import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchEmailCenterOverview } from "../../../services/apiService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom";
import { sendViactivEmailApi } from "../../../services/insuranceFormService";
import { Dialog } from "@mui/material";
import Loading from "../../Utility/Loading";
import { getCompanyDropDownList } from "../../../services/companyService";
import Select from "react-select";

const EmailCenter = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const { t, i18n } = useTranslation("common");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get("tab") || "overview";
  const isOverviewTabActive = tab === "overview";
  const [searchEmailType, setSearchEmailType] = useState("");
  const [searchEmailStatus, setSearchEmailStatus] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeCount, setEmployeeCount] = useState();
  const [formsPending, setFormsPending] = useState();
  const [emailPending, setEmailPending] = useState();
  const [docPending, setDocPending] = useState();
  const [dateFilter, setDateFilter] = useState("LAST_7_DAYS");
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0]; // Get YYYY-MM-DD
  const [sendDisabled, setSendDisabled] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  function calculateDate(date, days) {
    const resultDate = new Date(date);
    resultDate.setDate(resultDate.getDate() + days);
    return resultDate.toISOString().split("T")[0];
  }

  useEffect(() => {
    setSendDisabled(false);
    let formData = {
      fromDate: calculateDate(currentDate, -7),
      toDate: currentDateString,
    };
    if (dateFilter == "TODAY") {
      formData = {
        fromDate: calculateDate(currentDate, 0),
        toDate: currentDateString,
      };
    } else if (dateFilter == "LAST_3_DAYS") {
      formData = {
        fromDate: calculateDate(currentDate, -3),
        toDate: currentDateString,
      };
    } else if (dateFilter == "LAST_7_DAYS") {
      formData = {
        fromDate: calculateDate(currentDate, -7),
        toDate: currentDateString,
      };
    } else if (dateFilter == "LAST_15_DAYS") {
      formData = {
        fromDate: calculateDate(currentDate, -15),
        toDate: currentDateString,
      };
    } else if (dateFilter == "LAST_30_DAYS") {
      formData = {
        fromDate: calculateDate(currentDate, -30),
        toDate: currentDateString,
      };
    } else if (dateFilter == "LAST_60_DAYS") {
      formData = {
        fromDate: calculateDate(currentDate, -60),
        toDate: currentDateString,
      };
    }

    getEmailCenterOverview(formData);
  }, [dateFilter]);

  useEffect(() => {
    getCompanyDropDown();
  }, []);

  useEffect(() => {}, [filteredData, companyOptions]);

  const locale = {
    en: "en-US",
    de: "de-DE",
  };

  const formatDateGerman = (date) => {
    if (date === undefined) return "";
    const dateObject = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat(locale[i18n.language], options).format(
      dateObject
    );
  };

  const getCompanyDropDown = async () => {
    try {
      const response = await getCompanyDropDownList(localStorage.token);
      setCompanyOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmailCenterOverview = async (formData) => {
    try {
      const response = await fetchEmailCenterOverview(
        localStorage.token,
        formData
      );
      setFilteredData(response);
      setData(response);
      setCounts(response);
    } catch (error) {
      console.log(error);
    }
  };

  const setCounts = (response) => {
    let totalFormPending = 0;
    let totalEmployeeCount = 0;
    let totalDocumentPending = 0;
    let totalEmailPending = 0;

    response.forEach((company) => {
      totalFormPending += company.formSubmitted;
      totalEmployeeCount += company.employeeCount;
      totalDocumentPending += company.documentPending;
      totalEmailPending += company.emailPending;
    });
    setEmployeeCount(totalEmployeeCount);
    setFormsPending(totalFormPending);
    setEmailPending(totalEmailPending);
    setDocPending(totalDocumentPending);
  };

  const resetFilters = () => {
    setSelectedCompany("");
    setSearchEmailStatus("");
    setSearchEmailType("");
    setFilteredData(data);
    setDateFilter("LAST_7_DAYS");
  };

  const providerMap = {
    VIACTIVKRANKENKASSE: "Viactiv Krankenkasse",
    MOBILKRANKENKASSE: "Mobil Krankenkasse",
  };

  const applyFilter = () => {
    const filteredData = data
      .filter((row) => {
        return !selectedCompany || row.companyId == parseInt(selectedCompany);
      })
      .map((data) => ({
        ...data,
        emailRows: data.emailRows.filter(
          (emailRow) =>
            (!searchEmailType || searchEmailType === emailRow.emailType) &&
            (!searchEmailStatus || searchEmailStatus === emailRow.emailStatus)
        ),
      }));
    setFilteredData(filteredData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const getDisplayPages = () => {
    const pages = [];
    const pageWindow = 3; // Number of pages to display

    if (totalPages <= pageWindow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        for (let i = 1; i <= pageWindow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages) {
        for (let i = totalPages - pageWindow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const start = currentPage - 1;
        const end = currentPage + 1;

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  };

  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const [expandStates, setExpandStates] = useState(
    currentItems.map(() => false)
  );

  const handleExpandToggle = (index) => {
    const newExpandStates = [...expandStates];
    newExpandStates[index] = !newExpandStates[index];
    setExpandStates(newExpandStates);
  };

  const getEmailTypeText = (emailType) => {
    if (emailType === "FORM_EMAIL") {
      return t("applicationFormEmail");
    } else if (emailType === "PENSION_INSURANCE_EMAIL") {
      return t("pensionInsuranceEmail");
    }
    // } else if (emailType === "BULK_PENSION_BILLING_EMAIL") {
    //   return t("billingRefNumberEmail");
    // } else if (emailType === "MANAGER_EMAIL") {
    //   return t("managerEmail");
    // }
  };

  const getEmailStatusText = (status) => {
    if (status === "EMAIL_SENT") {
      return (
        <span style={{ fontWeight: 500, color: "green" }}>
          {" "}
          {t("emailSent")}
        </span>
      );
    } else if (status === "EMAIL_SEND_IN_PROGRESS") {
      return (
        <span style={{ fontWeight: 500, color: "blue" }}>
          {" "}
          {t("emailSendingInProgress")}
        </span>
      );
    } else if (status === "EMAIL_PENDING") {
      return (
        <span style={{ fontWeight: 500, color: "blue" }}>
          {" "}
          {t("emailPending")}
        </span>
      );
    } else if (status === "EMAIL_ERROR") {
      return (
        <span style={{ fontWeight: 500, color: "red" }}>
          {" "}
          Email {t("error")}
        </span>
      );
    } else if (status === "EMAIL_REPLY_RECEIVED") {
      return (
        <span style={{ fontWeight: 500, color: "orange" }}>
          {" "}
          {t("replyReceived")}
        </span>
      );
    }
  };

  const getDocStatusText = (status) => {
    if (status === "PENDING") {
      return t("documentsPending");
    } else if (status === "UPLOADED") {
      return t("documentsUploaded");
    } else if (status === "VERIFICATION_PENDING") {
      return t("docVerificationPending");
    } else if (status === "VERIFIED") {
      return t("documentsVerified");
    }
    return t("documentsNotRequired");
  };

  const getFormStatusText = (status) => {
    if (status === "PENDING") {
      return t("form") + " " + t("pending");
    } else if (status === "SUBMITTED") {
      return t("form") + " " + t("submitted");
    }
    return t("notRequired");
  };

  const sendEmail = (employeeId, providerTypeName) => {
    setSendDisabled(true);
    if (providerTypeName === "VIACTIVKRANKENKASSE") {
      sendViactivEmail(employeeId);
    } else if (providerTypeName === "MOBILKRANKENKASSE") {
      //To implement
      alert(t("notImplemented"));
    }
  };

  const sendViactivEmail = async (employeeId) => {
    try {
      setIsLoading(true);
      const response = await sendViactivEmailApi(
        employeeId,
        localStorage.token
      );
      if (response) {
        toast.success(t("mailWillBeSent"));
        let formData = {
          fromDate: calculateDate(currentDate, -7),
          toDate: currentDateString,
        };
        getEmailCenterOverview(formData);
      } else {
        toast.error(t("mailRequestFailed"));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="nav nav-tabs page-header-tab">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      isOverviewTabActive ? "active" : ""
                    }`}
                    id="user-tab"
                    data-toggle="tab"
                    href="#user-list"
                  >
                    {t("emailOverview")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  isOverviewTabActive ? "show active" : ""
                }`}
                id="user-list"
                role="tabpanel"
              >
                <div className="row clearfix">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="widgets2">
                          <div className="state">
                            <h6>Forms Submitted</h6>
                            <h2 style={{ marginBottom: "0px" }}>
                              {formsPending}
                            </h2>
                          </div>
                          <div className="icon">
                            <i className="fa fa-database" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="widgets2">
                          <div className="state">
                            <h6>{t("documentsPending")}</h6>
                            <h2 style={{ marginBottom: "0px" }}>
                              {docPending}
                            </h2>
                          </div>
                          <div className="icon">
                            <i className="fa fa-users" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="widgets2">
                          <div className="state">
                            <h6>{t("emailPending")}</h6>
                            <h2 style={{ marginBottom: "0px" }}>
                              {emailPending}
                            </h2>
                          </div>
                          <div className="icon">
                            <i className="fa fa-envelope" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="widgets2">
                          <div className="state">
                            <h6>{t("employeeCount")}</h6>
                            <h2 style={{ marginBottom: "0px" }}>
                              {employeeCount}
                            </h2>
                          </div>
                          <div className="icon">
                            <i className="fa fa-hand-o-left" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="container-fluid"> */}
                <div className="row clearfix">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-2 col-sm-6">
                            <label>{t("company")}</label>
                            {/* <div className="multiselect_div">
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={companyOptions}
                                value={selectedCompany}
                                onChange={(selectedOption) => {
                                  setSelectedCompany(selectedOption);
                                }}
                                placeholder={t("select")}
                              />
                            </div> */}
                            <div className="multiselect_div">
                              <select
                                className="custom-select"
                                value={selectedCompany}
                                onChange={(e) =>
                                  setSelectedCompany(e.target.value)
                                }
                              >
                                <option value="">Alle</option>
                                {companyOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-6">
                            <label>{t("emailType")}</label>
                            <div className="multiselect_div">
                              <select
                                className="custom-select"
                                value={searchEmailType}
                                onChange={(e) =>
                                  setSearchEmailType(e.target.value)
                                }
                              >
                                <option value="">{t("allTypes")}</option>
                                <option value="FORM_EMAIL">
                                  {t("applicationFormEmail")}
                                </option>
                                <option value="PENSION_INSURANCE_EMAIL">
                                  {t("pensionInsuranceEmail")}
                                </option>
                                {/* <option value="BILLING_REF_NUMBER_EMAIL">
                                  {t("billingRefNumberEmail")}
                                </option> */}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-6">
                            <label>{t("emailStatus")}</label>
                            <div className="multiselect_div">
                              <select
                                className="custom-select"
                                value={searchEmailStatus}
                                onChange={(e) =>
                                  setSearchEmailStatus(e.target.value)
                                }
                              >
                                <option value="">{t("allStatus")}</option>
                                <option value="EMAIL_SENT">{t("sent")}</option>
                                <option value="EMAIL_PENDING">
                                  {t("pending")}
                                </option>
                                <option value="EMAIL_ERROR">
                                  {t("error")}
                                </option>
                                <option value="EMAIL_REPLY">
                                  {t("replyReceived")}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-6">
                            <label style={{ whiteSpace: "nowrap" }}>
                              {t("employeeCreated")}
                            </label>
                            <div className="multiselect_div">
                              <select
                                className="custom-select"
                                value={dateFilter}
                                onChange={(e) => setDateFilter(e.target.value)}
                              >
                                <option value="TODAY">{t("today")}</option>
                                <option value="LAST_3_DAYS">
                                  {t("last3d")}
                                </option>
                                <option value="LAST_7_DAYS">
                                  {t("last7d")}
                                </option>
                                <option value="LAST_15_DAYS">
                                  {t("last15d")}
                                </option>
                                <option value="LAST_30_DAYS">
                                  {t("last30d")}
                                </option>
                                <option value="LAST_60_DAYS">
                                  {t("last60d")}
                                </option>
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-lg-2 col-md-2 col-sm-6">
                            <label>{t("fromDate")}</label>
                            <div className="input-group">
                              <input
                                type="date"
                                className="form-control"
                                value={formData.fromDate}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    fromDate: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-6">
                            <label>{t("toDate")}</label>
                            <div className="input-group">
                              <input
                                type="date"
                                className="form-control"
                                value={formData.toDate}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    toDate: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div> */}
                          <div className="col-md-2 col-sm-6">
                            <label>&nbsp;</label>
                            <button
                              onClick={() => {
                                applyFilter();
                              }}
                              className="btn btn-sm btn-primary btn-block"
                            >
                              {t("filter")}
                            </button>
                          </div>
                          <div className="col-md-2 col-sm-6">
                            <label>&nbsp;</label>
                            <button
                              onClick={() => {
                                resetFilters();
                              }}
                              className="btn btn-sm btn-primary btn-block"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {t("reset")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {currentItems.map((row, index) => (
                  <div className="row-clearfix" key={row.companyId}>
                    <div className="col-12">
                      <div
                        className={`card  ${
                          !expandStates[index] ? "card-collapsed" : ""
                        }`}
                      >
                        <div className="card-status" />
                        <div className="card-header">
                          <div className="table-responsive">
                            <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 border-style mb-0">
                              <tbody>
                                <tr>
                                  <td className="d-flex">
                                    <span
                                      className="avatar avatar-blue"
                                      data-toggle="tooltip"
                                      data-original-title={row.companyName}
                                    >
                                      {row.companyName
                                        .slice(0, 2)
                                        .toUpperCase()}
                                    </span>
                                    <div
                                      className="ml-3 mb-1"
                                      style={{ width: "150px" }}
                                    >
                                      <h6
                                        className="mb-0"
                                        style={{ fontWeight: 600 }}
                                      >
                                        <NavLink
                                          to={
                                            "/company/info?id=" + row.companyId
                                          }
                                          target="_blank"
                                        >
                                          {row.companyName}
                                        </NavLink>
                                      </h6>
                                      <span className="text-muted">
                                        {row.companyEmail
                                          ? row.companyEmail
                                          : "---"}
                                      </span>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <div
                                      className="font-15 text-center"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {row.managerCount}
                                    </div>
                                    <span>Manager Count</span>
                                  </td>
                                  <td className="text-center">
                                    <div
                                      className="font-15 text-center"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {row.employeeCount}
                                    </div>
                                    <span>{t("employeeCount")}</span>
                                  </td>
                                  {/* <td className="text-center">
                                    <div
                                      className="font-15 text-center"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {row.formSubmitted}
                                    </div>
                                    <span>Form Submitted</span>
                                  </td> */}
                                  <td className="text-center">
                                    <div
                                      className="font-15 text-center"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {row.documentPending}
                                    </div>
                                    <span>{t("documentsPending")}</span>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      className="card-options-collapse"
                                      data-toggle="card-collapse"
                                      onClick={() => handleExpandToggle(index)}
                                    >
                                      <i
                                        className={`fe ${
                                          expandStates[index]
                                            ? "fe-chevron-up"
                                            : "fe-chevron-down"
                                        }`}
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: 800,
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-vcenter table-hover text-nowrap mb-0">
                              <thead className="thead-light">
                                <tr>
                                  <th
                                    style={{ fontWeight: 600 }}
                                    className="w40"
                                  >
                                    #
                                  </th>
                                  <th style={{ fontWeight: 600 }}>Name</th>
                                  {/* <th style={{ fontWeight: 600 }}>Manager</th> */}
                                  <th style={{ fontWeight: 600 }}>
                                    {t("insuranceProvider")}
                                  </th>
                                  <th style={{ fontWeight: 600 }}>
                                    {t("formStatus")}
                                  </th>
                                  <th style={{ fontWeight: 600 }}>
                                    {t("emailStatus")}
                                  </th>
                                  <th style={{ fontWeight: 600 }}>
                                    {t("documentStatus")}
                                  </th>
                                  <th style={{ fontWeight: 600 }}>
                                    {t("actions")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="table-hover">
                                {row.emailRows?.map((emailRow, index) => (
                                  <tr key={index}>
                                    <td className="w40">{emailRow.index}</td>
                                    <td className="d-flex">
                                      <span
                                        className="avatar avatar-blue"
                                        data-toggle="tooltip"
                                        data-original-title={
                                          emailRow.employeeName
                                        }
                                      >
                                        {emailRow.employeeName
                                          .slice(0, 2)
                                          .toUpperCase()}
                                      </span>
                                      <div className="ml-3 mb-1">
                                        <h6
                                          className="mb-0"
                                          style={{ fontWeight: 600 }}
                                        >
                                          <NavLink
                                            to={
                                              "/employee/details?id=" +
                                              emailRow.employeeId
                                            }
                                            target="_blank"
                                          >
                                            {emailRow.employeeName}
                                          </NavLink>
                                        </h6>
                                        <span className="text-muted">
                                          {emailRow.email
                                            ? emailRow.email
                                            : "---"}
                                        </span>
                                      </div>
                                    </td>
                                    {/* <td>
                                      <div className="mb-1">
                                        <h6
                                          className="mb-0"
                                          style={{ fontWeight: 600 }}
                                        >
                                          <NavLink
                                            to="/companies"
                                            target="_blank"
                                          >
                                            {emailRow.managerName}
                                          </NavLink>
                                        </h6>
                                        <span className="text-muted">
                                          {emailRow.managerEmail}
                                        </span>
                                      </div>
                                    </td> */}
                                    <td>
                                      <div
                                        className="font-15"
                                        style={{ fontWeight: 600 }}
                                      >
                                        <NavLink
                                          to="/account/settings"
                                          target="_blank"
                                        >
                                          {providerMap[emailRow.providerName]}
                                        </NavLink>
                                      </div>
                                    </td>
                                    <td>
                                      {/* <span className="text-muted">Form Status :</span> */}
                                      <span
                                        className={`tag ${
                                          emailRow.formStatus === "SUBMITTED"
                                            ? "tag-success"
                                            : "tag-warning"
                                        } ml-0 mr-0`}
                                        style={{ fontWeight: 700 }}
                                      >
                                        {getFormStatusText(emailRow.formStatus)}
                                      </span>
                                    </td>
                                    <td>
                                      <div
                                        className="font-15"
                                        style={{ fontWeight: 600 }}
                                      >
                                        {getEmailTypeText(emailRow.emailType)}
                                      </div>
                                      {getEmailStatusText(emailRow.emailStatus)}
                                      {emailRow.mailSentTime &&
                                        emailRow.emailStatus ===
                                          "EMAIL_SENT" && (
                                          <>
                                            &nbsp;{t("on")}&nbsp;{" "}
                                            {formatDateGerman(
                                              emailRow.mailSentTime
                                            )}
                                          </>
                                        )}
                                    </td>
                                    <td>
                                      {emailRow.documentRequired == 0 ? (
                                        <span
                                          className={`tag ${
                                            emailRow.docStatus === "VERIFIED"
                                              ? "tag-success"
                                              : "tag-warning"
                                          } ml-0 mr-0`}
                                          style={{ fontWeight: 700 }}
                                        >
                                          No Manadatory Documents
                                        </span>
                                      ) : (
                                        <span
                                          className={`tag ${
                                            emailRow.docStatus === "VERIFIED"
                                              ? "tag-success"
                                              : "tag-warning"
                                          } ml-0 mr-0`}
                                          style={{ fontWeight: 700 }}
                                        >
                                          {getDocStatusText(emailRow.docStatus)}
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {emailRow.emailStatus ===
                                        "EMAIL_ERROR" && (
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          title={t("retry")}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          disabled={sendDisabled}
                                          onClick={() =>
                                            sendEmail(
                                              emailRow.employeeId,
                                              emailRow.providerName
                                            )
                                          }
                                        >
                                          {t("retry")}
                                        </button>
                                      )}
                                      {!emailRow.mailSentTime &&
                                        emailRow.formStatus !== "PENDING" &&
                                        emailRow.docStatus === "VERIFIED" &&
                                        emailRow.emailStatus !==
                                          "EMAIL_SEND_IN_PROGRESS" && (
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{ whiteSpace: "nowrap" }}
                                            title="Email"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            disabled={sendDisabled}
                                            onClick={() =>
                                              sendEmail(
                                                emailRow.employeeId,
                                                emailRow.providerName
                                              )
                                            }
                                          >
                                            {/* <i className="icon-envelope" />
                                     &nbsp; */}
                                            {t("sendEmail")}
                                          </button>
                                        )}
                                      {emailRow.formStatus !== "PENDING" &&
                                        emailRow.docStatus === "VERIFIED" &&
                                        emailRow.emailStatus ===
                                          "EMAIL_SENT" && (
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{ whiteSpace: "nowrap" }}
                                            title="Email"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            disabled={sendDisabled}
                                            onClick={() =>
                                              sendEmail(
                                                emailRow.employeeId,
                                                emailRow.providerName
                                              )
                                            }
                                          >
                                            {/* <i className="icon-envelope" />
                                     &nbsp; */}
                                            {t("resend")}
                                          </button>
                                        )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <nav>
                    <ul className="pagination">
                      {currentPage > 1 && (
                        <li
                          className="page-item"
                          style={{ padding: "5px 2px" }}
                        >
                          <button
                            onClick={() => handleClick(currentPage - 1)}
                            className="page-link"
                            style={{
                              fontSize: "14px",
                              padding: "2px 10px",
                              fontWeight: 600,
                            }}
                          >
                            Previous
                          </button>
                        </li>
                      )}

                      {getDisplayPages().map((page) => (
                        <li
                          key={page}
                          className={`page-item ${
                            currentPage === page ? "active" : ""
                          }`}
                          style={{ padding: "5px 2px" }}
                        >
                          <button
                            onClick={() => handleClick(page)}
                            className="page-link"
                            style={{
                              fontSize: "14px",
                              padding: "2px 10px",
                              fontWeight: 600,
                              backgroundColor:
                                currentPage === page ? "gray" : " ",
                            }}
                          >
                            {page}
                          </button>
                        </li>
                      ))}

                      {currentPage < totalPages && (
                        <li
                          className="page-item"
                          style={{ padding: "5px 2px" }}
                        >
                          <button
                            onClick={() => handleClick(currentPage + 1)}
                            className="page-link"
                            style={{
                              fontSize: "14px",
                              padding: "2px 10px",
                              fontWeight: 600,
                            }}
                          >
                            Next
                          </button>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EmailCenter;
