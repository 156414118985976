import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const tokenApiInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const downloadApiInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const handleChangePassword = async (token, request) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/company/change/password",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPublicHolidaysList = async (token) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/admin/public/holidays"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addPublicHoliday = async (token, data) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/admin/public/holiday",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePublicHoliday = async (token, id) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/admin/public/holiday/" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDocumentTypeApi = async (token, id) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/document/type/" + id + "/delete"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDocumentSettingsApi = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/document/settings");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveDocumentSettingsApi = async (token, request) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/document/settings",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDocumentRequestsApi = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/document/requests");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const raiseRequestApi = async (token, request) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/document/request",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDocumentRequestsForEmployeeApi = async (token, employeeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/document/requests/" + employeeId
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMyCompanyProfile = async (token, companyId) => {
  try {
    const response = await tokenApiInstance(token).get("/company/" + companyId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyCardDeliveryDetails = async (token, employeeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/company/" + employeeId + "/card/delivery"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editCompanyProfile = async (token, company) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/admin/edit/company",
      company
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserActivityList = async (token, companyId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/activity/" + companyId + "/list"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeaveRequestsForApproval = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/leave/requests");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const approveRequests = async (request, token) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/leave/approve/requests",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rejectRequests = async (request, token) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/leave/reject/requests",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDashboardData = async (token, storeId) => {
  try {
    const response = await tokenApiInstance(token).get("/dashboard/" + storeId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const raiseSupportTicket = async (token, request) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/customer/service/raise/ticket",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitFeedBack = async (token, request) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/customer/service/submit/feedback",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRaisedTickets = async (token) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/customer/service/tickets"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBillingDataApi = async (token, date) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/customer/service/billing?month=" + date
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveTimeTrackConfig = async (token, config, storeId) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/track/time/" + storeId + "/config",
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTimeTrackConfig = async (token, storeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/track/time/" + storeId + "/config"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const employeeCheckIn = async (
  token,
  employeeId,
  shiftId,
  clientIPAddress,
  latitude,
  longitude
) => {
  try {
    const response = await tokenApiInstance(token).get(
      `/track/${employeeId}/shift/${shiftId}/checkIn?latitude=${latitude}&longitude=${longitude}`,
      {
        headers: {
          "client-ip-address": clientIPAddress,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const employeeCheckOut = async (
  token,
  employeeId,
  shiftId,
  clientIPAddress,
  latitude,
  longitude
) => {
  try {
    const response = await tokenApiInstance(token).get(
      `/track/${employeeId}/shift/${shiftId}/checkOut?latitude=${latitude}&longitude=${longitude}`,
      {
        headers: {
          "client-ip-address": clientIPAddress,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNextSevenDaysSchedule = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/track/time/schedule");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPastShiftsForEmployee = async (token, date) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/track/past/shifts?month=" + date
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createDesignations = async (token, companyId, designations) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/admin/" + companyId + "/designations",
      designations
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDesignations = async (token, companyId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/admin/" + companyId + "/designations"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editDesignation = async (token, companyId, designation) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/admin/" + companyId + "/designation",
      designation
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDesignation = async (token, companyId, designationId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/admin/" + companyId + "/designation/delete/" + designationId
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchEmailCenterOverview = async (token, data) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/email/center/overview/list",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCompanyEmailCenterOverview = async (token, data) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/email/center/company/overview/list",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitGDPRConsent = async (token, consent) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/submit/consent/" + consent
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAgentProfile = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/agent/profile");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editAgentProfile = async (token, agent) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/agent/edit/profile",
      agent
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadEmployeesSampleCsv = async (token) => {
  try {
    const response = await downloadApiInstance(token).get(
      "/document/sample/employees",
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAgentProviders = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/agent/provider/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAgentProvider = async (token, data) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/agent/provider",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
