import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const apiInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const loginUser = async (user) => {
  try {
    const response = await apiInstance.post("/api/auth/token", user);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createSuperAdminUser = async (user) => {
  try {
    const response = await apiInstance.post(
      "/api/auth/super/admin/register",
      user
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleForgotPassword = async (email) => {
  try {
    const response = await apiInstance.post(
      "/api/auth/forgot/password/" + email
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
