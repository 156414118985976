import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { handleForgotPassword } from "../../services/authservice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const { t } = useTranslation("login");

  const sendNewPassword = async () => {
    try {
      console.log("email is  ", email);
      const response = await handleForgotPassword(email);
      if (response) {
        toast.success(t("checkEmailForPassword"));
        history.push("/login");
      } else {
        toast.error(t("resetFailedMessage"));
      }
    } catch (error) {
      console.error("Error resetting password", error);
    }
  };

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-5">
            <Link className="header-brand" to="/">
              <img
                src="https://boardi.co/assets/images/logo_icon_1.png"
                className="img-fluid mx-auto mb-2"
                alt="/"
                style={{ width: "35px", height: "35px" }}
              ></img>
            </Link>
          </div>
          <div className="card-body">
            <div className="card-title">{t("forgotPasswordText")}</div>
            <p className="text-muted">{t("passwordResetMessage")}</p>
            <div className="form-group">
              <label className="form-label" htmlFor="exampleInputEmail1">
                {t("emailAddress")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-footer">
              <button
                className="btn btn-primary btn-block"
                onClick={sendNewPassword}
              >
                {t("sendNewPassword")}
              </button>
            </div>
          </div>
          <div className="text-center text-muted font-14">
            {t("forgetIt")}, <Link to="/login">{t("sendMeBack")}</Link>
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active items-center">
              <img
                src="assets/images/slider6.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemOnetitle")}</h4>
                <p>{t("itemOnedescription")}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider7.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemTwotitle")}</h4>
                <p>{t("itemTwodescription")}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider8.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemThreetitle")}</h4>
                <p>{t("itemThreedescription")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
