import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TermsAndConditions = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("page");

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const policySections = [
    { id: "section1", title: t("tcSection1Title") },
    { id: "section2", title: t("tcSection2Title") },
    { id: "section3", title: t("tcSection3Title") },
    { id: "section4", title: t("tcSection4Title") },
    { id: "section5", title: t("tcSection5Title") },
    { id: "section6", title: t("tcSection6Title") },
    { id: "section7", title: t("tcSection7Title") },
    { id: "section8", title: t("tcSection8Title") },
    { id: "section9", title: t("tcSection9Title") },
    { id: "section10", title: t("tcSection10Title") },
    { id: "section11", title: t("tcSection11Title") },
    { id: "section12", title: t("tcSection12Title") },
    { id: "section13", title: t("tcSection13Title") },
    { id: "section14", title: t("tcSection14Title") },
    { id: "section15", title: t("tcSection15Title") },
    { id: "section16", title: t("tcSection16Title") },
    { id: "section17", title: t("tcSection17Title") },
    { id: "section18", title: t("tcSection18Title") },
  ];

  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 100) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          background: "#39374F",
          color: "#fff",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", background: "white" }}
        >
          <Link to="/login">
            <img
              src="https://boardi.co/assets/images/logo_2.png"
              alt="Boardi.co"
              style={{ width: "200px", height: "auto", marginRight: "10px" }}
            />
          </Link>
        </div>
        <div className="right">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ fontSize: "18px", fontWeight: 600 }}
          >
            Language
          </a>
          <div className="dropdown-menu">
            <button
              className="dropdown-item"
              onClick={() => changeLanguage("en")}
              style={{ display: "flex" }}
            >
              <img
                className="w20 mr-2"
                src="https://boardi.co/assets/images/flags/us.svg"
                alt="fake_url"
              />
              English
            </button>
            <div className="dropdown-divider" />
            <button
              className="dropdown-item"
              onClick={() => changeLanguage("de")}
              style={{ display: "flex" }}
            >
              <img
                className="w20 mr-2"
                src="https://boardi.co/assets/images/flags/de.svg"
                alt="fake_url"
              />
              Deutsch
            </button>
          </div>
        </div>
      </div>
      <div className="row" style={{ background: "#fff", padding: "20px" }}>
        <div className="col-md-1 col-sm-12"></div>
        <div className="col-md-3 col-sm-12 mt-4">
          <div
            style={{
              padding: "10px",
              position: isSticky ? "sticky" : "static",
              top: isSticky ? "20px" : "auto",
            }}
          >
            <ul className="mt-20">
              {policySections.map((section) => (
                <li
                  key={section.id}
                  onClick={() => scrollToSection(section.id)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#27A6EA",
                    marginTop: "2px",
                  }}
                >
                  {section.title}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="col-md-7 col-sm-12 mt-4">
          <div
            style={{
              padding: "10px",
              background: "#fff",
            }}
          >
            <h2
              style={{ textTransform: "uppercase", fontWeight: 600 }}
              className="mt-4"
            >
              {t("tcPageTitle")}
            </h2>
            <div className="mt-12"></div>
            {policySections.map((section) => (
              <section
                key={section.id}
                id={section.id}
                style={{ marginBottom: "20px" }}
              >
                <h3
                  className="mt-4"
                  style={{ fontWeight: 500, fontSize: "20px" }}
                >
                  {section.title}
                </h3>

                {section.id === "section1" && (
                  <div>
                    <p>{t("tcSection1Content")}</p>
                  </div>
                )}
                {section.id === "section2" && (
                  <div>
                    <p>{t("tcSection2Content")}</p>
                  </div>
                )}
                {section.id === "section3" && (
                  <div>
                    <p>{t("tcSection3Content")}</p>
                  </div>
                )}
                {section.id === "section4" && (
                  <div>
                    <p>{t("tcSection4Content")}</p>
                  </div>
                )}
                {section.id === "section5" && (
                  <div>
                    <p>{t("tcSection5Content")}</p>
                  </div>
                )}
                {section.id === "section6" && (
                  <div>
                    <p>{t("tcSection6Content")}</p>
                  </div>
                )}
                {section.id === "section7" && (
                  <div>
                    <p>{t("tcSection7Content")}</p>
                  </div>
                )}
                {section.id === "section8" && (
                  <div>
                    <p>{t("tcSection8Content")}</p>
                  </div>
                )}
                {section.id === "section9" && (
                  <div>
                    <p>{t("tcSection9Content")}</p>
                  </div>
                )}
                {section.id === "section10" && (
                  <div>
                    <p>{t("tcSection10Content")}</p>
                  </div>
                )}
                {section.id === "section11" && (
                  <div>
                    <p>{t("tcSection11Content")}</p>
                  </div>
                )}
                {section.id === "section12" && (
                  <div>
                    <p>{t("tcSection12Content")}</p>
                  </div>
                )}
                {section.id === "section13" && (
                  <div>
                    <p>{t("tcSection13Content")}</p>
                  </div>
                )}
                {section.id === "section14" && (
                  <div>
                    <p>{t("tcSection14Content")}</p>
                  </div>
                )}
                {section.id === "section15" && (
                  <div>
                    <p>{t("tcSection15Content")}</p>
                  </div>
                )}
                {section.id === "section16" && (
                  <div>
                    <p>{t("tcSection16Content")}</p>
                  </div>
                )}
                {section.id === "section17" && (
                  <div>
                    <p>{t("tcSection17Content")}</p>
                  </div>
                )}
                {section.id === "section18" && (
                  <div className="mb-40">
                    <p>{t("tcSection18Content")}</p>
                  </div>
                )}
              </section>
            ))}
          </div>
        </div>
        <div className="col-md-1 col-sm-12"></div>
      </div>
    </>
  );
};

export default TermsAndConditions;
