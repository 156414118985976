import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Barchart from "../../common/barchart";
import { getManagerDashboard } from "../../../services/companyService";
import Piechart from "../../common/piechart";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const ManagerDashboard = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const [companyStatistics, setCompanyStatistics] = useState({});
  const [employeeDistribution, setEmployeeDistribution] = useState({});
  const [onboardingStatistics, setOnboardingStatistics] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState();
  const [maleEmployees, setMaleEmployees] = useState();
  const [femaleEmployees, setFemaleEmployees] = useState();
  const [otherEmployees, setOtherEmployees] = useState();
  const [companySummary, setCompanySummary] = useState([]);

  useEffect(() => {
    fetchManagerDashboard();
  }, []);

  const fetchManagerDashboard = async () => {
    try {
      const response = await getManagerDashboard(localStorage.token);
      if (response) {
        setCompanyStatistics(response.companyStatistics);
        setCompanySummary(response.companySummary);
        setEmployeeDistribution(response.employeeDistribution);
        setOnboardingStatistics(response.onboardingStatisticsList);
        setTotalEmployees(response.totalEmployees);
        setMaleEmployees(response.maleEmployees);
        setFemaleEmployees(response.femaleEmployees);
        setOtherEmployees(response.otherEmployees);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOnboardingStatus = (status) => {
    if (status === "COMPLETED") return t("completed");
    if (status === "STARTED") return t("started");
    return t("initiated");
  };

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-8">
                <div
                  className={`section-body ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}
                >
                  <h4>
                    {t("welcome")} {localStorage.userName}!
                  </h4>
                  <small>
                    {t("dashboardMsg")}. <a href="">{t("learnMore")}</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link to="/companies" className="my_sort_cut text-muted">
                      <i
                        className="icon-home"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>{t("companies")}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <Link to="/email/center" className="my_sort_cut text-muted">
                      <i
                        className="icon-envelope"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>Emails</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link to="/employee" className="my_sort_cut text-muted">
                      <i
                        className="icon-users"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>{t("employees")}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link to="/companies" className="my_sort_cut text-muted">
                      <i
                        className="icon-like"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>Support</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix row-deck">
              <div className="col-xl-4 col-lg-12">
                <div className="card">
                  <div className="card-header" style={{ marginBottom: "40px" }}>
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {t("employeeDistribution")}
                    </h3>
                  </div>
                  <div
                    className="text-center"
                    style={{ padding: "5px", marginBottom: "20px" }}
                  >
                    <div className="row clearfix">
                      <div className="col-3">
                        <h6 className="mb-0 mt-1">
                          {totalEmployees ? totalEmployees : 0}
                        </h6>
                        <small className="text-muted">{t("total")}</small>
                      </div>
                      <div className="col-3">
                        <h6 className="mb-0 mt-1">
                          {maleEmployees ? maleEmployees : 0}
                        </h6>
                        <small className="text-muted">{t("male")}</small>
                      </div>
                      <div className="col-3">
                        <h6 className="mb-0 mt-1">
                          {femaleEmployees ? femaleEmployees : 0}
                        </h6>
                        <small className="text-muted">{t("female")}</small>
                      </div>
                      <div className="col-3">
                        <h6 className="mb-0 mt-1">
                          {otherEmployees ? otherEmployees : 0}
                        </h6>
                        <small className="text-muted">{t("other")}</small>
                      </div>
                    </div>
                  </div>
                  {employeeDistribution && (
                    <Piechart
                      categories={Object.keys(employeeDistribution)}
                      series={Object.values(employeeDistribution)}
                    ></Piechart>
                  )}
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {t("currentOnboardingStatus")}
                    </h3>
                  </div>
                  <div className="card-body">
                    {onboardingStatistics.length > 0 && (
                      <Barchart data={onboardingStatistics}></Barchart>
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-4 col-xl-4 col-md-4">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.emailSentPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.emailSentPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("emailSent")}
                        </span>
                      </div>
                      <div className="col-4 col-xl-4 col-md-4">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.emailPendingPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.emailPendingPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("emailPending")}
                        </span>
                      </div>
                      <div className="col-4 col-xl-4 col-md-4">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.documentsUploadedPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.documentsUploadedPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("documentsUploaded")}
                        </span>
                      </div>
                      {/* <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.formSubmittedPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.formSubmittedPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("formEinreichented")}
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {t("summary")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{t("companyName")}</th>
                            <th>{t("employees")}</th>
                            <th>{t("insuranceProvider")}</th>
                            <th>Onboarding Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {companySummary &&
                            companySummary.map((summary, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <NavLink
                                    to={"/company/info?id=" + summary.companyId}
                                    target="_blank"
                                    style={{ fontWeight: 550 }}
                                  >
                                    {summary.companyName}
                                  </NavLink>
                                </td>
                                <td>
                                  <ul className="list-unstyled team-info sm margin-0 w150">
                                    {summary.employees > 4 ? (
                                      <>
                                        {[...Array(4)].map((_, index) => (
                                          <li key={index}>
                                            <img
                                              src={`/assets/images/xs/avatar${
                                                index + 1
                                              }.jpg`}
                                              alt={`Avatar ${index + 1}`}
                                            />
                                          </li>
                                        ))}
                                        <li className="ml-2">
                                          <span>{summary.employees - 4}+</span>
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        {[...Array(summary.employees)].map(
                                          (_, index) => (
                                            <li key={index}>
                                              <img
                                                src={`/assets/images/xs/avatar${
                                                  index + 1
                                                }.jpg`}
                                                alt={`Avatar ${index + 1}`}
                                              />
                                            </li>
                                          )
                                        )}
                                      </>
                                    )}
                                  </ul>
                                </td>
                                <td>{summary.providerName}</td>
                                <td>
                                  {summary.status === "INITIATED" && (
                                    <span className="tag tag-danger">
                                      {getOnboardingStatus(summary.status)}
                                    </span>
                                  )}
                                  {summary.status !== "INITIATED" && (
                                    <span className="tag tag-success">
                                      {getOnboardingStatus(summary.status)}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboard);
