import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const tokenApiInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const submitForm = async (token, employeeId, request) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/insurance/" + employeeId + "/viactiv/application/form/submit",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getApplicationForm = async (token, employeeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/insurance/" + employeeId + "/viactiv/application/form"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generateApplicationForm = async (employeeId, token) => {
  try {
    const url = `/document/application/download/${employeeId}`;
    const response = await tokenApiInstance(token).get(url, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const generatePreviousInsuranceForm = async (employeeId, token) => {
  try {
    const url = `/document/previous/insurance/form/download/${employeeId}`;
    const response = await tokenApiInstance(token).get(url, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const generateCardDeliveryForm = async (employeeId, token) => {
  try {
    const url = `/document/card/delivery/form/download/${employeeId}`;
    const response = await tokenApiInstance(token).get(url, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const generateFamilyInsuranceForm = async (employeeId, token) => {
  try {
    const url = `/document/family/insurance/form/download/${employeeId}`;
    const response = await tokenApiInstance(token).get(url, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendViactivEmailApi = async (employeeId, token) => {
  try {
    const url = `/email/center/${employeeId}/viactiv/send/email`;
    const response = await tokenApiInstance(token).get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendCompanyManagerEmail = async (employeeId, token, request) => {
  try {
    const url = `/email/${employeeId}/send/manager/email`;
    const response = await tokenApiInstance(token).post(url, request);
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitPrevInsuranceFormApi = async (
  token,
  employeeId,
  request
) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/insurance/" + employeeId + "/viactiv/previous/insurance/form/submit",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitCardDeliveryFormApi = async (token, employeeId, request) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/insurance/" + employeeId + "/viactiv/card/delivery/form/submit",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitFamilyInsuranceFormApi = async (
  token,
  employeeId,
  request
) => {
  try {
    const response = await tokenApiInstance(token).post(
      "/insurance/" + employeeId + "/viactiv/family/insurance/form/submit",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPreviousInsuranceForm = async (token, employeeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/insurance/" + employeeId + "/viactiv/previous/insurance/form"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFamilyInsuranceForm = async (token, employeeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/insurance/" + employeeId + "/viactiv/family/insurance/form"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCardDeliveryForm = async (token, employeeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/insurance/" + employeeId + "/viactiv/card/delivery/form"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFormsSubmittedInfo = async (token, employeeId) => {
  try {
    const response = await tokenApiInstance(token).get(
      "/insurance/" + employeeId + "/forms/submitted"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
