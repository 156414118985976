import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const axiosInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllInsuranceProviders = async (token) => {
  try {
    const response = await axiosInstance(token).get("/insurance/provider/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createInsuranceProvider = async (token, data) => {
  try {
    const response = await axiosInstance(token).post(
      "/insurance/provider",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteInsuranceProviderById = async (token, id) => {
  try {
    const response = await axiosInstance(token).get(
      "/insurance/provider/" + id + "/delete"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInsuranceProviderOptions = async (token) => {
  try {
    const response = await axiosInstance(token).get(
      "/insurance/provider/dropdown/list"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editInsuranceProvider = async (token, id, email) => {
  try {
    const response = await axiosInstance(token).get(
      "/insurance/provider/edit/" + id + "/email/" + email
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
