import React, { useState } from "react";
import Chart from "react-apexcharts";

const Barchart = ({ data }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      fill: {
        colors: ["#5a5278", "#6f6593", "#8075aa", "#a192d9"],
      },
      xaxis: {
        categories: data.map((item) => item.category),
      },
    },
    series: [
      {
        name: "series-1",
        data: data.map((item) => item.value),
      },
    ],
  });

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        width="100%"
        height="400px"
      />
    </div>
  );
};

export default Barchart;
