import React from 'react';

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-white-400 backdrop-blur-sm">
      <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-dashed border-indigo-700 border-opacity-75"></div>
    </div>
  );
};

export default Loading;
