import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import axios from "axios";

const ImageUploadDialog = ({
  isOpen,
  onClose,
  onUpload,
  id,
  displayMsg,
  // isCompany,
  // isEmployee,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImagePreviewUrl(null);
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImagePreviewUrl(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedFile);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        "https://api.boardi.co/api/images/employee/" + id + "/upload",
        formData,
        config
      );
      onUpload(response.data);
      onClose();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle style={{ fontSize: "24px" }}>{displayMsg}</DialogTitle>
      <DialogContent>
        <div
          style={{
            padding: "16px",
            backgroundColor: "white",
            maxWidth: "450px",
            margin: "0 auto",
          }}
        >
          <div style={{ marginBottom: "16px" }}>
            <input
              type="file"
              onChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.gif,.heic"
              style={{ border: "1px solid #000", padding: "8px" }}
            />
          </div>
          {imagePreviewUrl && (
            <div style={{ marginBottom: "16px" }}>
              <img
                src={imagePreviewUrl}
                alt="Preview"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
          <div>
            <button
              onClick={handleUpload}
              style={{
                backgroundColor: "#187ae2",
                color: "white",
                padding: "8px 20px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Upload Image
            </button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploadDialog;
