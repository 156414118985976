import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DataProtectionPolicy = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("page");

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const policySections = [
    { id: "section1", title: t("dpSection1Title") },
    { id: "section2", title: t("dpSection2Title") },
    { id: "section3", title: t("dpSection3Title") },
    { id: "section4", title: t("dpSection4Title") },
    { id: "section5", title: t("dpSection5Title") },
    { id: "section6", title: t("dpSection6Title") },
    { id: "section7", title: t("dpSection7Title") },
    { id: "section8", title: t("dpSection8Title") },
    { id: "section9", title: t("dpSection9Title") },
    { id: "section10", title: t("dpSection10Title") },
    { id: "section11", title: t("dpSection11Title") },
    { id: "section12", title: t("dpSection12Title") },
    { id: "section13", title: t("dpSection13Title") },
  ];

  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 100) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openPDF = () => {
    window.open(
      "https://boardi.co/assets/pdfs/datenschutzerklaerung_hrms_bespokeitconsultants_com_de.pdf",
      "_blank"
    );
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          background: "#39374F",
          color: "#fff",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", background: "white" }}
        >
          <Link to="/login">
            <img
              src="https://boardi.co/assets/images/logo_2.png"
              alt="Boardi.co"
              style={{ width: "200px", height: "auto", marginRight: "10px" }}
            />
          </Link>
        </div>
        <div className="right">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ fontSize: "18px", fontWeight: 600 }}
          >
            Language
          </a>
          <div className="dropdown-menu">
            <button
              className="dropdown-item"
              onClick={() => changeLanguage("en")}
              style={{ display: "flex" }}
            >
              <img
                className="w20 mr-2"
                src="https://boardi.co/assets/images/flags/us.svg"
                alt="fake_url"
              />
              English
            </button>
            <div className="dropdown-divider" />
            <button
              className="dropdown-item"
              onClick={() => changeLanguage("de")}
              style={{ display: "flex" }}
            >
              <img
                className="w20 mr-2"
                src="https://boardi.co/assets/images/flags/de.svg"
                alt="fake_url"
              />
              Deutsch
            </button>
          </div>
        </div>
      </div>
      <div className="row" style={{ background: "#fff", padding: "20px" }}>
        <div className="col-md-1 col-sm-12"></div>
        <div className="col-md-3 col-sm-12 mt-4">
          <div
            style={{
              padding: "10px",
              // height: isSticky ? 'calc(100vh - 20px)' : 'auto',
              position: isSticky ? "sticky" : "static",
              top: isSticky ? "20px" : "auto",
              // background: "#f8f9fa",
              // border: "1px solid #dee2e6",
            }}
          >
            <ul className="mt-20">
              {policySections.map((section) => (
                <li
                  key={section.id}
                  onClick={() => scrollToSection(section.id)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#27A6EA",
                    marginTop: "2px",
                  }}
                >
                  {section.title}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="col-md-7 col-sm-12 mt-4">
          <div
            style={{
              padding: "10px",
              background: "#fff",
            }}
          >
            <h2
              style={{ textTransform: "uppercase", fontWeight: 600 }}
              className="mt-4"
            >
              {t("dpPageTitle")}
            </h2>

            {policySections.map((section) => (
              <section
                key={section.id}
                id={section.id}
                style={{ marginBottom: "20px" }}
              >
                <h3
                  className="mt-12"
                  style={{ fontWeight: 500, fontSize: "20px" }}
                >
                  {section.title}
                </h3>
                {section.id === "section1" && (
                  <div>
                    <p>{t("dpSection1Content")}</p>
                    <ul>
                      <ol>
                        <b>-</b>
                        {t("dpSection1L1")}
                      </ol>
                      <ol>
                        <b>-</b>
                        {t("dpSection1L2")}
                      </ol>
                    </ul>
                  </div>
                )}
                {section.id === "section2" && (
                  <div>
                    <p>{t("dpSection2Content")}</p>
                    <ul>
                      <ol>
                        <b>-</b>
                        {t("dpSection2L1")}
                      </ol>
                      <ol>
                        <b>-</b>
                        {t("dpSection2L2")}
                      </ol>
                    </ul>
                  </div>
                )}
                {section.id === "section3" && (
                  <div>
                    <p>{t("dpSection3Content")}</p>
                  </div>
                )}
                {section.id === "section4" && (
                  <div>
                    <p>{t("dpSection4Content")}</p>
                    <ul>
                      <ol>
                        <b>-</b>
                        {t("dpSection4L1")}
                      </ol>
                      <ol>
                        <b>-</b>
                        {t("dpSection4L2")}
                      </ol>
                      <ol>
                        <b>-</b>
                        {t("dpSection4L3")}
                      </ol>
                    </ul>
                  </div>
                )}
                {section.id === "section5" && (
                  <div>
                    <p>{t("dpSection5Content")}</p>
                    <ul>
                      <ol>
                        <b>-</b>
                        {t("dpSection5L1")}
                      </ol>
                      <ol>
                        <b>-</b>
                        {t("dpSection5L2")}
                      </ol>
                    </ul>
                  </div>
                )}
                {section.id === "section6" && (
                  <div>
                    <p>{t("dpSection6Content")}</p>
                  </div>
                )}
                {section.id === "section7" && (
                  <div>
                    <p>{t("dpSection7Content")}</p>
                  </div>
                )}
                {section.id === "section8" && (
                  <div>
                    <p>{t("dpSection8Content")}</p>
                  </div>
                )}
                {section.id === "section9" && (
                  <div>
                    <p>{t("dpSection9Content")}</p>
                  </div>
                )}
                {section.id === "section10" && (
                  <div>
                    <p>{t("dpSection10Content")}</p>
                  </div>
                )}
                {section.id === "section11" && (
                  <div>
                    <p>{t("dpSection11Content")}</p>
                  </div>
                )}
                {section.id === "section12" && (
                  <div>
                    <p>{t("dpSection12Content")}</p>
                  </div>
                )}
                {section.id === "section13" && (
                  <div>
                    <ul>
                      <ol>
                        <b>{t("dpSection13L1")}-</b>
                        {t("dpSection13L3")}
                      </ol>
                      <ol>
                        <b>{t("dpSection13L2")}-</b>
                        {t("dpSection13L4")}
                      </ol>
                    </ul>
                  </div>
                )}
              </section>
            ))}
          </div>
          <div className="mb-20 mt-4">
            <p>{t("dpConclusion")}</p>
          </div>
        </div>
        <div className="col-md-1 col-sm-12"></div>
      </div>
    </>
  );
};

export default DataProtectionPolicy;
