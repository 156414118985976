import React, { useEffect, useState } from "react";
import {
  editAgentProfile,
  getAgentProfile,
  getAgentProviders,
  updateAgentProvider,
} from "../../../services/apiService";
import { useTranslation } from "react-i18next";
import Loading from "../../Utility/Loading";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AgentProfile = () => {
  const { t } = useTranslation("common");
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [agent, setAgent] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [agentNumber, setAgentNumber] = useState("");
  const [businessPartnerNumber, setBusinessPartnerNumber] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [postcode, setPostcode] = useState("");
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState({
    providerName: "",
    providerId: "",
    ma8iIdentifier: "",
  });
  const [errors, setErrors] = useState({
    ma8iIdentifier: false,
  });

  useEffect(() => {
    fetchAgentProfile();
    fetchAgentProviders();
  }, []);

  useEffect(() => {}, [agent]);

  const fetchAgentProfile = async () => {
    try {
      const response = await getAgentProfile(localStorage.token);
      setAgent(response);
      setAgentProfile(response);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAgentProviders = async () => {
    try {
      const response = await getAgentProviders(localStorage.token);
      setProviders(response);
    } catch (error) {
      console.log(error);
    }
  };

  const setAgentProfile = (response) => {
    setFirstName(response.firstName);
    setLastName(response.lastName);
    setCountryCode(response.countryCode);
    setPhone(response.phone);
    setAgentNumber(response.agentNumber);
    setBusinessPartnerNumber(response.businessPartnerNumber);
    setStreet(response.address?.street);
    setHouseNumber(response.address?.houseNumber);
    setPostcode(response.address?.postcode);
    if (!response.agentNumber || !response.businessPartnerNumber) {
      localStorage.setItem("profileComplete", false);
    } else if (response.agentNumber && response.businessPartnerNumber) {
      localStorage.setItem("profileComplete", true);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditMode(true);
    setDisabled(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setDisabled(true);
    window.location.reload(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      firstName,
      lastName,
      countryCode,
      phone,
      agentNumber,
      businessPartnerNumber,
      address: {
        street,
        houseNumber,
        postcode,
      },
    };
    editAgent(formData);
  };

  const editAgent = async (formData) => {
    try {
      const response = await editAgentProfile(localStorage.token, formData);
      if (response) {
        toast.success(t("profileUpdatedSuccessfully"));
        setDisabled(true);
        setIsEditMode(false);
        fetchAgentProfile();
      } else {
        toast.error(t("somethingWentWrong"));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleEditProvider = (row) => {
    setProvider(row);
  };

  const handleEditClose = () => {
    setProvider({
      providerName: "",
      providerId: "",
      ma8iIdentifier: "",
    });
    window.location.reload(false);
  };

  const validateForm = (data) => {
    const newErrors = {};
    if (!data.ma8iIdentifier || data.ma8iIdentifier.trim() === "") {
      newErrors.ma8iIdentifier = true;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEditSubmit = () => {
    const isFormValid = validateForm(provider);
    if (isFormValid) {
      saveProvider(provider);
    }
  };

  const saveProvider = async (data) => {
    try {
      const response = await updateAgentProvider(localStorage.token, data);
      if (response) {
        toast.success(t("providerUpdatedSuccessfully"), {
          autoClose: 300,
        });
        handleEditClose();
        document.getElementById("closeEditModal").click();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error saving provider from agent profile:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProvider({ ...provider, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  return (
    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card card-profile">
              <div
                className="card-body text-center"
                style={{ alignItems: "center" }}
              >
                <h4 className="mb-3">{agent?.name}</h4>
                {/* <p className="mb-4">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text.
                  <br /> It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old.
                </p> */}
                {/* <button className="btn btn-outline-primary btn-sm">
                  <span className="fa fa-twitter" /> Follow
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <form className="card">
              <div className="card-body">
                <h3 className="card-title">{t("agentInfo")}</h3>
                <div className="row mt-4">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{t("firstName")}</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{t("lastName")}</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        disabled
                        placeholder="Email"
                        value={agent?.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{t("agentNum")}</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder={t("enterAgentNumber")}
                        value={agentNumber}
                        onChange={(e) => setAgentNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{t("bizPartnerNum")}</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder={t("enterBusinessNumber")}
                        value={businessPartnerNumber}
                        onChange={(e) =>
                          setBusinessPartnerNumber(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{t("street")}</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder={t("enterStreet")}
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{t("houseNumber")}</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder={t("enterHouseNumber")}
                        value={houseNumber}
                        onChange={(e) => setHouseNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        {t("addressPostCode")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disabled}
                        placeholder={t("enterPostCode")}
                        value={postcode}
                        onChange={(e) => setPostcode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">{t("location")}</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        placeholder={t("enterLocation")}
                        value={agent?.address?.location}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                {disabled && !isEditMode && (
                  <>
                    <button
                      className="btn btn-primary ml-10"
                      style={{ textTransform: "uppercase" }}
                      onClick={handleEdit}
                    >
                      {t("edit")}
                    </button>
                  </>
                )}
                {isEditMode && (
                  <>
                    <button
                      className="btn btn-primary ml-10"
                      style={{ textTransform: "uppercase" }}
                      onClick={handleSave}
                    >
                      {t("save")}
                    </button>
                    <button
                      className="btn btn-info ml-10"
                      style={{ textTransform: "uppercase" }}
                      onClick={handleCancel}
                    >
                      {t("cancel")}
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ fontWeight: 600 }}>#</th>
                      <th style={{ fontWeight: 600 }}>Krankenkasse</th>
                      <th style={{ fontWeight: 600 }}>MA – 8i-Kennung</th>
                      <th style={{ fontWeight: 600 }}>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {providers?.map((row, index) => (
                      <tr key={index}>
                        <td>{row.providerId}</td>
                        <td>{row.providerName}</td>
                        <td>{row.ma8iIdentifier}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            data-toggle="modal"
                            data-target="#editModal"
                            onClick={() => handleEditProvider(row)}
                          >
                            {t("edit")}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
      <div
        className="modal fade"
        id="editModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                MA–8I-Kennung hinzufügen/bearbeiten
              </h5>
              <button
                type="button"
                id="closeEditModal"
                className="close"
                onClick={handleEditClose}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Krankenkasse
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="providerName"
                      value={provider.providerName}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      MA – 8i-Kennung
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="ma8iIdentifier"
                      className={`form-control  ${
                        errors.ma8iIdentifier ? "is-invalid" : " "
                      }  `}
                      name="ma8iIdentifier"
                      value={provider.ma8iIdentifier}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.ma8iIdentifier && (
                      <div className="invalid-feedback">{t("required")}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEditClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleEditSubmit}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentProfile;
