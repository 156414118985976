import React, { useState } from "react";
import Loading from "./Loading";
import { Dialog } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UploadDialog = ({
  onClose,
  onUpload,
  docTypeId,
  isRequired,
  employeeId,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disbaled, setDisabled] = useState(true);
  const [sendAsAttachment, setSendAsAttachment] = useState(true);
  const { t, i18n } = useTranslation("employee");

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setDisabled(false);
  };

  const isFileSizeValid = (file) => {
    const maxSize = 8 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const handleUpload = async () => {
    if (isFileSizeValid(selectedFile)) {
      setDisabled(true);
      setIsLoading(true);
      const formData = new FormData();
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      let typeId = docTypeId ? docTypeId : -1;
      formData.append("file", selectedFile);
      formData.append("docTypeId", typeId);
      formData.append("sendAsAttachment", sendAsAttachment);
      try {
        const response = await axios.post(
          `https://api.boardi.co/upload/${employeeId}/employee/docs`,
          formData,
          config
        );
        setSelectedFile(null);
        setSendAsAttachment(true);
        setIsLoading(false);
        onUpload();
        onClose();
      } catch (error) {
        toast.error(
          "Fehler beim Hochladen der Datei. Bitte versuche es erneut.",
          {
            autoClose: 500,
          }
        );
        setIsLoading(false);
        onClose();
      }
    } else {
      toast.error(
        "Die Dateigröße überschreitet die maximal zulässige Größe (8 MB)."
      );
    }
  };

  const labelStyle = {
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "4px",
    transition: "background-color 0.3s ease-in-out",
  };

  const inputStyle = {
    display: "none",
  };

  const labelHoverStyle = {
    backgroundColor: "#0056b3",
  };

  return (
    <div className="card text-center" style={{ margin: "0px" }}>
      <div className="card-body">
        <h3 className="card-title">{t("uploadDocument")}</h3>
        <div>
          <label
            htmlFor="fileInput"
            style={{ ...labelStyle, ...(selectedFile && labelHoverStyle) }}
          >
            {t("chooseAFile")}...
          </label>
          <input
            type="file"
            id="fileInput"
            style={inputStyle}
            onChange={handleFileChange}
          />
          {selectedFile && (
            <div>
              <strong>{t("selectedFile")}:</strong> {selectedFile.name}
            </div>
          )}
        </div>
        {!isRequired && (
          <div className="row mt-4">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={sendAsAttachment}
                    onChange={() => setSendAsAttachment(!sendAsAttachment)}
                  />
                  <span
                    className="custom-control-label"
                    style={{
                      color: "#282826",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {t("sendAsAttachment")}
                  </span>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card-footer text-center">
        <button
          className="btn btn-primary"
          onClick={handleUpload}
          disabled={disbaled}
        >
          {t("upload")}
        </button>
        <button className="ml-10 btn btn-info" onClick={onClose}>
          {t("cancel")}
        </button>
      </div>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UploadDialog;
