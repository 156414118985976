import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const Columnchart = ({ storeEarnings,height,yLabel }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          markers: {
            fillColors: ['#5a5278', '#6f6593', '#8075aa', '#a192d9']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        yaxis: {
          title: {
            text: '€ (thousands)' || yLabel
          }
        },
        fill: {
          opacity: 1,
          colors: ['#5a5278', '#6f6593', '#8075aa']
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            }
          }
        }
      },
  });

  useEffect(() => {
    if (storeEarnings && storeEarnings.categories && storeEarnings.series) {
      setChartData({
        series: storeEarnings.series,
        options: {
          ...chartData.options,
          xaxis: {
            categories: storeEarnings.categories,
          },
        },
      });
    }
  }, [storeEarnings]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={height}/>
      </div>
    </div>
  );
};

export default Columnchart;
