import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const axiosInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const downloadApiInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getInsuranceDocumentsList = async (token, employeeId) => {
  try {
    const response = await axiosInstance(token).get(
      "/document/" + employeeId + "/type/list"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyDocumentById = async (employeeId, docId, token) => {
  try {
    const response = await axiosInstance(token).get(
      "/document/" + employeeId + "/" + docId + "/verify"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDocumentById = async (employeeId, docId, token) => {
  try {
    const response = await axiosInstance(token).get(
      "/document/" + employeeId + "/" + docId + "/delete"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadDocumentById = async (employeeId, docId, token) => {
  try {
    const response = await downloadApiInstance(token).get(
      "/download/employee/" + employeeId + "/" + docId,
      {
        responseType: "blob", // Set responseType to 'blob' to handle binary data
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDocumentsByEmployeeId = async (employeeId, token) => {
  try {
    const response = await axiosInstance(token).get("/documents/" + employeeId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const markRequestDoneApi = async (token, requestId) => {
  try {
    const response = await axiosInstance(token).get(
      "/documents/" + requestId + "/done"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const acceptContractApi = async (token, requestId) => {
  try {
    const response = await axiosInstance(token).get(
      "/documents/" + requestId + "/accept"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rejectContractApi = async (token, requestId) => {
  try {
    const response = await axiosInstance(token).get(
      "/documents/" + requestId + "/reject"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRequestApi = async (token, requestId) => {
  try {
    const response = await axiosInstance(token).get(
      "/documents/" + requestId + "/delete"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNonVerfiedDocumentsList = async (token) => {
  try {
    const response = await axiosInstance(token).get("/documents/verify/false");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveEmployeePersonalInfo = async (token, employeeId, data) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/payslip/" + employeeId + "/personal/info",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveEmployeeSalaryInfo = async (token, employeeId, data) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/payslip/" + employeeId + "/salary/info",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveEmployeeTaxInfo = async (token, employeeId, data) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/payslip/" + employeeId + "/tax/info",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveEmployeeSocialSecurityInfo = async (
  token,
  employeeId,
  data
) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/payslip/" + employeeId + "/socialsecurity/info",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPayslipDetailsById = async (token, employeeId) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/" + employeeId + "/payslip/details"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
