import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const axiosInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const tokenApiInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createCompany = async (token, data) => {
  try {
    const response = await axiosInstance(token).post("/company", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSubCompany = async (token, data, companyId) => {
  try {
    const response = await axiosInstance(token).post(
      "/company/" + companyId + "/daughter",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editCompanyApi = async (token, data) => {
  try {
    const response = await axiosInstance(token).post("/company/edit", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyList = async (token) => {
  try {
    const response = await axiosInstance(token).get("/company/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editCompany = async (token, data) => {
  try {
    const response = await axiosInstance(token).post("/company/edit", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCompanyById = async (token, companyId) => {
  try {
    const response = await axiosInstance(token).get(
      "/company/" + companyId + "/delete"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const validateToken = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/validate/token");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDropDownList = async (token) => {
  try {
    const response = await axiosInstance(token).get("/company/dropdown/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getManagerDropDown = async (token) => {
  try {
    const response = await axiosInstance(token).get("/manager/dropdown/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAgentDashboard = async (token) => {
  try {
    const response = await axiosInstance(token).get("/agent/dashboard");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getManagerDashboard = async (token) => {
  try {
    const response = await axiosInstance(token).get("/manager/dashboard");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDetailsById = async (token, id) => {
  try {
    const response = await axiosInstance(token).get(
      "/company/" + id + "/details"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addManager = async (data, token) => {
  try {
    const response = await axiosInstance(token).post("/manager", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editManagerApi = async (data, token) => {
  try {
    const response = await axiosInstance(token).post(
      "/manager/" + data.id + "/edit",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteManagerApi = async (id, token) => {
  try {
    const response = await axiosInstance(token).get(
      "/manager/" + id + "/delete"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getManagerListingApi = async (token) => {
  try {
    const response = await axiosInstance(token).get("/manager/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyManagersDropDown = async (token, companyId) => {
  try {
    const response = await axiosInstance(token).get(
      "/manager/" + companyId + "/dropdown/list"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCompanyManagers = async (
  token,
  companyId,
  assignedManagers
) => {
  try {
    const response = await axiosInstance(token).post(
      "/manager/" + companyId + "/update",
      assignedManagers
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
