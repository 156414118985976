import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { submitGDPRConsent } from "../../services/apiService";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const GDPRConsentForm = ({ setShowConsentForm, changeLanguage }) => {
  const [consentGiven, setConsentGiven] = useState(false);
  const { t } = useTranslation("common");
  const history = useHistory();

  const handleCheckboxChange = () => {
    setConsentGiven(!consentGiven);
  };

  const submitConsent = async () => {
    try {
      const response = await submitGDPRConsent(localStorage.token, true);
      localStorage.setItem("consentAccepted", true);
      setShowConsentForm(false);
    } catch (error) {
      if (error.isAxiosError && error.response) {
        console.error("Server returned an error:", error.response.data);
      } else if (error.isAxiosError) {
        console.error("Request failed without a response:", error.message);
      } else {
        console.error("Error setting up the request:", error.message);
      }
      toast.error("Something Went Wrong. Please Try Again.");
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title" style={{ color: "#5a5278" }}>
            <b>Insure Sync</b>
          </h4>
          <div className="card-options">
            <a
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Language
            </a>
            <div className="dropdown-menu">
              <button
                className="dropdown-item"
                onClick={() => changeLanguage("en")}
                style={{ display: "flex" }}
              >
                <img
                  className="w20 mr-2"
                  src="https://boardi.co/assets/images/flags/us.svg"
                  alt="fake_url"
                />
                English
              </button>
              <div className="dropdown-divider" />
              <button
                className="dropdown-item"
                onClick={() => changeLanguage("de")}
                style={{ display: "flex" }}
              >
                <img
                  className="w20 mr-2"
                  src="https://boardi.co/assets/images/flags/de.svg"
                  alt="fake_url"
                />
                Deutsch
              </button>
              <div className="dropdown-divider" />
            </div>
            <button
              id="close"
              type="button"
              className="close ml-4"
              aria-label="Close"
              onClick={() => {
                history.push("/login");
              }}
            >
              {/* <span aria-hidden="true">×</span> */}
            </button>
          </div>
        </div>
        <div className="card-body">
          <img
            className="card-img-top rounded-full mx-auto"
            src="../assets/images/gdpr.png"
            alt=""
            width={200}
            height={140}
            style={{
              maxWidth: "200px",
              maxHeight: "140px",
              width: "200px",
              height: "140px",
            }}
          />
          <h5 className="card-title text-center" style={{ color: "#5a5278" }}>
            {t("gdprTitle")}
          </h5>
          <p className="card-text" style={{ fontSize: "14px" }}>
            {t("gdprPara1")}
          </p>
          <p className="card-text" style={{ fontSize: "14px" }}>
            {t("gdprPara2")}
          </p>
          <p className="card-text" style={{ fontSize: "14px" }}>
            {t("gdprPara3")}{" "}
            <Link
              to="/data/protection/policy"
              target="_blank"
              style={{ color: "#27A6EA", cursor: "pointer" }}
            >
              <b>{t("dataProtection")}</b>
            </Link>{" "}
            {t("gdprPara4")}{" "}
            <Link
              to="/terms/conditions"
              target="_blank"
              style={{ color: "#27A6EA", cursor: "pointer" }}
            >
              <b>{t("termsAndConditions")}</b>
            </Link>
          </p>
        </div>
        <div className="card-footer text-right" style={{ fontSize: "16px" }}>
          <button className="btn btn-primary" onClick={submitConsent}>
            {t("agreeBtn")}
          </button>
        </div>
      </div>
    </>
  );
};

export default GDPRConsentForm;
