import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEmployeeAccountApi } from "../../../services/employeeService";
import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Loading from "../../Utility/Loading";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import NoData from "../../Utility/NoData";

function Accounts() {
  const { t, i18n } = useTranslation("common");
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const [employeeAccountData, setEmployeeAccountData] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const labelStyle = {
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "4px",
    transition: "background-color 0.3s ease-in-out",
  };

  const inputStyle = {
    display: "none",
  };

  const labelHoverStyle = {
    backgroundColor: "#0056b3",
  };

  const isFileSizeValid = (file) => {
    const maxSize = 8 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const handleUpload = () => {
    setIsLoading(true);
    if (file && isFileSizeValid(file)) {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("https://api.boardi.co/account/upload/csv", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response) {
            toast.success(t("matchedMsg"));
            getEmployeeAccount();
          } else {
            toast.error(t("somethingWentWrong"));
          }
          setUploadDialogOpen(false);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setUploadDialogOpen(false);
          console.error("Error uploading file:", error);
        });
    } else {
      setIsLoading(false);
      setUploadDialogOpen(false);
      console.error("No file selected");
    }
  };

  useEffect(() => {
    getEmployeeAccount();
  }, []);

  useEffect(() => {}, [employeeAccountData]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employeeAccountData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(employeeAccountData.length / itemsPerPage);

  const getDisplayPages = () => {
    const pages = [];
    const pageWindow = 3; // Number of pages to display

    if (totalPages <= pageWindow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        for (let i = 1; i <= pageWindow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages) {
        for (let i = totalPages - pageWindow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const start = currentPage - 1;
        const end = currentPage + 1;

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  };

  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getEmployeeAccount = async () => {
    try {
      const response = await getEmployeeAccountApi(localStorage.token);
      if (response) {
        setEmployeeAccountData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setUploadDialogOpen(false);
    setFile(null);
  };

  const providerMap = {
    VIACTIVKRANKENKASSE: "Viactiv Krankenkasse",
    MOBILKRANKENKASSE: "Mobil Krankenkasse",
  };

  const locale = {
    en: "en-US",
    de: "de-DE",
  };

  const formatDateGerman = (date) => {
    if (date === undefined) return "";
    const dateObject = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat(locale[i18n.language], options).format(
      dateObject
    );
  };

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="nav nav-tabs page-header-tab">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="Accounts-tab"
                    data-toggle="tab"
                    href="#Accounts-Payments"
                  >
                    {t("payment")}
                  </a>
                </li>
              </ul>
              <div className="header-action">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setUploadDialogOpen(true)}
                >
                  <i className="fe fe-plus mr-2" />
                  {t("performMatching")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className="tab-pane fade show active"
                id="Accounts-Payments"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive invoice_list">
                      <table className="table table-hover table-striped table-vcenter">
                        <thead>
                          <tr>
                            <th style={{ fontWeight: 600 }} className="w40">
                              #
                            </th>
                            <th>ID</th>
                            <th>{t("employeeName")}</th>
                            <th>{t("insuranceName")}</th>
                            <th>{t("refNum")}</th>
                            <th>{t("matchedDate")}</th>
                            <th>{t("paymentStatus")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((account, index) => (
                            <tr>
                              <td className="w40">{account.index}</td>
                              <td>{account.idNumber}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src="../assets/images/userAvatar.png"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Avatar Name"
                                    alt="Avatar"
                                    className="w35 h35 rounded"
                                  />
                                  <div
                                    className="ml-3"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <NavLink
                                      to={
                                        "/employee/details?id=" +
                                        account.employeeId
                                      }
                                      target="_blank"
                                    >
                                      {account.employeeName}
                                    </NavLink>
                                    <p className="mb-0">
                                      {account.companyName}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="ml-3"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {providerMap[account.providerName]}
                                    <p
                                      className="mb-0"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {account.mailSentTime && (
                                        <>
                                          {t("mailedOn")}{" "}
                                          {formatDateGerman(
                                            account.mailSentTime
                                          )}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="ml-3"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {account.billingRefNum}
                                    <p
                                      className="mb-0"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {account.billingRefNumReceivedDate && (
                                        <>
                                          {t("receivedOn")}{" "}
                                          {formatDateGerman(
                                            account.billingRefNumReceivedDate
                                          )}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="mb-0">
                                  {account.matchedDate && (
                                    <>{formatDateGerman(account.matchedDate)}</>
                                  )}
                                </p>
                              </td>
                              <td>
                                {account.paymentDone && (
                                  <span
                                    className="tag tag-success"
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      color: "white",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {t("received")}
                                  </span>
                                )}
                                {!account.paymentDone && (
                                  <span
                                    className="tag tag-warning"
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      color: "white",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {t("pending")}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {currentItems.length == 0 && (
                        <NoData
                          messageHeading={
                            "No Data related to accounting present as of now"
                          }
                          messageSubHeading={""}
                          messageLink={"/"}
                          noClick={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <nav>
                    <ul className="pagination">
                      {currentPage > 1 && (
                        <li
                          className="page-item"
                          style={{ padding: "5px 2px" }}
                        >
                          <button
                            onClick={() => handleClick(currentPage - 1)}
                            className="page-link"
                            style={{
                              fontSize: "14px",
                              padding: "2px 10px",
                              fontWeight: 600,
                            }}
                          >
                            <ChevronLeft size={17} />
                          </button>
                        </li>
                      )}

                      {getDisplayPages().map((page) => (
                        <li
                          key={page}
                          className={`page-item ${
                            currentPage === page ? "active" : ""
                          }`}
                          style={{ padding: "5px 2px" }}
                        >
                          <button
                            onClick={() => handleClick(page)}
                            className="page-link"
                            style={{
                              fontSize: "14px",
                              padding: "2px 10px",
                              fontWeight: 600,
                              backgroundColor:
                                currentPage === page ? "gray" : " ",
                            }}
                          >
                            {page}
                          </button>
                        </li>
                      ))}

                      {currentPage < totalPages && (
                        <li
                          className="page-item"
                          style={{ padding: "5px 2px" }}
                        >
                          <button
                            onClick={() => handleClick(currentPage + 1)}
                            className="page-link"
                            style={{
                              fontSize: "14px",
                              padding: "2px 10px",
                              fontWeight: 600,
                            }}
                          >
                            <ChevronRight size={17} />
                          </button>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={uploadDialogOpen}
          style={{ backdropFilter: "blur(2px)" }}
          fullWidth
        >
          <div className="card text-center" style={{ margin: "0px" }}>
            <div className="card-body">
              <h3 className="card-title">{t("uploadDocument")}</h3>
              <div>
                <label
                  htmlFor="fileInput"
                  style={{
                    ...labelStyle,
                    ...(file && labelHoverStyle),
                  }}
                >
                  {t("chooseAFile")}...
                </label>
                <input
                  type="file"
                  id="fileInput"
                  style={inputStyle}
                  onChange={handleFileChange}
                />
                {file && (
                  <div>
                    <strong>{t("selectedFile")}:</strong> {file.name}
                  </div>
                )}
              </div>
            </div>
            <div className="card-footer text-center">
              <button
                className="btn btn-primary"
                onClick={handleUpload}
                disabled={!file}
              >
                {t("upload")}
              </button>
              <button className="ml-10 btn btn-info" onClick={onClose}>
                {t("cancel")}
              </button>
            </div>
            <Dialog
              open={isLoading}
              style={{ backdropFilter: "blur(2px)" }}
              fullWidth
            >
              <div
                className="flex flex-col justify-center"
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  className="mx-auto"
                  style={{ backgroundColor: "transparent" }}
                >
                  <Loading />
                </div>
              </div>
            </Dialog>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default Accounts;
