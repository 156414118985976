import React from "react";
import LottieWrapper from "./LottieWrapper";
import animationData from "./noDataFolder.json";
import { useTranslation } from "react-i18next";

const NoData = ({
  messageHeading,
  messageSubHeading,
  messageLink,
  noClick,
}) => {
  const { t } = useTranslation("common");
  return (
    <div
      style={{ justifyContent: "center" }}
      className="item-center text-center"
    >
      <LottieWrapper animationData={animationData} height={250} />
      <p className="m-2" style={{ textTransform: "" }}>
        {messageHeading}
      </p>
      <p className="m-2">
        {messageSubHeading}{" "}
        {!noClick && <a href={messageLink}>&nbsp;{t("clickHere")}</a>}
      </p>
    </div>
  );
};

export default NoData;
