"use client"
import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const Context = ({children}) => {


  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(undefined);
  const [expanded,setExpanded] = useState(true);  
  const [userName,setUserName] = useState('');  
  const [userEmail,setUserEmail] = useState('');  
  const [userCompany,setUserCompany] = useState('');  
  const [authorities,setAuthorities] = useState([]); 
  const [role,setRole] = useState(''); 

  const setLoggedIn = (loggedIn) => {
    setIsLoggedIn(loggedIn);
    localStorage.setItem('isLoggedIn', loggedIn);
  };

  const setJwtToken = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const setUserDetails = (user) => {
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userEmail', user.userName);
    localStorage.setItem('userCompany', user.companyId);
    localStorage.setItem('authorities', user.authorities);
    localStorage.setItem('token', user.token);
    localStorage.setItem('companyId', user.companyId);
    localStorage.setItem('role',user.role);
    localStorage.setItem('consentAccepted',user.consentAccepted);
    localStorage.setItem('profileComplete',user.profileComplete);
    setAuthorities(user.authorities);
    setRole(user.role);
  };
  

  return (
    <div>
        <StateContext.Provider
            value={{
                isLoggedIn,
                setLoggedIn,
                setIsLoggedIn,
                token,
                setJwtToken,
                setToken,
                setUserDetails,
                expanded,
                setExpanded,
                role,
                setRole,
                authorities,
                setAuthorities
            }}
        >
            {children}
        </StateContext.Provider>
    </div>
  )
}

export default Context;

export const useStateContext = () => useContext(StateContext);


