import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserActivityList } from "../../../services/apiService";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const UserActivity = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [activityData, setActivityData] = useState([]);
  const { t } = useTranslation("common");

  useEffect(() => {
    fetchUserActivity();
  }, []);

  useEffect(() => {}, [activityData]);

  const filteredData = activityData.filter(
    (activity) =>
      !searchName ||
      activity.employeeName.toLowerCase().includes(searchName.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const getDisplayPages = () => {
    const pages = [];
    const pageWindow = 3; // Number of pages to display

    if (totalPages <= pageWindow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        for (let i = 1; i <= pageWindow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages) {
        for (let i = totalPages - pageWindow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const start = currentPage - 1;
        const end = currentPage + 1;

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  };

  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const resetFilters = () => {
    setSearchName("");
  };

  const fetchUserActivity = async () => {
    try {
      const response = await getUserActivityList(
        localStorage.token,
        localStorage.companyId
      );
      setActivityData(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
          <div className="container-fluid">
            {/* <div className="row clearfix">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Timeline Activity</h3>
                  </div>
                  <div className="card-body">
                    <div className="timeline_item ">
                      <img
                        className="tl_avatar"
                        src="../assets/images/xs/avatar1.jpg"
                        alt="fake_url"
                      />
                      <span>
                        <a href="fake_url;">Elisse Joson</a> San Francisco, CA{" "}
                        <small className="float-right text-right">
                          20-April-2019 - Today
                        </small>
                      </span>
                      <h6 className="font600">
                        Hello, 'Im a single div responsive timeline without
                        media Queries!
                      </h6>
                      <div className="msg">
                        <p>
                          I'm speaking with myself, number one, because I have a
                          very good brain and I've said a lot of things. I write
                          the best placeholder text, and I'm the biggest
                          developer on the web card she has is the Lorem card.
                        </p>
                        <a href="fake_url;" className="mr-20 text-muted">
                          <i className="fa fa-heart text-pink" /> 12 Love
                        </a>
                        <a
                          className="text-muted"
                          role="button"
                          data-toggle="collapse"
                          href="#collapseExample"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <i className="fa fa-comments" /> 1 Comment
                        </a>
                        <div
                          className="collapse p-4 section-gray mt-2"
                          id="collapseExample"
                        >
                          <form className="well">
                            <div className="form-group">
                              <textarea
                                rows={2}
                                className="form-control no-resize"
                                placeholder="Enter here for tweet..."
                                defaultValue={""}
                              />
                            </div>
                            <button className="btn btn-primary">Submit</button>
                          </form>
                          <ul className="recent_comments list-unstyled mt-4 mb-0">
                            <li>
                              <div className="avatar_img">
                                <img
                                  className="rounded img-fluid"
                                  src="../assets/images/xs/avatar4.jpg"
                                  alt="fake_url"
                                />
                              </div>
                              <div className="comment_body">
                                <h6>
                                  Donald Gardner{" "}
                                  <small className="float-right font-14">
                                    Just now
                                  </small>
                                </h6>
                                <p>
                                  Lorem ipsum Veniam aliquip culpa laboris minim
                                  tempor
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="timeline_item ">
                      <img
                        className="tl_avatar"
                        src="../assets/images/xs/avatar4.jpg"
                        alt="fake_url"
                      />
                      <span>
                        <a href="fake_url">Dessie Parks</a> Oakland, CA{" "}
                        <small className="float-right text-right">
                          19-April-2019 - Yesterday
                        </small>
                      </span>
                      <h6 className="font600">
                        Oeehhh, that's awesome.. Me too!
                      </h6>
                      <div className="msg">
                        <p>
                          I'm speaking with myself, number one, because I have a
                          very good brain and I've said a lot of things. on the
                          web by far... While that's mock-ups and this is
                          politics, are they really so different? I think the
                          only card she has is the Lorem card.
                        </p>
                        <div className="timeline_img mb-20">
                          <img
                            className="width100"
                            src="../assets/images/gallery/1.jpg"
                            alt="Awesome"
                          />
                          <img
                            className="width100"
                            src="../assets/images/gallery/2.jpg"
                            alt="Awesome"
                          />
                        </div>
                        <a href="fake_url;" className="mr-20 text-muted">
                          <i className="fa fa-heart text-pink" /> 23 Love
                        </a>
                        <a
                          className="text-muted"
                          role="button"
                          data-toggle="collapse"
                          href="#collapseExample1"
                          aria-expanded="false"
                          aria-controls="collapseExample1"
                        >
                          <i className="fa fa-comments" /> 2 Comment
                        </a>
                        <div
                          className="collapse p-4 section-gray mt-2"
                          id="collapseExample1"
                        >
                          <form className="well">
                            <div className="form-group">
                              <textarea
                                rows={2}
                                className="form-control no-resize"
                                placeholder="Enter here for tweet..."
                                defaultValue={""}
                              />
                            </div>
                            <button className="btn btn-primary">Submit</button>
                          </form>
                          <ul className="recent_comments list-unstyled mt-4 mb-0">
                            <li>
                              <div className="avatar_img">
                                <img
                                  className="rounded img-fluid"
                                  src="../assets/images/xs/avatar4.jpg"
                                  alt="fake_url"
                                />
                              </div>
                              <div className="comment_body">
                                <h6>
                                  Donald Gardner{" "}
                                  <small className="float-right font-14">
                                    Just now
                                  </small>
                                </h6>
                                <p>
                                  Lorem ipsum Veniam aliquip culpa laboris minim
                                  tempor
                                </p>
                                <div className="timeline_img mb-20">
                                  <img
                                    className="width150"
                                    src="../assets/images/gallery/7.jpg"
                                    alt="Awesome"
                                  />
                                  <img
                                    className="width150"
                                    src="../assets/images/gallery/8.jpg"
                                    alt="Awesome"
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="avatar_img">
                                <img
                                  className="rounded img-fluid"
                                  src="../assets/images/xs/avatar3.jpg"
                                  alt="fake_url"
                                />
                              </div>
                              <div className="comment_body">
                                <h6>
                                  Dessie Parks{" "}
                                  <small className="float-right font-14">
                                    1min ago
                                  </small>
                                </h6>
                                <p>
                                  It is a long established fact that a reader
                                  will be distracted by the readable content of
                                  a page when looking
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="timeline_item ">
                      <img
                        className="tl_avatar"
                        src="../assets/images/xs/avatar7.jpg"
                        alt="fake_url"
                      />
                      <span>
                        <a href="fake_url">Rochelle Barton</a> San Francisco, CA{" "}
                        <small className="float-right text-right">
                          12-April-2019
                        </small>
                      </span>
                      <h6 className="font600">
                        An Engineer Explains Why You Should Always Order the
                        Larger Pizza
                      </h6>
                      <div className="msg">
                        <p>
                          I'm speaking with myself, number one, because I have a
                          very good brain and I've said a lot of things. I write
                          the best placeholder text, and I'm the biggest
                          developer on the web by far... While that's mock-ups
                          and this is politics, is the Lorem card.
                        </p>
                        <a href="fake_url;" className="mr-20 text-muted">
                          <i className="fa fa-heart text-pink" /> 7 Love
                        </a>
                        <a
                          className="text-muted"
                          role="button"
                          data-toggle="collapse"
                          href="#collapseExample2"
                          aria-expanded="false"
                          aria-controls="collapseExample2"
                        >
                          <i className="fa fa-comments" /> 1 Comment
                        </a>
                        <div
                          className="collapse p-4 section-gray mt-2"
                          id="collapseExample2"
                        >
                          <form className="well">
                            <div className="form-group">
                              <textarea
                                rows={2}
                                className="form-control no-resize"
                                placeholder="Enter here for tweet..."
                                defaultValue={""}
                              />
                            </div>
                            <button className="btn btn-primary">Submit</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">{t("activities")}</h3>
                <div className="card-options">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        onChange={(e) => setSearchName(e.target.value)}
                        placeholder="Search By Name..."
                        value={searchName}
                        name="s"
                      />
                      <span className="input-group-btn ml-2">
                        <button className="btn btn-icon" type="submit">
                          <span className="fe fe-search" />
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-vcenter table-hover mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>{t("employee")}</th>
                        <th>{t("userName")}</th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          {t("branchName")}
                        </th>
                        <th>{t("role")}</th>
                        <th style={{ whiteSpace: "nowrap" }}>
                          {t("activity")}
                        </th>
                        <th>{t("timestamp")}</th>
                      </tr>
                    </thead>
                    <tbody className="table-hover">
                      {currentItems.map((activity, index) => (
                        <tr key={index}>
                          <td className="d-flex">
                            <span
                              className="avatar avatar-blue"
                              data-toggle="tooltip"
                              data-original-title={activity.employeeName}
                            >
                              {activity.employeeName.substring(0, 2)}
                            </span>
                            <div className="ml-3">
                              <h6 className="mb-0">{activity.employeeName}</h6>
                              <span className="text-muted">
                                {activity.userName}
                              </span>
                            </div>
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            <div className="font-15">{activity.userName}</div>
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            <div className="font-15">{activity.storeName}</div>
                          </td>
                          <td style={{ whiteSpace: "nowrap", fontWeight: 500 }}>
                            {activity.role}
                          </td>
                          <td
                            style={{
                              whiteSpace: "nowrap",
                              color: "blue",
                              fontWeight: 500,
                            }}
                          >
                            {activity.action}
                          </td>
                          <td>{activity.timeStampString}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <nav>
                      <ul className="pagination">
                        {currentPage > 1 && (
                          <li
                            className="page-item"
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(currentPage - 1)}
                              className="page-link" style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600
                              }}
                            >
                              Previous
                            </button>
                          </li>
                        )}

                        {getDisplayPages().map((page) => (
                          <li
                            key={page}
                            className={`page-item ${
                              currentPage === page ? "active" : ""
                            }`}
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(page)}
                              className="page-link" style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600,
                                backgroundColor:
                                  currentPage === page ? "gray" : " ",
                              }}
                            >
                              {page}
                            </button>
                          </li>
                        ))}

                        {currentPage < totalPages && (
                          <li
                            className="page-item"
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(currentPage + 1)}
                              className="page-link" style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600
                              }}
                            >
                              Next
                            </button>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserActivity;
