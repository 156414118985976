import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import {
  addManager,
  deleteManagerApi,
  editManagerApi,
  getCompanyDropDownList,
  getManagerListingApi,
} from "../../../services/companyService";
import { Dialog } from "@mui/material";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Loading from "../../Utility/Loading";
import countryData from "../../../constants/countryData";
import MultiSelect from "../../Utility/MultiSelect";

function Managers(props) {
  const { fixNavbar } = props;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [managerData, setManagerData] = useState([]);
  const [managerId, setManagerId] = useState("");
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState({
    managerEmail: "",
    managerFirstName: "",
    managerLastName: "",
    phone: "",
    countryCode: "",
    assignedCompanies: [],
  });
  const [errors, setErrors] = useState({
    managerEmail: false,
    managerFirstName: false,
    managerLastName: false,
  });
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [assignedCompanies, setAssignedCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editManager, setEditManager] = useState(null);
  const [companiesManaged, setCompaniesManaged] = useState(null);
  const [managerName, setManagerName] = useState(null);

  const countryCodeOptions = countryData.map((country) => ({
    value: country.dial_code,
    label: `${country.dial_code} - ${country.name}`,
  }));

  useEffect(() => {
    getCompanyDropDown();
    getManagerListing();
  }, []);

  useEffect(() => {}, [managerData, companyOptions]);

  const getCompanyDropDown = async () => {
    try {
      const response = await getCompanyDropDownList(localStorage.token);
      setCompanyOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getManagerListing = async () => {
    try {
      const response = await getManagerListingApi(localStorage.token);
      setManagerData(response);
      setFilteredData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const resetFilters = () => {
    setSearchName("");
    setSearchEmail("");
    setSelectedCompany(null);
    setFilteredData(managerData);
  };

  const applyFilters = () => {
    const filteredData = managerData
      .filter(
        (manager) =>
          !searchName || manager.name.toLowerCase().includes(searchName)
      )
      .filter(
        (manager) =>
          !searchEmail ||
          manager.managerEmail.toLowerCase().includes(searchEmail)
      )
      .filter(
        (manager) =>
          !selectedCompany ||
          manager.assignedCompanies.includes(selectedCompany.value)
      );
    setFilteredData(filteredData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const getDisplayPages = () => {
    const pages = [];
    const pageWindow = 3; // Number of pages to display

    if (totalPages <= pageWindow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        for (let i = 1; i <= pageWindow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages) {
        for (let i = totalPages - pageWindow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const start = currentPage - 1;
        const end = currentPage + 1;

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  };

  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDeleteClick = (id, name) => {
    setManagerId(id);
  };

  const handleDeleteConfirm = () => {
    setIsLoading(true);
    deleteManager();
    setIsLoading(false);
  };

  const deleteManager = async () => {
    try {
      const response = await deleteManagerApi(managerId, localStorage.token);
      if (response) {
        document.getElementById("closeDeleteConfirmBtn").click();
        toast.success("Deleted Successfully");
        getManagerListing();
      } else {
        toast.error(t("somethingWentWrong"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditManager({ ...editManager, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleDropDownInputChange = (e) => {
    setFormData({ ...formData, ["countryCode"]: e.value });
  };
  const handleEditDropDownInputChange = (e) => {
    setEditManager({ ...editManager, ["countryCode"]: e.value });
  };

  const validateForm = (data) => {
    const newErrors = {};
    if (!data.managerEmail || data.managerEmail.trim() === "") {
      newErrors.managerEmail = true;
    }
    if (!data.managerFirstName || data.managerFirstName.trim() === "") {
      newErrors.managerFirstName = true;
    }
    if (!data.managerLastName || data.managerLastName.trim() === "") {
      newErrors.managerLastName = true;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      saveManager();
    }
  };

  const handleEditSubmit = () => {
    const isFormValid = validateForm(editManager);
    if (isFormValid) {
      editManagerSubmit();
    }
  };

  const saveManager = async () => {
    try {
      let request = { ...formData, assignedCompanies: assignedCompanies };
      const response = await addManager(request, localStorage.token);
      if (response) {
        toast.success(
          t("managerCreatedSuccessfully") + " " + t("companyManagerMsg"),
          {
            autoClose: 300,
          }
        );
        handleClose();
        document.getElementById("closeAddModal").click();
        getManagerListing();
      } else {
        toast.error(
          "There was an error processing your request. Check if manager email is already registered with us or contact support."
        );
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error saving manager:", error);
    }
  };

  const editManagerSubmit = async () => {
    try {
      let request = { ...editManager, assignedCompanies: assignedCompanies };
      const response = await editManagerApi(request, localStorage.token);
      if (response) {
        toast.success("Manager Updated", {
          autoClose: 300,
        });
        handleEditClose();
        document.getElementById("closeEditManagerModal").click();
        getManagerListing();
      } else {
        toast.error(
          "There was an error processing your request.  Try again or Contact support."
        );
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error editing manager:", error);
    }
  };

  const handleClose = () => {
    setFormData({
      managerEmail: "",
      managerFirstName: "",
      managerLastName: "",
      phone: "",
      countryCode: "",
    });
    setErrors({
      managerEmail: false,
      managerFirstName: false,
      managerLastName: false,
    });
    setAssignedCompanies([]);
  };

  function getCommaSeparatedCompanies(companiesManaged) {
    if (!Array.isArray(companiesManaged) || companiesManaged.length === 0) {
      return "-"; // Return empty string if input is not an array or is empty
    }
    return companiesManaged.join(", ");
  }

  const handleEditManagerModal = (manager) => {
    setEditManager(manager);
    setAssignedCompanies(manager.assignedCompanies);
  };

  const handleEditClose = () => {
    setEditManager(null);
    setAssignedCompanies([]);
    setErrors({
      managerEmail: false,
      managerFirstName: false,
      managerLastName: false,
    });
  };
  return (
    <div>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="Departments-tab"
                  data-toggle="tab"
                  href="#Departments-list"
                >
                  Liste
                </a>
              </li>
              {/* <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li>
               */}
            </ul>
            <div className="header-action">
              <button
                type="button"
                className="btn btn-primary mt-2 btn-block"
                data-toggle="modal"
                data-target="#addModal"
              >
                <i className="fe fe-plus mr-2" />
                {t("addManager")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="Departments-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2 col-sm-6">
                      <label>{t("company")}</label>
                      <div className="multiselect_div">
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={companyOptions}
                          value={selectedCompany}
                          onChange={(selectedOption) =>
                            setSelectedCompany(selectedOption)
                          }
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>{t("searchByName")}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                          className="form-control"
                          placeholder={t("enterHere")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>{t("searchByEmail")}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          value={searchEmail}
                          onChange={(e) => setSearchEmail(e.target.value)}
                          className="form-control"
                          placeholder={t("enterHere")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>&nbsp;</label>
                      <button
                        onClick={() => {
                          applyFilters();
                        }}
                        className="btn btn-sm btn-primary btn-block p-2"
                      >
                        {t("filter")}
                      </button>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>&nbsp;</label>
                      <button
                        onClick={() => {
                          resetFilters();
                        }}
                        className="btn btn-sm btn-primary btn-block p-2"
                      >
                        {t("reset")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title" style={{ fontWeight: 500 }}>
                    {t("companyList")}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive mb-4">
                    <table className="table  table-vcenter table-hover mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ fontWeight: 600 }} className="w40">
                            #
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("managerName")}
                          </th>
                          {/* <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                             Email
                          </th> */}
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("phone")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("accountCreated")}
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              fontWeight: 600,
                            }}
                          >
                            {t("compManaged")}
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              fontWeight: 600,
                            }}
                          >
                            {t("employeeCount")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-hover">
                        {currentItems.map((manager, index) => (
                          <tr key={manager.id}>
                            <td className="w40">{manager.index}</td>
                            <td style={{ display: "flex" }}>
                              <span
                                className="avatar avatar-blue"
                                data-toggle="tooltip"
                                data-original-title={manager.name}
                              >
                                {manager.name.substring(0, 2)}
                              </span>
                              <div className="ml-3">
                                <h6 className="mb-0">{manager.name}</h6>
                                <span className="text-muted">
                                  {manager.managerEmail}
                                </span>
                              </div>
                            </td>
                            <td>
                              {manager.countryCode}
                              {" - "}
                              {manager.phone}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {manager.invitationSent ? (
                                <>
                                  <span
                                    className="tag tag-success"
                                    style={{
                                      fontWeight: 600,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {t("inviteSent")}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span
                                    className="tag tag-warning"
                                    style={{
                                      fontWeight: 600,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {t("pending")}
                                  </span>
                                </>
                              )}
                            </td>
                            <td
                              //className="d-flex"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {/* {getCommaSeparatedCompanies(
                                manager.companiesManaged
                              )} */}
                              <span>
                                <ul className="list-unstyled team-info sm margin-0">
                                  {manager.companiesManaged &&
                                  manager.companiesManaged.length > 4 ? (
                                    <>
                                      {[...Array(4)].map((_, index) => (
                                        <li key={index}>
                                          <img
                                            src={`/assets/images/xs/company${
                                              index + 1
                                            }.png`}
                                            alt={`Avatar ${index + 1}`}
                                            title={
                                              manager.companiesManaged[index]
                                            }
                                          />
                                        </li>
                                      ))}
                                      {manager.companiesManaged &&
                                        manager.companiesManaged.length > 4 && (
                                          <li className="ml-2">
                                            <span>
                                              {manager.companiesManaged.length -
                                                4}
                                              +
                                            </span>
                                          </li>
                                        )}
                                    </>
                                  ) : (
                                    <>
                                      {manager.companiesManaged &&
                                        manager.companiesManaged.map(
                                          (_, index) => (
                                            <li key={index}>
                                              <img
                                                src={`/assets/images/xs/company${
                                                  index + 1
                                                }.png`}
                                                alt={`Avatar ${index + 1}`}
                                                title={
                                                  manager.companiesManaged[
                                                    index
                                                  ]
                                                }
                                              />
                                            </li>
                                          )
                                        )}
                                    </>
                                  )}
                                </ul>
                              </span>
                              {!manager.companiesManaged ||
                                (manager.companiesManaged &&
                                  manager.companiesManaged.length !== 0 && (
                                    <span className="ml-2">
                                      <button
                                        onClick={() => {
                                          setCompaniesManaged(
                                            manager.companiesManaged
                                          );
                                          setManagerName(manager.name);
                                        }}
                                        data-toggle="modal"
                                        data-target="#companiesManagedModal"
                                        style={{
                                          fontWeight: 550,
                                          color: "navy",
                                        }}
                                      >
                                        View
                                      </button>
                                    </span>
                                  ))}
                            </td>
                            <td>
                              <span
                                style={{
                                  justifyContent: "center",
                                  backgroundColor: "#90CDF4",
                                  color: "#374151",
                                  padding: "0.5rem 1rem",
                                  borderRadius: "999px",
                                  fontWeight: "bold",
                                }}
                              >
                                {manager.employeeCount}
                              </span>
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleEditManagerModal(manager)}
                                className="btn btn-icon"
                                title="Edit"
                                data-toggle="modal"
                                data-target="#editManagerModal"
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteClick(manager.id)}
                                className="btn btn-icon"
                                data-toggle="modal"
                                data-target="#deleteConfirmationModal"
                              >
                                <i className="fa fa-trash-o text-red-400" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <nav>
                      <ul className="pagination">
                        {currentPage > 1 && (
                          <li
                            className="page-item"
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(currentPage - 1)}
                              className="page-link"
                              style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600,
                              }}
                            >
                              <ChevronLeft size={17} />
                            </button>
                          </li>
                        )}

                        {getDisplayPages().map((page) => (
                          <li
                            key={page}
                            className={`page-item ${
                              currentPage === page ? "active" : ""
                            }`}
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(page)}
                              className="page-link"
                              style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600,
                                backgroundColor:
                                  currentPage === page ? "gray" : " ",
                              }}
                            >
                              {page}
                            </button>
                          </li>
                        ))}

                        {currentPage < totalPages && (
                          <li
                            className="page-item"
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(currentPage + 1)}
                              className="page-link"
                              style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600,
                              }}
                            >
                              <ChevronRight size={17} />
                            </button>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                {t("addNewManager")}
              </h5>
              <button
                type="button"
                id="closeAddModal"
                className="close"
                onClick={handleClose}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      {t("managerFirstName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.managerFirstName ? "is-invalid" : " "
                      }  `}
                      name="managerFirstName"
                      value={formData.managerFirstName}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.managerFirstName && (
                      <div className="invalid-feedback">{t("required")}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      {t("managerLastName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.managerLastName ? "is-invalid" : " "
                      }  `}
                      name="managerLastName"
                      value={formData.managerLastName}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.managerLastName && (
                      <div className="invalid-feedback">{t("required")}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Manager Email
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>

                    {/* <input type="text" className="form-control" placeholder="managers Email" /> */}
                    <input
                      type="managerEmail"
                      className={`form-control  ${
                        errors.managerEmail ? "is-invalid" : " "
                      }  `}
                      name="managerEmail"
                      value={formData.managerEmail}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.managerEmail && (
                      <div className="invalid-feedback">{t("required")}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">{t("countryCode")}</label>
                    <Select
                      options={countryCodeOptions}
                      name="countryCode"
                      value={
                        formData.countryCode
                          ? countryCodeOptions.find(
                              (option) => option.value === formData.countryCode
                            )
                          : null
                      }
                      onChange={handleDropDownInputChange}
                      placeholder={t("select")}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">{t("phone")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                    />
                  </div>
                </div>
                <div className="col-md-12 form-label">
                  {t("assignCompaniesToManage")}
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <MultiSelect
                      selectOptions={companyOptions}
                      setValues={setAssignedCompanies}
                      selectedValues={assignedCompanies}
                      // disabled={disabled}
                    />
                  </div>
                </div>
                <div
                  className="col-md-12 mb-4 mt-4"
                  style={{ fontSize: "14px" }}
                >
                  <b>{t("pleaseNote")} : </b> {t("selectParentCompanyInfo")}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editManagerModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="editManagerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                {t("editManager")}
              </h5>
              <button
                type="button"
                id="closeEditManagerModal"
                className="close"
                onClick={handleEditClose}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      {t("managerFirstName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.managerFirstName ? "is-invalid" : " "
                      }  `}
                      name="managerFirstName"
                      value={editManager?.managerFirstName}
                      onChange={handleEditInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.managerFirstName && (
                      <div className="invalid-feedback">{t("required")}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      {t("managerLastName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.managerLastName ? "is-invalid" : " "
                      }  `}
                      name="managerLastName"
                      value={editManager?.managerLastName}
                      onChange={handleEditInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.managerLastName && (
                      <div className="invalid-feedback">{t("required")}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Manager Email
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>

                    {/* <input type="text" className="form-control" placeholder="managers Email" /> */}
                    <input
                      type="managerEmail"
                      className="form-control"
                      name="managerEmail"
                      value={editManager?.managerEmail}
                      placeholder={t("enterHere")}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">{t("countryCode")}</label>
                    <Select
                      options={countryCodeOptions}
                      name="countryCode"
                      value={
                        editManager?.countryCode
                          ? countryCodeOptions.find(
                              (option) =>
                                option.value === editManager?.countryCode
                            )
                          : null
                      }
                      onChange={handleEditDropDownInputChange}
                      placeholder={t("select")}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">{t("phone")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={editManager?.phone}
                      onChange={handleEditInputChange}
                      placeholder={t("enterHere")}
                    />
                  </div>
                </div>
                <div className="col-md-12 form-label">
                  Assign Companies to Manage
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <MultiSelect
                      selectOptions={companyOptions}
                      setValues={setAssignedCompanies}
                      selectedValues={assignedCompanies}
                      // disabled={disabled}
                    />
                  </div>
                </div>
                <div
                  className="col-md-12 mb-4 mt-4"
                  style={{ fontSize: "14px" }}
                >
                  <b>Note : </b> If you select a parent company then all the
                  daughter companies will be automatically assigned.
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEditClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleEditSubmit}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel">
                {t("deleteConfirmation")}
              </h5>
              <button
                type="button"
                id="closeConfirmModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this manager ? </p>
              <p>
                Once deleted the manager account will be deleted and it will be
                removed from the assigned companies. Employees will not be
                deleted
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="closeDeleteConfirmBtn"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("cancel")}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
              >
                {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="companiesManagedModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="companiesManagedModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companiesManagedModalLabel">
                {managerName} {t("manages")} :
              </h5>
              <button
                type="button"
                id="closeCompaniesManagedModalModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <ul>
                {companiesManaged &&
                  companiesManaged.map((item, index) => (
                    <li key={index}>
                      {index + 1}. {item}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Managers);
