import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  editCompanyProfile,
  getMyCompanyProfile,
} from "../../../services/apiService";
import { useTranslation } from "react-i18next";
import Loading from "../../Utility/Loading";
import { useSelector } from "react-redux";

const CompanyProfile = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const initialCompany = {
    name: "",
    referenceNum: "",
    registrationId: "",
    countryCode: "",
    phone: "",
    email: "",
    address: {},
  };
  const initialAddress = {
    street1: "",
    street2: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [company, setCompany] = useState(initialCompany);
  const [address, setAddress] = useState(initialAddress);
  const { t } = useTranslation("common");

  const toggleEditMode = async () => {
    if (isEditMode) {
      setIsLoading(true);
      editCompany();
      fetchCompanyProfile();
    }
    setIsEditMode(!isEditMode);
  };

  const renderEditableField = (label, value, onChange) => (
    <div className="px-4 py-2 border-bottom border-gray-50">
      <div className="row">
        <div className="col-sm-3">
          <dt className="mt-1 form-label" style={{ fontSize: "15px" }}>
            {label}
          </dt>
        </div>
        <div className="col-sm-9">
          <dd className="mt-1 text-gray-700" style={{ fontSize: "15px" }}>
            <input
              type="text"
              value={value}
              onChange={onChange}
              className="border rounded px-2 py-1 w-full"
            />
          </dd>
        </div>
      </div>
    </div>
  );

  const renderDisplayField = (label, value) => (
    <div className="px-4 py-2 border-bottom border-gray-50">
      <div className="row">
        <div className="col-sm-3">
          <dt className="mt-1 form-label" style={{ fontSize: "15px" }}>
            {label}
          </dt>
        </div>
        <div className="col-sm-9">
          <dd className="mt-1 text-gray-700" style={{ fontSize: "15px" }}>
            {value}
          </dd>
        </div>
      </div>
    </div>
  );

  function handleCompanyChange(e, label) {
    const copy = { ...company };
    copy[label] = e.target.value;
    setCompany(copy);
  }

  function handleAddressChange(e, label) {
    const copy = { ...address };
    copy[label] = e.target.value;
    setAddress(copy);
  }

  const resetForm = () => {
    setCompany(initialCompany);
    setAddress(initialAddress);
  };

  useEffect(() => {
    fetchCompanyProfile();
  }, []);

  const fetchCompanyProfile = async () => {
    try {
      let idForCompany = parseInt(localStorage.companyId, 10);
      const response = await getMyCompanyProfile(
        localStorage.token,
        idForCompany
      );
      setCompany(response);
      if (response.address === undefined || response.address == null) {
        setAddress(initialAddress);
      } else {
        setAddress(response.address);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCompany = async () => {
    const formaData = {
      name: company.name,
      referenceNum: company.referenceNum,
      email: company.email,
      registrationId: company.registrationId,
      countryCode: company.countryCode,
      phone: company.phone,
      address: address,
    };
    const response = await editCompanyProfile(localStorage.token, formaData);
    if (!response) {
      toast.error("Error occured on Server side", {
        autoClose: 500,
      });
    }
    fetchCompanyProfile();
    setIsLoading(false);
  };

  return (
    <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="relative">
                  <img
                    className="mr-3 mb-4"
                    src={"../assets/images/company.jpg"}
                    alt="company logo"
                    style={{ width: "300px", height: "190px" }}
                  />
                </div>
                <div className="media mb-4">
                  <div className="media-body">
                    <h5 className="m-0">{company.name}</h5>
                    <p className="text-muted mb-0">{company.email}</p>
                  </div>
                </div>
                <p className="mb-4">
                  <label className="form-label">{company.name}</label>
                  <label className="form-label">
                    {company?.address?.street},{company?.address?.houseNumber}
                  </label>
                  <label className="form-label">
                    {company?.address?.postcode} {company?.address?.location}
                  </label>
                  <label className="form-label">Email: {company.email}</label>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="card">
              <div className="card-body">
                {isEditMode
                  ? renderEditableField("Company Name", company.name, (e) =>
                      handleCompanyChange(e, "name")
                    )
                  : renderDisplayField("Company Name", company.name)}
                {renderDisplayField(t("referenceNum"), company.referenceNum)}
                {/* {renderDisplayField(t('phone, company.countryCode+' - '+company.phone)} */}
                {renderDisplayField("Manager", company?.manager?.name)}
                {isEditMode ? (
                  <>
                    {renderEditableField(
                      t("countryCode"),
                      company?.manager?.countryCode,
                      (e) => handleCompanyChange(e, "countryCode")
                    )}
                    {renderEditableField(
                      t("phone"),
                      company?.manager?.phone,
                      (e) => handleCompanyChange(e, "phone")
                    )}
                  </>
                ) : (
                  renderDisplayField(
                    t("phone"),
                    company?.manager?.countryCode +
                      " - " +
                      company?.manager?.phone
                  )
                )}

                {isEditMode
                  ? renderEditableField(
                      t("email"),
                      company?.manager?.managerEmail,
                      (e) => handleCompanyChange(e, "email")
                    )
                  : renderDisplayField(
                      t("email"),
                      company?.manager?.managerEmail
                    )}

                {renderDisplayField(t("street"), company?.address?.street)}
                {renderDisplayField(
                  t("houseNumber"),
                  company?.address?.houseNumber
                )}
                {renderDisplayField(t("postCode"), company?.address?.postcode)}
                {renderDisplayField(t("location"), company?.address?.location)}
                {/* {isEditMode ? (
                  <>
                    {renderEditableField(
                      t("addressStreet1"),
                      address?.street1,
                      (e) => handleAddressChange(e, "street1")
                    )}
                    {renderEditableField(
                      t("addressStreet2"),
                      address?.street2,
                      (e) => handleAddressChange(e, "street2")
                    )}
                    {renderEditableField(t("addressCity"), address?.city, (e) =>
                      handleAddressChange(e, "city")
                    )}
                    {renderEditableField(
                      t("addressCountry"),
                      address?.country,
                      (e) => handleAddressChange(e, "country")
                    )}
                    {renderEditableField(
                      t("addressPostCode"),
                      address?.postcode,
                      (e) => handleAddressChange(e, "postcode")
                    )}
                  </>
                ) : (
                  renderDisplayField(t("address"), company.addressString)
                )} */}
                {/* {localStorage.role !== "ROLE_EMPLOYEE" && (
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-base font-semibold leading-6 text-gray-900">
                      {t("contractTemplate")}
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <Button variant="contained" color="primary">
                        {t("uploadContractTemplateBtn")}
                      </Button>
                    </dd>
                  </div>
                )}
                {localStorage.role !== "ROLE_EMPLOYEE" && (
                  <div className="card-footer text-right">
                    <button
                      className="btn btn-primary"
                      onClick={toggleEditMode}
                    >
                      {isEditMode ? t("save") : t("edit")}
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  );
};

export default CompanyProfile;
