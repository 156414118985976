import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enCommon from './locales/en/common.json';
import enLogin from './locales/en/login.json';
import enDepartment from './locales/en/department.json';
import enMenu from './locales/en/menu.json';
import deCommon from './locales/de/common.json';
import deLogin from './locales/de/login.json';
import deDepartment from './locales/de/department.json';
import deMenu from './locales/de/menu.json';
import enEmployee from './locales/en/employee.json';
import deEmployee from './locales/de/employee.json';
import enPage from './locales/en/page.json';
import dePage from './locales/de/page.json';

const resources = {
  en: {
    common: enCommon,
    login: enLogin,
    department: enDepartment,
    menu:enMenu,
    employee:enEmployee,
    page:enPage
  },
  de: {
    common: deCommon,
    login: deLogin,
    department: deDepartment,
    menu:deMenu,
    employee:deEmployee,
    page:dePage
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'de', // Set the default language
    fallbackLng: 'de', // Fallback language in case of missing translations
    interpolation: {
      escapeValue: false, // React escapes string content by default, so no need for HTML escape
    },
  });

export default i18n;
