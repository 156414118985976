import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import {
  createCompany,
  deleteCompanyById,
  editCompanyApi,
  getCompanyList,
  getManagerDropDown,
  updateCompanyManagers,
} from "../../../services/companyService";
import { Dialog, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, Trash2 } from "lucide-react";
import Loading from "../../Utility/Loading";
import MultiSelect from "../../Utility/MultiSelect";

function Companies(props) {
  const { fixNavbar } = props;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [companyData, setCompanyData] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [managerOptions, setManagerOptions] = useState([]);
  const [assignedManagers, setAssignedManagers] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [editCompany, setEditCompany] = useState("");

  const { t } = useTranslation("common");

  const initialErrorState = {
    name: "",
    email: "",
    referenceNum: "",
    street: "",
    houseNumber: "",
    postcode: "",
    location: "",
    companyCommissionPer: "",
    agentCommissionPer: "",
    providerId: "",
    holdingCompany: "",
  };
  const initialValidState = {
    name: false,
    email: false,
    referenceNum: false,
    street: false,
    houseNumber: false,
    postcode: false,
    location: false,
    companyCommissionPer: false,
    agentCommissionPer: false,
    holdingCompany: false,
  };
  const initalFormData = {
    name: "",
    email: "",
    referenceNum: "",
    registrationId: "",
    street: "",
    houseNumber: "",
    postcode: "",
    location: "",
    companyCommissionPer: "",
    agentCommissionPer: "",
    holdingCompany: "no",
  };
  const initialRow = {
    name: "",
    email: "",
    referenceNum: "",
    registrationId: "",
    street: "",
    houseNumber: "",
    postcode: "",
    location: "",
    holdingCompany: false,
    addressSameAsParentCompany: true,
  };
  const [rows, setRows] = useState([initialRow]);

  const [errors, setErrors] = useState(initialErrorState);
  const [valid, setValid] = useState(initialValidState);
  const [formData, setFormData] = useState(initalFormData);
  // const [noSubCompanyAdded, setNoSubCompanyAdded] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddRow = () => {
    setRows([...rows, { ...initialRow }]);
    // if (rows.length === 0) {
    //   setNoSubCompanyAdded(false);
    // }
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    // if (updatedRows.length === 0) {
    //   setNoSubCompanyAdded(true);
    // }
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setRows(updatedRows);
  };

  useEffect(() => {
    fetchCompanyData();
    fetchManagerDropDown();
  }, []);

  useEffect(() => {}, [
    companyData,
    companyOptions,
    cityOptions,
    managerOptions,
  ]);

  const fetchCompanyData = async () => {
    try {
      const response = await getCompanyList(localStorage.token);
      setCompanyData(response);
      setFilteredData(response);
      handleOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchManagerDropDown = async () => {
    try {
      const response = await getManagerDropDown(localStorage.token);
      setManagerOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptions = (response) => {
    const uniqueCitySet = new Set();
    const cityOptions = response
      .filter((d) => d.location)
      .filter((d) => {
        const city = d.location;
        if (!uniqueCitySet.has(city)) {
          uniqueCitySet.add(city);
          return true;
        }
        return false;
      })
      .map((d) => ({
        label: d.location,
        value: d.location,
      }));
    setCityOptions(cityOptions);

    const uniqueCompanySet = new Set();
    const companyOptions = response
      .filter((d) => d.name)
      .filter((d) => {
        const company = d.name;
        if (!uniqueCompanySet.has(company)) {
          uniqueCompanySet.add(company);
          return true;
        }
        return false;
      })
      .map((d) => ({
        label: d.name,
        value: d.name,
      }));

    setCompanyOptions(companyOptions);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = (formData, edit) => {
    const newErrors = {};
    if (!formData.name || formData.name.trim() === "") {
      newErrors.name = t("companyNameIsRequired");
    }
    if (!formData.street || formData.street.trim() === "") {
      newErrors.street = t("streetRequired");
    }
    if (!formData.houseNumber || formData.houseNumber.trim() === "") {
      newErrors.houseNumber = t("required");
    }
    if (!formData.location || formData.location.trim() === "") {
      newErrors.location = t("cityRequired");
    }
    if (!formData.postcode || formData.postcode.trim() === "") {
      newErrors.postcode = t("postCodeRequired");
    }
    if (!formData.referenceNum || formData.referenceNum.trim() === "") {
      newErrors.referenceNum = t("referenceNumIsRequired");
    }
    if (rows.length > 0 && !edit && formData.holdingCompany === "yes") {
      rows.forEach((row, rowIndex) => {
        if (!row.name || row.name.trim() === "") {
          newErrors.name = t("required");
        }
        if (!row.referenceNum || row.referenceNum.trim() === "") {
          newErrors.referenceNum = t("required");
        }
        if (!row.addressSameAsParentCompany) {
          if (!row.street || row.street.trim() === "") {
            newErrors.street = t("required");
          }
          if (!row.houseNumber || row.houseNumber.trim() === "") {
            newErrors.houseNumber = t("required");
          }
          if (!row.location || row.location.trim() === "") {
            newErrors.location = t("required");
          }
          if (!row.postcode || row.postcode.trim() === "") {
            newErrors.postcode = t("required");
          }
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    let holdingCompany = formData.holdingCompany === "yes" ? true : false;
    // if (holdingCompany && noSubCompanyAdded) {
    //   toast.error("Add atleast one daughter company");
    // }
    const isFormValid = validateForm(formData, false);
    if (isFormValid) {
      const valid = {};
      valid.name = true;
      valid.email = true;
      valid.street = true;
      valid.houseNumber = true;
      valid.location = true;
      valid.postcode = true;
      valid.referenceNum = true;
      setValid(valid);

      const request = {
        name: formData.name,
        email: formData.email,
        subCompanies: holdingCompany ? rows : null,
        street: formData.street,
        postcode: formData.postcode,
        location: formData.location,
        houseNumber: formData.houseNumber,
        referenceNum: formData.referenceNum,
        registrationId: formData.registrationId,
        companyCommissionPer: formData.companyCommissionPer,
        agentCommissionPer: formData.agentCommissionPer,
        holdingCompany: formData.holdingCompany === "yes" ? true : false,
      };
      addCompany(request);
    }
  };

  const addCompany = async (companyData) => {
    try {
      const response = await createCompany(localStorage.token, companyData);
      if (!response) {
        setValid(initialValidState);
        toast.info(t("companyNameExists"), {
          autoClose: 2000,
        });
      } else {
        toast.success(t("companyCreatedSuccessfully"), {
          autoClose: 500,
        });
        handleClose();
        document.getElementById("closeAddModal").click();
        fetchCompanyData();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error saving company:", error);
    }
  };

  const handleClose = () => {
    setFormData(initalFormData);
    setErrors(initialErrorState);
    setValid(initialValidState);
    setRows([initialRow]);
  };

  const handleAssignManagerClose = () => {
    setAssignedManagers([]);
    setCompanyName("");
  };

  const handleAssignManagerSubmit = async () => {
    try {
      const response = await updateCompanyManagers(
        localStorage.token,
        companyId,
        assignedManagers
      );
      if (!response) {
        toast.error(t("somethingWentWrong"), {
          autoClose: 1000,
        });
      } else {
        toast.success(t("managersUpdatedSuccess"), {
          autoClose: 1000,
        });
        handleAssignManagerClose();
        document.getElementById("closeAssignManagerModal").click();
        fetchCompanyData();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error updating company managers:", error);
    }
  };

  const resetFilters = () => {
    setSelectedLocation(null);
    setSearchName("");
    setSelectedCompany(null);
    setFilteredData(companyData);
  };

  const applyFilters = () => {
    const filteredData = companyData
      .filter(
        (company) =>
          !searchName || company.name.toLowerCase().includes(searchName)
      )
      .filter(
        (company) =>
          !selectedLocation || selectedLocation.value === company.location
      )
      .filter(
        (company) => !selectedCompany || selectedCompany.value === company.name
      );
    setFilteredData(filteredData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const getDisplayPages = () => {
    const pages = [];
    const pageWindow = 3; // Number of pages to display

    if (totalPages <= pageWindow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        for (let i = 1; i <= pageWindow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages) {
        for (let i = totalPages - pageWindow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const start = currentPage - 1;
        const end = currentPage + 1;

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  };

  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handleDeleteClick = (id, name) => {
    setCompanyId(id);
  };

  const handleDeleteConfirm = () => {
    setIsLoading(true);
    deleteCompany();
    setIsLoading(false);
  };
  const deleteCompany = async () => {
    try {
      const response = await deleteCompanyById(localStorage.token, companyId);
      if (response) {
        document.getElementById("closeConfirmModal").click();
        fetchCompanyData();
        toast.success(t("companyDeletedSuccessfully"));
      } else {
        toast.error(t("somethingWentWrong"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  function getCommaSeparatedManagers(managerNames) {
    if (!Array.isArray(managerNames) || managerNames.length === 0) {
      return "-";
    }
    return managerNames.join(", ");
  }

  const handleEditCompanyClose = () => {
    setEditCompany(null);
    setErrors(initialErrorState);
    setValid(initialValidState);
  };

  const handleEditCompanySubmit = async () => {
    const isFormValid = validateForm(editCompany, true);
    if (isFormValid) {
      const valid = {};
      valid.name = true;
      valid.email = true;
      valid.street = true;
      valid.houseNumber = true;
      valid.location = true;
      valid.postcode = true;
      valid.referenceNum = true;
      setValid(valid);
      editCompanyDetails();
    }
  };

  const editCompanyDetails = async () => {
    try {
      const response = await editCompanyApi(localStorage.token, editCompany);
      if (!response) {
        setValid(initialValidState);
        toast.error(t("companyUpdateFailed"), {
          autoClose: 2000,
        });
      } else {
        toast.success(t("companyUpdatedSuccess"), {
          autoClose: 500,
        });
        handleEditCompanyClose();
        document.getElementById("closeEditModal").click();
        fetchCompanyData();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error saving company:", error);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditCompany({ ...editCompany, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  return (
    <div>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="Departments-tab"
                  data-toggle="tab"
                  href="#Departments-list"
                >
                  Liste
                </a>
              </li>
              {/* <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li>
               */}
            </ul>
            {localStorage.getItem("role") === "ROLE_SUPER_ADMIN" && (
              <div className="header-action">
                <button
                  type="button"
                  className="btn btn-primary mt-2 btn-block"
                  data-toggle="modal"
                  data-target="#addModal"
                >
                  <i className="fe fe-plus mr-2" />
                  {t("addNewCompany")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="Departments-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2 col-sm-6">
                      <label>{t("company")}</label>
                      <div className="multiselect_div">
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={companyOptions}
                          value={selectedCompany}
                          onChange={(selectedOption) =>
                            setSelectedCompany(selectedOption)
                          }
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>{t("location")}</label>
                      <div className="form-group">
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={cityOptions}
                          value={selectedLocation}
                          onChange={(selectedOption) =>
                            setSelectedLocation(selectedOption)
                          }
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label>Name suchen</label>
                      <div className="input-group">
                        <input
                          type="text"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                          className="form-control"
                          placeholder={t("enterHere")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>&nbsp;</label>
                      <button
                        onClick={() => {
                          applyFilters();
                        }}
                        className="btn btn-sm btn-primary btn-block p-2"
                      >
                        {t("filter")}
                      </button>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>&nbsp;</label>
                      <button
                        onClick={() => {
                          resetFilters();
                        }}
                        className="btn btn-sm btn-primary btn-block p-2"
                      >
                        {t("reset")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title" style={{ fontWeight: 500 }}>
                    {t("companyList")}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive mb-4">
                    <table className="table  table-vcenter table-hover mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ fontWeight: 600 }} className="w40">
                            #
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("companyName")}
                          </th>
                          {/* <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            Type
                          </th> */}
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            Mutterunternehmen
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("referenceNum")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("registrationId")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            Status
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              fontWeight: 600,
                            }}
                          >
                            Managers
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("employeeCount")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("location")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-hover">
                        {currentItems.map((company, index) => (
                          <tr key={company.id}>
                            <td className="w40">{company.index}</td>
                            <td>
                              <div>
                                <NavLink
                                  to={"/company/info?id=" + company.companyId}
                                >
                                  <h6
                                    className="mb-0"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {company.name}
                                  </h6>
                                </NavLink>

                                <span className="text-muted">
                                  {company.email}
                                </span>
                              </div>
                            </td>
                            {/* <td>
                              {company.holdingCompany
                                ? "Mutterunternehmen"
                                : "Tochterunternehmen"}
                            </td> */}
                            <td>
                              {company.parentCompanyName
                                ? company.parentCompanyName
                                : "NA"}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.referenceNum}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.registrationId}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.onboardingStatus === "STARTED" && (
                                <span
                                  className="tag tag-success"
                                  style={{ fontWeight: 600 }}
                                >
                                  {company.onboardingStatus}
                                </span>
                              )}
                              {company.onboardingStatus === "INITIATED" && (
                                <span
                                  className="tag tag-orange"
                                  style={{ fontWeight: 600 }}
                                >
                                  {company.onboardingStatus}
                                </span>
                              )}
                            </td>

                            <td style={{ whiteSpace: "nowrap" }}>
                              <ul>
                                {company?.managerNames?.map(
                                  (manager, index) => (
                                    <li key={index}>{manager}</li>
                                  )
                                )}
                              </ul>
                            </td>

                            <td
                              style={{
                                whiteSpace: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  justifyContent: "center",
                                  backgroundColor: "#90CDF4",
                                  color: "#374151",
                                  padding: "0.5rem 1rem",
                                  borderRadius: "999px",
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                }}
                              >
                                {company.employeeCount}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.location}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <NavLink
                                className="btn btn-icon btn-sm"
                                title="View"
                                to={"/company/info?id=" + company.companyId}
                                target="_blank"
                              >
                                <i className="fa fa-eye" />
                              </NavLink>
                              <button
                                type="button"
                                className="btn btn-icon btn-sm mb-2"
                                title="Edit Company"
                                data-toggle="modal"
                                data-target="#editModal"
                                onClick={(e) => {
                                  setCompanyId(company.companyId);
                                  setCompanyName(company.name);
                                  setEditCompany(company);
                                }}
                              >
                                <i className="fa fa-pencil" />
                              </button>
                              {localStorage.getItem("role") ===
                                "ROLE_SUPER_ADMIN" && (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-sm mb-2"
                                    title="Assign/Remove Managers"
                                    data-toggle="modal"
                                    data-target="#assignManagerModal"
                                    onClick={(e) => {
                                      setCompanyId(company.companyId);
                                      setAssignedManagers(
                                        company.assignedManagers
                                      );
                                      setCompanyName(company.name);
                                    }}
                                  >
                                    <i className="fa fa-users" />
                                  </button>
                                  <button
                                    type="button"
                                    title="Delete Company"
                                    onClick={() =>
                                      handleDeleteClick(
                                        company.companyId,
                                        company.name
                                      )
                                    }
                                    className="btn btn-icon mb-1"
                                    data-toggle="modal"
                                    data-target="#deleteConfirmationModal"
                                  >
                                    <i className="fa fa-trash-o text-red-400" />
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <nav>
                      <ul className="pagination">
                        {currentPage > 1 && (
                          <li
                            className="page-item"
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(currentPage - 1)}
                              className="page-link"
                              style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600,
                              }}
                            >
                              <ChevronLeft size={17} />
                            </button>
                          </li>
                        )}

                        {getDisplayPages().map((page) => (
                          <li
                            key={page}
                            className={`page-item ${
                              currentPage === page ? "active" : ""
                            }`}
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(page)}
                              className="page-link"
                              style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600,
                                backgroundColor:
                                  currentPage === page ? "gray" : " ",
                              }}
                            >
                              {page}
                            </button>
                          </li>
                        ))}

                        {currentPage < totalPages && (
                          <li
                            className="page-item"
                            style={{ padding: "5px 2px" }}
                          >
                            <button
                              onClick={() => handleClick(currentPage + 1)}
                              className="page-link"
                              style={{
                                fontSize: "14px",
                                padding: "2px 10px",
                                fontWeight: 600,
                              }}
                            >
                              <ChevronRight size={17} />
                            </button>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                {t("addNewCompany")}
              </h5>
              <button
                type="button"
                id="closeAddModal"
                className="close"
                onClick={handleClose}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="row clearfix">
                <div
                  className="col-12 mb-4"
                  style={{ fontWeight: 650, color: "#b7b7b7" }}
                >
                  <span>{t("companyDetails")}</span>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("companyName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.name ? "is-valid" : " "
                      }  ${errors.name ? "is-invalid" : " "}  `}
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("companyEmail")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("referenceNum")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.referenceNum ? "is-valid" : " "
                      }  ${errors.referenceNum ? "is-invalid" : " "}  `}
                      name="referenceNum"
                      value={formData.referenceNum}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.referenceNum && (
                      <div className="invalid-feedback">
                        {errors.referenceNum}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("registrationId")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="registrationId"
                      value={formData.registrationId}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("street")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.street ? "is-valid" : " "
                      }  ${errors.street ? "is-invalid" : " "}  `}
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                      placeholder={t("enterStreet")}
                      required
                    />
                    {errors.street && (
                      <div className="invalid-feedback">{errors.street}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("houseNumber")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.houseNumber ? "is-valid" : " "
                      }  ${errors.houseNumber ? "is-invalid" : " "}  `}
                      name="houseNumber"
                      value={formData.houseNumber}
                      onChange={handleInputChange}
                      placeholder="Enter House number"
                      required
                    />
                    {errors.houseNumber && (
                      <div className="invalid-feedback">
                        {errors.houseNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("location")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.location ? "is-valid" : " "
                      }  ${errors.location ? "is-invalid" : " "}  `}
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.location && (
                      <div className="invalid-feedback">{errors.location}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("postCode")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.postcode ? "is-valid" : " "
                      }  ${errors.postcode ? "is-invalid" : " "}  `}
                      name="postcode"
                      value={formData.postcode}
                      onChange={handleInputChange}
                      placeholder={t("enterPostCode")}
                      required
                    />
                    {errors.postcode && (
                      <div className="invalid-feedback">{errors.postcode}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Ist dies eine{" "}
                      <b
                        style={{ color: "#5A5279", textTransform: "uppercase" }}
                      >
                        Holdinggesellschaft
                      </b>
                      ?
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "2px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <div className="mt-2">
                      <div className="form-check form-check-inline">
                        <input
                          className={`form-check-input ${
                            errors.holdingCompany ? "is-invalid" : ""
                          }`}
                          type="radio"
                          name="holdingCompany"
                          value="yes"
                          id="holdingCompanyYes"
                          checked={formData.holdingCompany === "yes"}
                          onChange={handleInputChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="holdingCompanyYes"
                        >
                          Ja
                        </label>
                      </div>
                      <div className="form-check form-check-inline ml-8">
                        <input
                          className={`form-check-input ${
                            errors.holdingCompany ? "is-invalid" : ""
                          }`}
                          type="radio"
                          name="holdingCompany"
                          value="no"
                          id="holdingCompanyNo"
                          checked={formData.holdingCompany === "no"}
                          onChange={handleInputChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="holdingCompanyNo"
                        >
                          Nein
                        </label>
                      </div>
                    </div>
                    {errors.holdingCompany && (
                      <div className="invalid-feedback">
                        {errors.holdingCompany}
                      </div>
                    )}
                  </div>
                </div>
                {formData.holdingCompany == "yes" && (
                  <>
                    <div
                      className="col-12 mb-2 mt-2"
                      style={{ fontWeight: 650, color: "#b7b7b7" }}
                    >
                      <span>Tochterunternehmen Details</span>
                    </div>
                    {/* {noSubCompanyAdded && (
                      <div className="card-alert alert alert-danger mb-0">
                        Add atleast one daughter company.
                      </div>
                    )} */}
                    <div className="col-12">
                      {rows.map((row, index) => (
                        <>
                          <hr style={{ margin: "4px 0 4px 0" }} />
                          <div className="row mt-4">
                            <div className="col-md-3 col-sm-6">
                              <div className="form-group">
                                <label className="form-label">
                                  {t("companyName")}
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginLeft: "1px",
                                      marginRight: "4px",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={row.name}
                                  onChange={(e) =>
                                    handleRowChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  placeholder={t("enterHere")}
                                  required
                                />
                                {!row.name && (
                                  <div style={invalidFeedbackStyle}>
                                    {t("required")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                              <div className="form-group">
                                <label className="form-label">
                                  {t("companyEmail")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  value={row.email}
                                  onChange={(e) =>
                                    handleRowChange(
                                      index,
                                      "email",
                                      e.target.value
                                    )
                                  }
                                  placeholder={t("enterHere")}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                              <div className="form-group">
                                <label className="form-label">
                                  {t("referenceNum")}
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginLeft: "1px",
                                      marginRight: "4px",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="referenceNum"
                                  value={row.referenceNum}
                                  onChange={(e) =>
                                    handleRowChange(
                                      index,
                                      "referenceNum",
                                      e.target.value
                                    )
                                  }
                                  placeholder={t("enterHere")}
                                  required
                                />
                                {!row.referenceNum && (
                                  <div style={invalidFeedbackStyle}>
                                    {t("required")}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                              <div className="form-group">
                                <label className="form-label">
                                  {t("registrationId")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="registrationId"
                                  value={row.registrationId}
                                  onChange={(e) =>
                                    handleRowChange(
                                      index,
                                      "registrationId",
                                      e.target.value
                                    )
                                  }
                                  placeholder={t("enterHere")}
                                />
                              </div>
                            </div>
                            {!row.addressSameAsParentCompany && (
                              <>
                                <div className="col-md-3 col-sm-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {t("street")}
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                          marginLeft: "1px",
                                          marginRight: "4px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="street"
                                      value={row.street}
                                      onChange={(e) =>
                                        handleRowChange(
                                          index,
                                          "street",
                                          e.target.value
                                        )
                                      }
                                      placeholder={t("enterStreet")}
                                      required
                                    />
                                    {!row.street && (
                                      <div style={invalidFeedbackStyle}>
                                        {t("required")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {t("houseNumber")}
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                          marginLeft: "1px",
                                          marginRight: "4px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="houseNumber"
                                      value={row.houseNumber}
                                      onChange={(e) =>
                                        handleRowChange(
                                          index,
                                          "houseNumber",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter House number"
                                      required
                                    />
                                    {!row.houseNumber && (
                                      <div style={invalidFeedbackStyle}>
                                        {t("required")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {t("location")}
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                          marginLeft: "1px",
                                          marginRight: "4px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="location"
                                      value={row.location}
                                      onChange={(e) =>
                                        handleRowChange(
                                          index,
                                          "location",
                                          e.target.value
                                        )
                                      }
                                      placeholder={t("enterHere")}
                                      required
                                    />
                                    {!row.location && (
                                      <div style={invalidFeedbackStyle}>
                                        {t("required")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {t("postCode")}
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                          marginLeft: "1px",
                                          marginRight: "4px",
                                        }}
                                      >
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="postcode"
                                      value={row.postcode}
                                      onChange={(e) =>
                                        handleRowChange(
                                          index,
                                          "postcode",
                                          e.target.value
                                        )
                                      }
                                      placeholder={t("enterPostCode")}
                                      required
                                    />
                                    {!row.postcode && (
                                      <div style={invalidFeedbackStyle}>
                                        {t("required")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="col-md-4 col-sm-6 mt-2">
                              <label className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={row.addressSameAsParentCompany}
                                  onChange={(e) =>
                                    handleRowChange(
                                      index,
                                      "addressSameAsParentCompany",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="custom-control-label">
                                  {t("addressSameAsParent")}
                                </span>
                              </label>
                            </div>
                            <div className="col-lg-1 col-md-12">
                              <IconButton
                                onClick={() => handleRemoveRow(index)}
                              >
                                <Trash2 className="h-6 w-6 text-red-400" />
                              </IconButton>
                            </div>
                          </div>
                        </>
                      ))}
                      <div className="flex gap-5 mt-4 mb-2 justify-start">
                        <button className="btn btn-info" onClick={handleAddRow}>
                          {t("addRow")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="assignManagerModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="assignManagerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="assignManagerModalLabel">
                {t("assignRemoveManagers")}
              </h5>
              <button
                type="button"
                id="closeAssignManagerModal"
                className="close"
                onClick={handleAssignManagerClose}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="row clearfix">
                <div className="col-md-12 mb-4">
                  <b style={{ fontWeight: 600 }}> {t("companyName")} : </b>{" "}
                  {companyName}
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <MultiSelect
                      selectOptions={managerOptions}
                      setValues={setAssignedManagers}
                      selectedValues={assignedManagers}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleAssignManagerClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAssignManagerSubmit}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel">
                {t("deleteConfirmation")}
              </h5>
              <button
                type="button"
                id="closeConfirmModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">{t("confirmDeleteMessage")}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("cancel")}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
              >
                {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editModalLabel">
                {t("editingCompany")} - {companyName}
              </h5>
              <button
                type="button"
                id="closeEditModal"
                className="close"
                onClick={handleEditCompanyClose}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="row clearfix">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("companyName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={editCompany?.name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("companyEmail")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={editCompany?.email}
                      onChange={handleEditInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("referenceNum")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.referenceNum ? "is-valid" : " "
                      }  ${errors.referenceNum ? "is-invalid" : " "}  `}
                      name="referenceNum"
                      value={editCompany?.referenceNum}
                      onChange={handleEditInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.referenceNum && (
                      <div className="invalid-feedback">
                        {errors.referenceNum}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("registrationId")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="registrationId"
                      value={editCompany?.registrationId}
                      onChange={handleEditInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("street")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.street ? "is-valid" : " "
                      }  ${errors.street ? "is-invalid" : " "}  `}
                      name="street"
                      value={editCompany?.street}
                      onChange={handleEditInputChange}
                      placeholder={t("enterStreet")}
                      required
                    />
                    {errors.street && (
                      <div className="invalid-feedback">{errors.street}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("houseNumber")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.houseNumber ? "is-valid" : " "
                      }  ${errors.houseNumber ? "is-invalid" : " "}  `}
                      name="houseNumber"
                      value={editCompany?.houseNumber}
                      onChange={handleEditInputChange}
                      placeholder="Enter House number"
                      required
                    />
                    {errors.houseNumber && (
                      <div className="invalid-feedback">
                        {errors.houseNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("location")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.location ? "is-valid" : " "
                      }  ${errors.location ? "is-invalid" : " "}  `}
                      name="location"
                      value={editCompany?.location}
                      onChange={handleEditInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.location && (
                      <div className="invalid-feedback">{errors.location}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("postCode")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.postcode ? "is-valid" : " "
                      }  ${errors.postcode ? "is-invalid" : " "}  `}
                      name="postcode"
                      value={editCompany?.postcode}
                      onChange={handleEditInputChange}
                      placeholder={t("enterPostCode")}
                      required
                    />
                    {errors.postcode && (
                      <div className="invalid-feedback">{errors.postcode}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEditCompanyClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleEditCompanySubmit}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
