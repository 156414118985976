import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { toast } from "react-toastify";
import {
  createSubCompany,
  getCompanyDetailsById,
  getManagerDropDown,
  updateCompanyManagers,
} from "../../../services/companyService";
import MultiSelect from "../../Utility/MultiSelect";

const CompanyDetails = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const [companyId, setCompanyId] = useState(id);
  const [managerOptions, setManagerOptions] = useState([]);
  const [assignedManagers, setAssignedManagers] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const [companyData, setCompanyData] = useState({});
  const [totalEmployees, setTotalEmployees] = useState(null);
  const [inactiveEmployees, setInactiveEmployees] = useState(null);
  const [activeEmployees, setActiveEmployees] = useState(null);
  const [maleEmployees, setMaleEmployees] = useState(null);
  const [feMaleEmployees, setFeMaleEmployees] = useState(null);
  const [otherEmployees, setOtherEmployees] = useState(null);
  const { t } = useTranslation("common");
  const initialErrorState = {
    name: "",
    email: "",
    referenceNum: "",
    street: "",
    houseNumber: "",
    postcode: "",
    location: "",
    companyCommissionPer: "",
    agentCommissionPer: "",
    providerId: "",
  };
  const initialValidState = {
    name: false,
    email: false,
    referenceNum: false,
    street: false,
    houseNumber: false,
    postcode: false,
    location: false,
    companyCommissionPer: false,
    agentCommissionPer: false,
  };
  const initalFormData = {
    name: "",
    email: "",
    referenceNum: "",
    registrationId: "",
    street: "",
    houseNumber: "",
    postcode: "",
    location: "",
    companyCommissionPer: "",
    agentCommissionPer: "",
    addressSameAsParentCompany: true,
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [valid, setValid] = useState(initialValidState);
  const [formData, setFormData] = useState(initalFormData);

  useEffect(() => {
    setCompanyId(id);
    getCompanyDetails();
    fetchManagerDropDown();
  }, []);

  useEffect(() => {}, [companyData, managerOptions]);

  const fetchManagerDropDown = async () => {
    try {
      const response = await getManagerDropDown(localStorage.token);
      setManagerOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyDetails = async () => {
    try {
      let response;
      if (id) {
        response = await getCompanyDetailsById(localStorage.token, id);
      }
      setCompanyData(response);
      setCompanyName(response.name);
      setAssignedManagers(response.assignedManagers);
      setTotalEmployees(response.totalEmployees);
      setInactiveEmployees(response.inactiveEmployees);
      setActiveEmployees(response.activeEmployees);
      setMaleEmployees(response.maleEmployees);
      setFeMaleEmployees(response.feMaleEmployees);
      setOtherEmployees(response.otherEmployees);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAssignManagerClose = () => {
    setAssignedManagers([]);
    setCompanyName("");
  };

  const handleAssignManagerSubmit = async () => {
    try {
      const response = await updateCompanyManagers(
        localStorage.token,
        companyId,
        assignedManagers
      );
      if (!response) {
        toast.error(t("somethingWentWrong"), {
          autoClose: 1000,
        });
      } else {
        toast.success(t("managersUpdatedSuccess"), {
          autoClose: 1000,
        });
        handleAssignManagerClose();
        document.getElementById("closeAssignManagerModal").click();
        getCompanyDetails();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error updating company managers:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = (formData) => {
    const newErrors = {};
    if (!formData.name || formData.name.trim() === "") {
      newErrors.name = t("companyNameIsRequired");
    }
    if (!formData.referenceNum || formData.referenceNum.trim() === "") {
      newErrors.referenceNum = t("referenceNumIsRequired");
    }
    if (!formData.addressSameAsParentCompany) {
      if (!formData.street || formData.street.trim() === "") {
        newErrors.street = t("streetRequired");
      }
      if (!formData.houseNumber || formData.houseNumber.trim() === "") {
        newErrors.houseNumber = t("required");
      }
      if (!formData.location || formData.location.trim() === "") {
        newErrors.location = t("cityRequired");
      }
      if (!formData.postcode || formData.postcode.trim() === "") {
        newErrors.postcode = t("postCodeRequired");
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubCompanySubmit = () => {
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      const valid = {};
      valid.name = true;
      valid.email = true;
      valid.street = true;
      valid.houseNumber = true;
      valid.location = true;
      valid.postcode = true;
      valid.referenceNum = true;
      setValid(valid);

      const request = {
        name: formData.name,
        email: formData.email,
        street: formData.street,
        postcode: formData.postcode,
        location: formData.location,
        houseNumber: formData.houseNumber,
        referenceNum: formData.referenceNum,
        registrationId: formData.registrationId,
        companyCommissionPer: formData.companyCommissionPer,
        agentCommissionPer: formData.agentCommissionPer,
        holdingCompany: false,
        addressSameAsParentCompany: formData.addressSameAsParentCompany,
      };
      addSubCompany(request);
    }
  };

  const addSubCompany = async (companyData) => {
    try {
      const response = await createSubCompany(
        localStorage.token,
        companyData,
        companyId
      );
      if (!response) {
        setValid(initialValidState);
        toast.info(t("companyNameExists"), {
          autoClose: 2000,
        });
      } else {
        toast.success(t("companyCreatedSuccessfully"), {
          autoClose: 500,
        });
        handleSubCompanyClose();
        document.getElementById("closeAddModal").click();
        getCompanyDetails();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error saving company:", error);
    }
  };

  const handleSubCompanyClose = () => {
    setFormData(initalFormData);
    setErrors(initialErrorState);
    setValid(initialValidState);
  };

  return (
    <>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
        <div className="container-fluid">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="media">
                  <img
                    className="mr-3"
                    src={"../assets/images/company.jpg"}
                    alt="company logo"
                    style={{ width: "200px", height: "60px" }}
                  />
                  <div className="media-body">
                    <h5 className="m-0">{companyData.name}</h5>
                    <p className="text-muted mb-0">
                      {companyData.email
                        ? companyData.email
                        : "<Email: " + t("notSet") + ">"}
                    </p>
                    <p className="text-muted mb-0">
                      {companyData?.street},{companyData?.location},
                      {companyData?.postcode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body  py-4">
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-12">
              <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="pills-calendar-tab"
                    data-toggle="pill"
                    href="#pills-calendar"
                    role="tab"
                    aria-controls="pills-calendar"
                    aria-selected="false"
                  >
                    {t("overview")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-calendar"
                  role="tabpanel"
                  aria-labelledby="pills-calendar-tab"
                >
                  <div className="row">
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("totalEmployees")}</span>
                            <h3 className="mb-0">
                              <span className="counter">
                                {" "}
                                {totalEmployees && (
                                  <CountUp end={totalEmployees} />
                                )}
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("inactiveEmps")}</span>
                            <h3 className="mb-0">
                              {inactiveEmployees && (
                                <CountUp end={inactiveEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("activeEmps")}</span>
                            <h3 className="mb-0">
                              {activeEmployees && (
                                <CountUp end={activeEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("maleEmps")}</span>
                            <h3 className="mb-0 counter">
                              {" "}
                              {maleEmployees && <CountUp end={maleEmployees} />}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("femaleEmps")}</span>
                            <h3 className="mb-0 counter">
                              {" "}
                              {feMaleEmployees && (
                                <CountUp end={feMaleEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("otherEmps")}</span>
                            <h3 className="mb-0 counter">
                              {" "}
                              {otherEmployees && (
                                <CountUp end={otherEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Managers</h3>
                {localStorage.getItem("role") === "ROLE_SUPER_ADMIN" && (
                  <div className="card-options">
                    <button
                      className="btn btn-primary"
                      title={t("assignRemoveManagers")}
                      data-toggle="modal"
                      data-target="#assignManagerModal"
                    >
                      {t("assignRemoveManagers")}
                    </button>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t("manager")}</th>
                        <th>Email</th>
                        <th>{t("phone")}</th>
                        <th>{t("inviteSent")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyData.managers?.map((manager, index) => (
                        <tr key={index}>
                          <td>{manager.id}</td>
                          <td>{manager.name}</td>
                          <td>{manager.managerEmail}</td>
                          <td>
                            {manager.countryCode}
                            {"-"}
                            {manager.phone}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {manager.invitationSent ? (
                              <>
                                <span
                                  className="tag tag-success"
                                  style={{
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {t("inviteSent")}
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="tag tag-warning"
                                  style={{
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {t("pending")}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {companyData.subCompanyEntities && (
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">{t("daughterCompanies")}</h3>
                  {localStorage.getItem("role") === "ROLE_SUPER_ADMIN" && (
                    <div className="card-options">
                      <button
                        className="btn btn-primary"
                        title={t("addDaughterCompany")}
                        data-toggle="modal"
                        data-target="#addModal"
                      >
                        {t("addDaughterCompany")}
                      </button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>Email</th>
                          <th>{t("referenceNum")}</th>
                          <th>{t("registrationId")}</th>
                          <th>{t("employeeCount")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyData.subCompanyEntities?.map(
                          (company, index) => (
                            <tr key={index}>
                              <td>{company.name}</td>
                              <td>{company.email}</td>
                              <td>{company.referenceNum}</td>
                              <td>{company.registrationId}</td>
                              <td>{company.employeeCount}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <p className="p-4">
                  <b> {t("pleaseNote")} </b>{" "}
                  {t("totalEmployeesIncludingDaughterPart1")} {companyData.name}{" "}
                  {t("totalEmployeesIncludingDaughterPart2")}
                  <b
                    style={{ fontWeight: 600, color: "navy", fontSize: "18px" }}
                  >
                    {" "}
                    {companyData.employeeCount}{" "}
                  </b>
                  {t("totalEmployeesIncludingDaughterPart3")} {companyData.name}{" "}
                </p>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade"
          id="assignManagerModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="assignManagerModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="assignManagerModalLabel">
                  {t("assignRemoveManagers")}
                </h5>
                <button
                  type="button"
                  id="closeAssignManagerModal"
                  className="close"
                  onClick={handleAssignManagerClose}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: "20px" }}>
                <div className="row clearfix">
                  <div className="col-md-12 mb-4">
                    <b style={{ fontWeight: 600 }}>{t("companyName")} : </b>{" "}
                    {companyName}
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <MultiSelect
                        selectOptions={managerOptions}
                        setValues={setAssignedManagers}
                        selectedValues={assignedManagers}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleAssignManagerClose}
                  data-dismiss="modal"
                >
                  {t("close")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAssignManagerSubmit}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addModalLabel">
                  {t("addDaughterCompany")}
                </h5>
                <button
                  type="button"
                  id="closeAddModal"
                  className="close"
                  onClick={handleSubCompanyClose}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: "20px" }}>
                <div className="row clearfix">
                  <div
                    className="col-12 mb-4"
                    style={{ fontWeight: 650, color: "#b7b7b7" }}
                  >
                    <span>{t("companyDetails")}</span>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t("companyName")}
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1px",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          valid.name ? "is-valid" : " "
                        }  ${errors.name ? "is-invalid" : " "}  `}
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder={t("enterHere")}
                        required
                      />
                      {errors.name && (
                        <div className="invalid-feedback">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="form-label">{t("companyEmail")}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder={t("enterHere")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t("referenceNum")}
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1px",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          valid.referenceNum ? "is-valid" : " "
                        }  ${errors.referenceNum ? "is-invalid" : " "}  `}
                        name="referenceNum"
                        value={formData.referenceNum}
                        onChange={handleInputChange}
                        placeholder={t("enterHere")}
                        required
                      />
                      {errors.referenceNum && (
                        <div className="invalid-feedback">
                          {errors.referenceNum}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t("registrationId")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="registrationId"
                        value={formData.registrationId}
                        onChange={handleInputChange}
                        placeholder={t("enterHere")}
                        required
                      />
                    </div>
                  </div>
                  {!formData.addressSameAsParentCompany && (
                    <>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            {t("street")}
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginLeft: "1px",
                                marginRight: "4px",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              valid.street ? "is-valid" : " "
                            }  ${errors.street ? "is-invalid" : " "}  `}
                            name="street"
                            value={formData.street}
                            onChange={handleInputChange}
                            placeholder={t("enterStreet")}
                            required
                          />
                          {errors.street && (
                            <div className="invalid-feedback">
                              {errors.street}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            {t("houseNumber")}
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginLeft: "1px",
                                marginRight: "4px",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              valid.houseNumber ? "is-valid" : " "
                            }  ${errors.houseNumber ? "is-invalid" : " "}  `}
                            name="houseNumber"
                            value={formData.houseNumber}
                            onChange={handleInputChange}
                            placeholder="Enter House number"
                            required
                          />
                          {errors.houseNumber && (
                            <div className="invalid-feedback">
                              {errors.houseNumber}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            {t("location")}
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginLeft: "1px",
                                marginRight: "4px",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              valid.location ? "is-valid" : " "
                            }  ${errors.location ? "is-invalid" : " "}  `}
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            placeholder={t("enterHere")}
                            required
                          />
                          {errors.location && (
                            <div className="invalid-feedback">
                              {errors.location}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            {t("postCode")}
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginLeft: "1px",
                                marginRight: "4px",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              valid.postcode ? "is-valid" : " "
                            }  ${errors.postcode ? "is-invalid" : " "}  `}
                            name="postcode"
                            value={formData.postcode}
                            onChange={handleInputChange}
                            placeholder={t("enterPostCode")}
                            required
                          />
                          {errors.postcode && (
                            <div className="invalid-feedback">
                              {errors.postcode}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-md-4 col-cm-6 mt-8">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={formData.addressSameAsParentCompany}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            addressSameAsParentCompany: e.target.checked,
                          })
                        }
                      />
                      <span
                        className="custom-control-label"
                        style={{
                          fontSize: "15px",
                          fontWeight: 400,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("addressSameAsParent")}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleSubCompanyClose}
                  data-dismiss="modal"
                >
                  {t("close")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubCompanySubmit}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
