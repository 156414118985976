import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  generateApplicationForm,
  getApplicationForm,
  submitForm,
} from "../../../../services/insuranceFormService";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import SignaturePad from "../../../Utility/SignaturePad";
import MultiSelect from "../../../Utility/MultiSelect";
import Select from "react-select";
import Loading from "../../../Utility/Loading";

const ApplicationForm = ({
  birthDate,
  employeeId,
  pensionNumber,
  employmentDate,
  submit,
}) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const { t, i18n } = useTranslation("employee");
  const [annualSalaryLimit, setAnnualSalaryLimit] = useState();
  const [needFamilyMemberInsurance, setNeedFamilyMemberInsurance] = useState();
  const [consentGranted, setConsentGranted] = useState();
  const currentDate = new Date();
  const currentDateTimeInGermany = new Date(
    currentDate.toLocaleString("en-US", { timeZone: "Europe/Berlin" })
  );

  const hours = currentDateTimeInGermany.getHours();
  const minutes = currentDateTimeInGermany.getMinutes();
  // const period = hours >= 12 ? "PM" : "AM";
  const displayHours = hours;
  const initialTime = `${displayHours}:${minutes < 10 ? "0" : ""}${minutes}`;

  const [declarationDate, setDeclarationDate] = useState(
    currentDateTimeInGermany.toISOString().split("T")[0]
  );
  const [signDate, setSignDate] = useState(
    currentDateTimeInGermany.toISOString().split("T")[0]
  );
  const [time, setTime] = useState(initialTime);
  const [signUrl, setSignUrl] = useState("");
  const [signaturePadOpen, setSignaturePadOpen] = useState(false);
  const [name, setName] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [disabled, setDisabled] = useState(false);
  const [occupationTypes, setOccupationTypes] = useState(["EMPLOYED"]);
  const [insuranceType, setInsuranceType] = useState(["INFLUXFROMABROAD"]);
  const [reasonForChange, setReasonChange] = useState("INSURANCESTATUSCHANGED");
  const [rightToVote, setRightToVote] = useState(null);
  const [needPensionNumber, setNeedPensionNumber] = useState(
    !(pensionNumber && pensionNumber.trim() !== "")
  );
  const [pensionInsuranceNumber, setPensionInsuranceNumber] =
    useState(pensionNumber);
  const [employeeBirthDate, setEmployeeBirthDate] = useState(birthDate);
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submittedDateTime, setSubmittedDateTime] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [insuranceStartDate, setInsuranceStartDate] = useState(employmentDate);
  const [maritalStatus, setMaritalStatus] = useState("");
  const initialErrorState = {
    occupationTypes: "",
    insuranceType: "",
    name: "",
    fromDate: "",
    toDate: "",
    needPensionNumber: "",
    reasonForChange: "",
    rightToVote: "",
    consentGranted: "",
    declarationDate: "",
    time: "",
    signUrl: "",
    signDate: "",
    insuranceStartDate: "",
    maritalStatus: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [fields, setFields] = useState({
    xx: "",
    ttmmjj: "",
    a: "",
    yyz: "",
  });
  const [ssnErrors, setSsnErrors] = useState({
    xx: "",
    ttmmjj: "",
    a: "",
    yyz: "",
  });

  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  const invalidFeedbackCardStyle = {
    display: hasErrors ? "block" : "none",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "100%",
    color: "#dc3545",
  };

  useEffect(() => {
    setDisabled(false);
    // if(pensionNumber && pensionNumber.trim() !== ''){
    //   setNeedPensionNumber(false);
    // }
    getViactivApplicationForm();
  }, []);

  useEffect(() => {}, [applicationSubmitted]);

  useEffect(() => {
    if (pensionInsuranceNumber) {
      // Split the saved SSN into parts and populate the fields
      const xx = pensionInsuranceNumber.slice(0, 2);
      const ttmmjj = pensionInsuranceNumber.slice(2, 8);
      const a = pensionInsuranceNumber.slice(8, 9);
      const yyz = pensionInsuranceNumber.slice(9);
      setFields({ xx, ttmmjj, a, yyz });
    }
  }, [pensionInsuranceNumber]);

  const saveApplicationForm = () => {
    let ssn = pensionInsuranceNumber;
    if (!needPensionNumber) {
      ssn = `${fields.xx}${fields.ttmmjj}${fields.a}${fields.yyz}`;
    }
    const formData = {
      occupationTypes: occupationTypes,
      previousProvider: {
        name: name,
        fromDate: fromDate,
        toDate: toDate,
      },
      reasonForChange: reasonForChange,
      needPensionNumber: needPensionNumber,
      pensionInsuranceNumber: ssn,
      insuranceType: insuranceType,
      consentGranted: consentGranted,
      rightToVote: rightToVote,
      declarationDate: declarationDate,
      insuranceStartDate: insuranceStartDate,
      time: time,
      signDate: signDate,
      signUrl: signUrl,
      maritalStatus: maritalStatus,
      provider: "VIACTIVKRANKENKASSE",
      formType: "APPLICATION_FORM",
    };
    if (validateForm(formData)) {
      setIsLoading(true);
      setEditMode(false);
      submitApplicationForm(formData);
      setDisabled(true);
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    if (!formData.occupationTypes || formData.occupationTypes.length == 0) {
      newErrors.occupationTypes = t("required");
    }
    // if (!formData.insuranceType || formData.insuranceType.length == 0) {
    //   newErrors.insuranceType = t("required");
    // }
    // if (
    //   !formData.previousProvider.name ||
    //   formData.previousProvider.name.trim() === ""
    // ) {
    //   newErrors.name = t("required");
    // }
    // if (
    //   !formData.previousProvider.fromDate ||
    //   formData.previousProvider.fromDate.trim() === ""
    // ) {
    //   newErrors.fromDate = t("required");
    // }
    // if (
    //   !formData.previousProvider.toDate ||
    //   formData.previousProvider.toDate.trim() === ""
    // ) {
    //   newErrors.toDate = t("required");
    // }
    if (!formData.reasonForChange || formData.reasonForChange.trim() === "") {
      newErrors.reasonForChange = t("required");
    }
    if (!formData.needPensionNumber && !validateFields()) {
      newErrors.needPensionNumber = t("needPension");
    }
    // if (!consentGranted) {
    //   newErrors.consentGranted = t("required");
    // }
    // if (!formData.declarationDate || formData.declarationDate.trim() === "") {
    //   newErrors.declarationDate = t("required");
    // }
    if (
      !formData.insuranceStartDate ||
      formData.insuranceStartDate.trim() === ""
    ) {
      newErrors.insuranceStartDate = t("required");
    }
    if (!formData.maritalStatus || formData.maritalStatus.trim() === "") {
      newErrors.maritalStatus = t("required");
    }

    // if (!formData.time || formData.time.trim() === "") {
    //   newErrors.time = t("required");
    // }
    // if (!formData.rightToVote || formData.rightToVote.trim() === "") {
    //   newErrors.rightToVote = t("required");
    // }
    // if (!formData.signUrl || formData.signUrl.trim() === "") {
    //   newErrors.signUrl = t("required");
    // }

    if (formData.insuranceStartDate && formData.signDate) {
      const signDateObj = new Date(formData.signDate);
      const insuranceDateObj = new Date(formData.insuranceStartDate);
      if (signDateObj >= insuranceDateObj) {
        newErrors.signDate =
          "Das Signaturdatum sollte vor dem Versicherungsdatum liegen.";
      }
    }
    setErrors(newErrors);
    setHasErrors(Object.keys(newErrors).length === 0 ? false : true);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextSSNInput = (e, nextInputId, length) => {
    if (e.target.value.length === length) {
      document.getElementById(nextInputId).focus();
    }
  };

  // Handle input changes
  const handleSSNChange = (e) => {
    const { name, value } = e.target;
    // if(fields.ttmmjj === ""){
    //   const [dd, mm, yy] = employeeBirthDate.split("-");
    //   const ttmmjj = `${dd}${mm}${yy.slice(2)}`; // Convert to TTMMJJ
    //   setFields({ ...fields, [name]: value,"ttmmjj":ttmmjj  });
    // }else{
    //   setFields({ ...fields, [name]: value });
    // }
    // setNeedPensionNumber(false);
    // Check if employeeBirthDate is defined and has the correct format
    if (fields.ttmmjj === "" && employeeBirthDate && name !== "ttmmjj") {
      const [dd, mm, yy] = employeeBirthDate.split("-");
      const ttmmjj = `${dd}${mm}${yy.slice(2)}`;
      setFields((prevFields) => ({ ...prevFields, [name]: value, ttmmjj }));
    } else {
      setFields((prevFields) => ({ ...prevFields, [name]: value }));
    }
    setNeedPensionNumber(false);
  };

  // Individual validation for each part
  const validateFields = () => {
    let isValid = true;
    let newssnErrors = { xx: "", ttmmjj: "", a: "", yyz: "" };

    // Validate XX (first 2 digits)
    if (!/^[0-9]{2}$/.test(fields.xx)) {
      newssnErrors.xx = "XX muss genau 2 Ziffern haben.";
      isValid = false;
    }

    // Validate TTMMJJ (birthdate in DDMMYY format)
    if (!/^[0-9]{6}$/.test(fields.ttmmjj)) {
      newssnErrors.ttmmjj = "TTMMJJ muss genau 6 Ziffern (Geburtsdatum) haben";
      isValid = false;
    }

    // Validate A (1 letter)
    if (!/^[A-Z]$/.test(fields.a)) {
      newssnErrors.a = "A muss genau 1 Großbuchstaben haben";
      isValid = false;
    }

    // Validate YYZ (2 digits and 1 letter)
    if (!/^[0-9]{2}[A-Z]$/.test(fields.yyz)) {
      newssnErrors.yyz = "YYZ muss 2 Ziffern gefolgt von 1 Großbuchstaben sein";
      isValid = false;
    }

    setSsnErrors(newssnErrors);
    return isValid;
  };

  const setApplicationForm = (data) => {
    setOccupationTypes(data.occupationTypes);
    setName(data.previousProvider?.name);
    setFromDate(data.previousProvider?.fromDate);
    setToDate(data.previousProvider?.toDate);
    setReasonChange(data.reasonForChange);
    setNeedPensionNumber(data.needPensionNumber);
    setPensionInsuranceNumber(data.pensionInsuranceNumber);
    setInsuranceType(data.insuranceType);
    setConsentGranted(data.consentGranted);
    setRightToVote(data.rightToVote);
    setDeclarationDate(data.declarationDate);
    setTime(data.time);
    setSignDate(data.signDate);
    setSignUrl(data.signUrl);
    setSubmittedDateTime(data.submittedTime);
    setInsuranceStartDate(data.insuranceStartDate);
    setMaritalStatus(data.maritalStatus);
  };

  const getViactivApplicationForm = async () => {
    try {
      const response = await getApplicationForm(localStorage.token, employeeId);
      if (response) {
        setApplicationForm(response);
        setApplicationSubmitted(true);
        setDisabled(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitApplicationForm = async (formData) => {
    try {
      const response = await submitForm(
        localStorage.token,
        employeeId,
        formData
      );
      if (response) {
        toast.success(t("formSubmittedSuccessfully"));
        window.location.reload();
        //getViactivApplicationForm();
      } else {
        toast.error(t("formSubmissionFailed"));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const closeSignaturePad = () => {
    setSignaturePadOpen(false);
  };

  const setSignUrlFromPad = (url) => {
    setSignUrl(url);
  };

  const generateForm = async () => {
    try {
      const response = await generateApplicationForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const contentDisposition = response.headers["content-disposition"];
      const filenameRegex = /filename=([^;]+)/;
      const matches = filenameRegex.exec(contentDisposition);
      const filename =
        matches && matches[1]
          ? matches[1].trim()
          : "Versicherungsantragsformular.pdf";
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.log(error);
    }
  };

  const OCCUPATION_TYPE_OPTIONS = [
    { value: "EMPLOYED", label: "Beschäftigt" },
    { value: "VOLUNTARILYINSURED", label: "Freiwillig versichert" },
    {
      value: "MINIJOBMONTHLYGROSS",
      label: "Monatliches Bruttoentgelt beträgt bis zu 538,– Euro (Minijob)",
    },
    { value: "VOCATIONALTRAINING", label: "In Ausbildung" },
    { value: "STUDYING", label: "Studierend" },
    { value: "SELFEMPLOYED", label: "Selbstständig" },
    { value: "PENSIONRECEIPT", label: "Rentenbezug" },
    { value: "UNEMPLOYMENTBENEFITS", label: "Arbeitslosengeld" },
    { value: "BASICINCOME", label: "Bürgergeld" },
  ];

  const INSURANCE_TYPE_OPTIONS = [
    { value: "COMPULSORYINSURANCE", label: "Pflichtversichert" },
    { value: "PRIVATEINSURANCE", label: "Privat" },
    { value: "VOLUNTARYINSURANCE", label: "Freiwillig" },
    { value: "FAMILYINSURANCE", label: "Familienversichert" },
    { value: "NOTLEGALLYINSURED", label: "Nicht gesetzl. versichert" },
    { value: "INFLUXFROMABROAD", label: "Zuzug aus dem Ausland" },
  ];

  const REASON_CHANGE_OPTIONS = [
    {
      value: "INSURANCESTATUSCHANGED",
      label:
        "Der Versicherungsstatus hat sich geändert. (z. B. Aufnahme einer neuen Beschäftigung/Ausbildung/Studium etc.)",
    },
    {
      value: "INSURANCESTATUSUNCHANGED",
      label:
        "Der Versicherungsstatus ist unverändert. (Die Vorkasse wird über den Kündigungswunsch informiert.)",
    },
  ];

  const RIGHT_TO_VOTE_OPTIONS = [
    { value: "WITHCALL", label: "Persönlich" },
    {
      value: "AFTERCALL",
      label: "Nach telefonischer Rücksprache",
    },
  ];

  const MARRITAL_STATUS_OPTIONS = [
    { value: "SINGLE", label: "Ledig" },
    { value: "MARRIED", label: "Verheiratet" },
    {
      value: "CIVIL_PARTNERSHIP",
      label: "Lebenspartnerschaft",
    },
  ];

  const setOccupationTypesValues = (values) => {
    setOccupationTypes(values);
  };

  const setInsuranceTypeValues = (values) => {
    setInsuranceType(values);
  };

  // const handlePensionInsuranceNumber = (e) => {
  //   setPensionInsuranceNumber(e.target.value);
  //   setNeedPensionNumber(false);
  // };

  const handleNeedPensionInsurance = (needPensionNo) => {
    if (needPensionNo) {
      setNeedPensionNumber(true);
      setPensionInsuranceNumber("");
      setFields({
        xx: "",
        ttmmjj: "",
        a: "",
        yyz: "",
      });
      setSsnErrors({
        xx: "",
        ttmmjj: "",
        a: "",
        yyz: "",
      });
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    setDisabled(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setDisabled(true);
    window.location.reload(false);
  };

  function formatDateOnlyGerman(dateString) {
    const date = new Date(dateString);
    const germanDate = new Intl.DateTimeFormat("de-DE", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);

    return germanDate;
  }

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    //const signDateObj = new Date(signDate);
    const insuranceDateObj = new Date(insuranceStartDate);
    // if (signDateObj >= insuranceDateObj) {
    //   toast.error(
    //     "Das Signaturdatum sollte vor dem Versicherungsdatum liegen."
    //   );
    // }
    const oneDayBefore = new Date(insuranceDateObj);
    oneDayBefore.setDate(insuranceDateObj.getDate() - 1);
    const year = oneDayBefore.getFullYear();
    const month = String(oneDayBefore.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(oneDayBefore.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setToDate(formattedDate);
  }, [insuranceStartDate]);

  return (
    <div className="card">
      {applicationSubmitted && (
        <div className="card-alert alert alert-success mb-0">
          {t("applicationSubmittedOn")} &nbsp;
          {formatDateOnlyGerman(submittedDateTime)}.&nbsp;&nbsp;
          <button className="btn btn-info" onClick={generateForm}>
            {t("viewFromPDF")}
          </button>
        </div>
      )}
      {!applicationSubmitted && (
        <div className="card-alert alert alert-info mb-0">
          {t("applicationRequired")}
        </div>
      )}
      <div style={invalidFeedbackCardStyle}>
        <div className="card-alert alert alert-danger mb-0 mt-4">
          {t("applicationFormError")}
        </div>
      </div>
      <div className="card-header d-flex justify-between m-0 p-0 mt-4 ml-2 mb-1">
        <div className="btn-group" role="group">
          <button
            type="button"
            className={`btn ${
              activeTab === 1 ? "btn-primary" : "btn-secondary"
            }`}
            style={{
              border: activeTab === 1 ? "3px solid #00a67d" : "",
              zIndex: activeTab === 1 ? "1px" : "",
            }}
            id="insurance-relationship-tab"
            data-toggle="tab"
            href="#insurance-relationship"
            onClick={() => handleTabClick(1)}
          >
            VERS.-BEZIEHUNG
          </button>
          {/* <button
            type="button"
            className={`btn ${
              activeTab === 2 ? "btn-primary" : "btn-secondary"
            } ml-2`}
            style={{
              border: activeTab === 2 ? "3px solid #00a67d" : "",
              zIndex: activeTab === 2 ? "1px" : "",
            }}
            id="consent-tab"
            data-toggle="tab"
            href="#consent"
            onClick={() => handleTabClick(2)}
          >
            ZUSTIMMUNG
          </button> */}
        </div>
        <div>
          {/* <h4
            className="form-label text-right mt-2 mr-2"
            style={{ fontWeight: 600, fontSize: "18px", color: "#5a5279" }}
          >
            {activeTab} von 2 Tabs
          </h4> */}
        </div>
      </div>
      <hr className="m-0 p-0" />
      <div className="card-body p-2">
        <div className="tab-content">
          <div
            className="tab-pane fade show active p-4"
            id="insurance-relationship"
            role="tabpanel"
            style={{
              backgroundColor: "whitesmoke",
            }}
          >
            <div>
              <div className="row ml-2">
                <div
                  className="col-md-3 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      {t("selectedNameOfInsurance")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value="Viactiv Karkenkasse"
                      disabled
                    />
                  </div>
                </div>
                <div
                  className="col-md-3 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      {t("startMembershipDate")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="date"
                      name="insuranceStartDate"
                      value={insuranceStartDate}
                      onChange={(e) => setInsuranceStartDate(e.target.value)}
                      // className="border rounded px-2 py-1 w-full"
                      className="form-control"
                      disabled={disabled}
                    />
                    {errors.insuranceStartDate && (
                      <div style={invalidFeedbackStyle}>
                        {errors.insuranceStartDate}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="col-md-3 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      {t("maritalStatus")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={MARRITAL_STATUS_OPTIONS}
                      value={MARRITAL_STATUS_OPTIONS.find(
                        (option) => option.value === maritalStatus
                      )}
                      onChange={(e) => setMaritalStatus(e.value)}
                      placeholder={t("select")}
                      isDisabled={disabled}
                    />
                    {errors.maritalStatus && (
                      <div style={invalidFeedbackStyle}>
                        {errors.maritalStatus}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="col-md-3 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      {t("occupationType")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <MultiSelect
                      selectOptions={OCCUPATION_TYPE_OPTIONS}
                      setValues={setOccupationTypesValues}
                      selectedValues={occupationTypes}
                      disabled={disabled}
                    />
                    {errors.occupationTypes && (
                      <div style={invalidFeedbackStyle}>
                        {errors.occupationTypes}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ml-2">
                <div
                  className="col-md-12 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={annualSalaryLimit}
                        onChange={() =>
                          setAnnualSalaryLimit(!annualSalaryLimit)
                        }
                        disabled={disabled}
                      />
                      <span
                        className="custom-control-label"
                        style={{
                          color: "#282826",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        das Einkommen liegt über der Jahresarbeitsentgeltgrenze
                        (jährlich 69.300 Euro – Stand 2024)
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <h6
                className="ml-3 mt-2 mb-2"
                style={{ fontSize: "17px", fontWeight: 600, color: "#58585B" }}
              >
                Die Kundin bzw. der Kunde war zuletzt versichert
              </h6>
              <div className="row ml-2">
                <div className="col-md-3 col-sm-12">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      {t("nameOfInsurance")}
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t("nameOfInsurance")}
                      required
                      disabled={disabled}
                    />
                    {/* {errors.name && (
                      <div style={invalidFeedbackStyle}>{errors.name}</div>
                    )} */}
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      Von
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <input
                      type="date"
                      name="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      // className="border rounded px-2 py-1 w-full"
                      className="form-control"
                      disabled={disabled}
                    />
                    {/* {errors.fromDate && (
                      <div style={invalidFeedbackStyle}>{errors.fromDate}</div>
                    )} */}
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      Bis
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <input
                      type="date"
                      name="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      // className="border rounded px-2 py-1 w-full"
                      className="form-control"
                      disabled={disabled}
                    />
                    {/* {errors.toDate && (
                      <div style={invalidFeedbackStyle}>{errors.toDate}</div>
                    )} */}
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      {t("insuranceType")}
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <MultiSelect
                      selectOptions={INSURANCE_TYPE_OPTIONS}
                      setValues={setInsuranceTypeValues}
                      selectedValues={insuranceType}
                      disabled={disabled}
                    />
                    {/* {errors.insuranceType && (
                      <div style={invalidFeedbackStyle}>
                        {errors.insuranceType}
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="row ml-2 mb-2">
                <div
                  className="col-md-12 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={needFamilyMemberInsurance}
                        onChange={() =>
                          setNeedFamilyMemberInsurance(
                            !needFamilyMemberInsurance
                          )
                        }
                        disabled={disabled}
                      />
                      <span
                        className="custom-control-label"
                        style={{
                          color: "#282826",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        Es sollen Familienangehörige kostenfrei mitversichert
                        werden. Bitte senden Sie mir den erforderlichen
                        Fragebogen zu.
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row ml-2 mb-2">
                <div className="col-md-8 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      Grund für den Kassenwechsel
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={REASON_CHANGE_OPTIONS}
                      value={REASON_CHANGE_OPTIONS.find(
                        (option) => option.value === reasonForChange
                      )}
                      onChange={(selectedOption) =>
                        setReasonChange(selectedOption.value)
                      }
                      placeholder={t("select")}
                      isDisabled={disabled}
                    />
                    {errors.reasonForChange && (
                      <div style={invalidFeedbackStyle}>
                        {errors.reasonForChange}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ml-2 mb-4">
                <div className="col-md-4 col-sm-12">
                  {/* <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      {t("pensionInsuranceNumber")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={pensionInsuranceNumber}
                      onChange={(e) => handlePensionInsuranceNumber(e)}
                      disabled={disabled}
                    />
                  </div> */}
                  <div className="form-group">
                    <label className="form-label">
                      {t("Sozialversicherungsnummer")}
                    </label>
                    {/* <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={pensionInsuranceNumber}
                                    onChange={(e) =>
                                      setPensionInsuranceNumber(e.target.value)
                                    }
                                  /> */}

                    {/* XX input (2 digits) */}
                    <input
                      type="text"
                      name="xx"
                      id="xx"
                      maxLength="2"
                      value={fields.xx}
                      disabled={disabled}
                      onChange={handleSSNChange}
                      onKeyUp={(e) => handleNextSSNInput(e, "ttmmjj", 2)}
                      style={{
                        padding: "6px",
                        //marginRight: "5px",
                        width: "50px",
                        border: ssnErrors.xx
                          ? "1px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                        fontSize: "14px",
                        opacity: 1,
                      }}
                    />
                    {" - "}
                    {/* TTMMJJ input (6 digits) */}
                    <input
                      type="text"
                      name="ttmmjj"
                      id="ttmmjj"
                      maxLength="6"
                      value={fields.ttmmjj}
                      disabled={disabled}
                      onChange={handleSSNChange}
                      onKeyUp={(e) => handleNextSSNInput(e, "a", 6)}
                      style={{
                        padding: "6px",
                        //marginRight: "5px",
                        width: "80px",
                        border: ssnErrors.ttmmjj
                          ? "1px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                        fontSize: "14px",
                        opacity: 1,
                      }}
                    />
                    {" - "}
                    {/* A input (1 letter) */}
                    <input
                      type="text"
                      name="a"
                      id="a"
                      maxLength="1"
                      value={fields.a}
                      disabled={disabled}
                      onChange={handleSSNChange}
                      onKeyUp={(e) => handleNextSSNInput(e, "yyz", 1)}
                      style={{
                        padding: "6px",
                        //marginRight: "5px",
                        width: "30px",
                        border: ssnErrors.a
                          ? "1px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                        fontSize: "14px",
                        opacity: 1,
                      }}
                    />
                    {" - "}
                    {/* YYZ input (2 digits, 1 letter) */}
                    <input
                      type="text"
                      name="yyz"
                      id="yyz"
                      maxLength="3"
                      value={fields.yyz}
                      disabled={disabled}
                      onChange={handleSSNChange}
                      style={{
                        padding: "6px",
                        width: "70px",
                        border: ssnErrors.yyz
                          ? "1px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                        fontSize: "14px",
                        opacity: 1,
                      }}
                    />

                    {(ssnErrors.xx ||
                      ssnErrors.ttmmjj ||
                      ssnErrors.a ||
                      ssnErrors.yyz) &&
                      !needPensionNumber && (
                        <>
                          <p
                            className="mt-1 mb-0"
                            style={{
                              color: "red",
                              fontSize: "13px",
                            }}
                          >
                            Erwartetes Format ist XX-TTMMJJ-A-YYZ
                          </p>
                        </>
                      )}

                    {ssnErrors.xx && (
                      <p
                        className="mt-1 mb-0"
                        style={{ color: "red", fontSize: "13px" }}
                      >
                        {ssnErrors.xx}
                      </p>
                    )}
                    {ssnErrors.ttmmjj && (
                      <p
                        className="mt-1 mb-0"
                        style={{ color: "red", fontSize: "13px" }}
                      >
                        {ssnErrors.ttmmjj}
                      </p>
                    )}
                    {ssnErrors.a && (
                      <p
                        className="mt-1 mb-0"
                        style={{ color: "red", fontSize: "13px" }}
                      >
                        {ssnErrors.a}
                      </p>
                    )}
                    {ssnErrors.yyz && (
                      <p
                        className="mt-1 mb-0"
                        style={{ color: "red", fontSize: "13px" }}
                      >
                        {ssnErrors.yyz}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 mt-4">
                  <div className="form-group" style={{ marginTop: "4px" }}>
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={needPensionNumber}
                        onChange={() =>
                          handleNeedPensionInsurance(!needPensionNumber)
                        }
                      />
                      <span
                        className="custom-control-label"
                        style={{
                          color: "#282826",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        Sozialversicherungsnummer beantragen.
                      </span>
                    </label>
                  </div>
                </div>
                {errors.needPensionNumber && (
                  <div style={invalidFeedbackStyle}>
                    {errors.needPensionNumber}
                  </div>
                )}
              </div>
              <div className="row ml-2 mb-4">
                <div className="col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      Wahlrecht
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <Select
                      options={RIGHT_TO_VOTE_OPTIONS}
                      value={RIGHT_TO_VOTE_OPTIONS.find(
                        (option) => option.value === rightToVote
                      )}
                      onChange={(selectedOption) =>
                        setRightToVote(selectedOption.value)
                      }
                      placeholder={t("select")}
                      isDisabled={disabled}
                    />
                    {/* {errors.rightToVote && (
                      <div style={invalidFeedbackStyle}>
                        {errors.rightToVote}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      am
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <input
                      type="date"
                      name="declarationDate"
                      value={declarationDate}
                      onChange={(e) => setDeclarationDate(e.target.value)}
                      // className="border rounded px-2 py-1 w-full"
                      className="form-control"
                      disabled={disabled}
                    />
                    {/* {errors.declarationDate && (
                      <div style={invalidFeedbackStyle}>{errors.declarationDate}</div>
                    )} */}
                  </div>
                </div>
                <div className="col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      um
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <input
                      type="text"
                      name="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      className="form-control"
                      disabled={disabled}
                    />
                    {/* {errors.time && (
                      <div style={invalidFeedbackStyle}>{errors.time}</div>
                    )} */}
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginTop: "28px" }}
                >
                  Uhr hat die/der Versicherte sein Wahlrecht zur VIACTIV unter
                  Angabe der vorstehenden Daten abgegeben.
                </div>
              </div>
              <div className="row ml-2 mb-4">
                <div
                  className="col-md-3 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      Datum
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <input
                      type="date"
                      name="signDate"
                      value={signDate}
                      onChange={(e) => setSignDate(e.target.value)}
                      className="form-control"
                      disabled={disabled}
                    />
                    {errors.signDate && (
                      <div style={invalidFeedbackStyle}>{errors.signDate}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-1 col-sm-12"></div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      Namen der/des Mitarbeitenden
                      {/* <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span> */}
                    </label>
                    <div>
                      <img src={signUrl}></img>
                      <button
                        className="btn btn-info"
                        style={{
                          height: "35px",
                          width: "100px",
                          padding: "2px",
                          marginTop: "5px",
                          fontWeight: 600,
                        }}
                        onClick={() => setSignaturePadOpen(true)}
                        disabled={disabled}
                      >
                        ZEICHEN
                      </button>
                      {/* {errors.signUrl && (
                        <div style={invalidFeedbackStyle}>{errors.signUrl}</div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ml-2 mb-4">
                <div
                  className="col-md-12 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  Bitte beachten Sie die beiliegenden Informationen zum
                  Datenschutz. Diese finden Sie auch online unter
                  www.viactiv.de/datenschutz.
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="tab-pane fade p-4"
            id="consent"
            role="tabpanel"
            style={{
              backgroundColor: "whitesmoke",
            }}
          >
            <div>
              <h6
                className="ml-3 mt-2"
                style={{ fontSize: "17px", fontWeight: 600, color: "#58585B" }}
              >
                Datenschutzrechtliche und werberechtliche Einwilligungserklärung
              </h6>
              <div className="row ml-2 mt-2 mb-2">
                <div
                  className="col-md-10 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group">
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={consentGranted}
                        onChange={() => setConsentGranted(!consentGranted)}
                        disabled={disabled}
                      />
                      <span
                        className="custom-control-label"
                        style={{
                          color: "#282826",
                          fontSize: "15px",
                          fontWeight: 400,
                        }}
                      >
                        Der Kunde ist damit einverstanden, dass die VIACTIV
                        Krankenkasse die angegebenen personenbezogenen Daten
                        verarbeitet und nutzt, um sowohl über die Vorteile einer
                        Mitgliedschaft als auch zum Zwecke der Werbung und/oder
                        Marktforschung schriftlich, telefonisch oder per E-Mail
                        zu informieren und zu beraten.
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row ml-2 mb-4">
                <div className="col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      Wahlrecht
                    </label>
                    <Select
                      options={RIGHT_TO_VOTE_OPTIONS}
                      value={RIGHT_TO_VOTE_OPTIONS.find(
                        (option) => option.value === rightToVote
                      )}
                      onChange={(selectedOption) =>
                        setRightToVote(selectedOption.value)
                      }
                      placeholder={t("select")}
                      isDisabled={disabled}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      am
                    </label>
                    <input
                      type="date"
                      name="declarationDate"
                      value={declarationDate}
                      onChange={(e) => setDeclarationDate(e.target.value)}
                      // className="border rounded px-2 py-1 w-full"
                      className="form-control"
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#282826" }}>
                      um
                    </label>
                    <input
                      type="text"
                      name="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      className="form-control"
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginTop: "28px" }}
                >
                  Uhr hat die/der Versicherte sein Wahlrecht zur VIACTIV unter
                  Angabe der vorstehenden Daten abgegeben.
                </div>
              </div>
              <div className="row ml-2 mb-4">
                <div
                  className="col-md-3 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      Datum
                    </label>
                    <input
                      type="date"
                      name="signDate"
                      value={signDate}
                      onChange={(e) => setSignDate(e.target.value)}
                      className="form-control"
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="col-md-1 col-sm-12"></div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#282826" }}>
                      Namen der/des Mitarbeitenden
                    </label>
                    <div>
                      <img src={signUrl}></img>
                      <button
                        className="btn btn-info"
                        style={{
                          height: "35px",
                          width: "100px",
                          padding: "2px",
                          marginTop: "5px",
                          fontWeight: 600,
                        }}
                        onClick={() => setSignaturePadOpen(true)}
                        disabled={disabled}
                      >
                        ZEICHEN
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ml-2 mb-4">
                <div
                  className="col-md-12 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  Bitte beachten Sie die beiliegenden Informationen zum
                  Datenschutz. Diese finden Sie auch online unter
                  www.viactiv.de/datenschutz.
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="card-footer">
        <div>
          <button
            className="btn btn-primary"
            style={{ textTransform: "uppercase" }}
            disabled={disabled}
            onClick={saveApplicationForm}
          >
            {t("submit")}
          </button>
          {disabled && (
            <>
              <button
                className="btn btn-primary ml-10"
                style={{ textTransform: "uppercase" }}
                onClick={handleEdit}
              >
                {t("edit")}
              </button>
            </>
          )}
          {editMode && (
            <>
              <button
                className="btn btn-info ml-10"
                style={{ textTransform: "uppercase" }}
                onClick={handleCancel}
              >
                {t("cancel")}
              </button>
            </>
          )}
        </div>
      </div>
      <Dialog
        open={signaturePadOpen}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
          <SignaturePad
            closeSignaturePad={closeSignaturePad}
            setSignUrlFromPad={setSignUrlFromPad}
          />
        </div>
      </Dialog>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ApplicationForm;
