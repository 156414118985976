import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({categories,series}) => {

  const options = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: categories,
    legend: {
      position: 'bottom',
      offsetX: 10,
      offsetY: 5,
      markers: {
        fillColors: ['#5a5278', '#6f6593', '#8075aa', '#a192d9']
      }
    },
    fill: {
      colors: ['#5a5278', '#6f6593', '#8075aa', '#a192d9']
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%"
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="pie" width="100%" />
      </div>
    </div>
  );
};

export default PieChart;
