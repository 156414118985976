import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const axiosInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const downloadApiInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmployeeList = async (token, requestParams) => {
  try {
    const response = await axiosInstance(token).get("/employee/list", {
      params: requestParams,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeCountData = async (token) => {
  try {
    const response = await axiosInstance(token).get("/employee/counts");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDesignationOptions = async (token) => {
  try {
    const response = await axiosInstance(token).get("/employee/designations");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllDepartmentOptions = async (token) => {
  try {
    const response = await axiosInstance(token).get("/employee/departments");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addEmployeeApi = async (token, employeeData) => {
  try {
    const response = await axiosInstance(token).post("/employee", employeeData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeDetailsById = async (token, id) => {
  try {
    const response = await axiosInstance(token).get("/employee/" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editEmployeeBankDetails = async (token, id, request) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/" + id + "/bank/details",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editEmployeePayrollData = async (token, id, request) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/" + id + "/payroll/data",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editEmployeePersonalData = async (token, id, request) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/" + id + "/personal/data",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editEmployeeProfile = async (token, id, request) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/" + id + "/profile",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editPersonalInfo = async (token, id, request) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/" + id + "/personal/info",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const raiseSelfLeaverequest = async (token, leave) => {
  try {
    const response = await axiosInstance(token).post(
      "/raise/leave/self/request",
      leave
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserLeaveHistory = async (token, id) => {
  try {
    const response = await axiosInstance(token).get("/leave/history/" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLeavesForMonth = async (token, month, empId) => {
  try {
    const response = await axiosInstance(token).get(
      "/leave/month/" + month + "/history/" + empId
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeLeavesForScheduler = async (token, search) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/leaves/dates",
      search
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStoreEmployeeList = async (token, id) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/store/" + id + "/list"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeDropDownListApi = async (token) => {
  try {
    const response = await axiosInstance(token).get("/employee/dropdown/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generateContractForEmployee = async (
  employeeId,
  token,
  formData
) => {
  try {
    const url = `/contract/download/${employeeId}?startDate=${formData.startDate}&endDate=${formData.endDate}`;
    const response = await downloadApiInstance(token).get(url, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const downloadMonthlyPayrollReportApi = async (
  token,
  storeId,
  month,
  year
) => {
  try {
    const url = `/employee/download/store/${storeId}/month/${month}/year/${year}`;
    const response = await downloadApiInstance(token).get(url, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const disableEmployees = async (token, data) => {
  try {
    const response = await axiosInstance(token).post("/employee/disable", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserPermissions = async (token, permissions, id) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/" + id + "/update/permissions",
      permissions
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generateCredsForEmployee = async (token, ids) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/generate/credentials",
      ids
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMyEmployeeDetails = async (token) => {
  try {
    const response = await axiosInstance(token).get("/employee/details");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const processPayrollForEmployees = async (token, storeId, month) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/process/payroll/store/" + storeId + "/month/" + month
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllEmployeeSalaryDetails = async (token, storeId, month) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/store/" + storeId + "/salary/month/" + month
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPayrollSummaryForStore = async (token, storeId, year) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/store/" + storeId + "/year/" + year
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSalaryDetailsForEmployee = async (token, id, month) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/" + id + "/salary/process/month/" + month
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveSurchargeForEmployee = async (token, id, request) => {
  try {
    const response = await axiosInstance(token).post(
      "/employee/" + id + "/add/surcharge",
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getHealthInsuranceProvidersList = async (token) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/public/insurance/providers"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const approvePayrollForStore = async (token, storeId, month) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/store/" +
        storeId +
        "/salary/month/" +
        month +
        "/payroll/approve"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rejectPayrollForStore = async (token, storeId, month) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/store/" +
        storeId +
        "/salary/month/" +
        month +
        "/payroll/reject"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const markPaymentDoneBySalaryId = async (token, salaryId) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/salary/" + salaryId + "/mark/payment/done"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendPayslipToEmployeeBySalaryId = async (token, salaryId) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/salary/" + salaryId + "/generate/payslip"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeAccountApi = async (token) => {
  try {
    const response = await axiosInstance(token).get("/account/employee/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const activateEmployeeById = async (token, employeeId) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/" + employeeId + "/activate"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const disableEmployeeById = async (token, employeeId) => {
  try {
    const response = await axiosInstance(token).get(
      "/employee/" + employeeId + "/disable"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmailHistoryByEmployeeId = async (token, employeeId) => {
  try {
    const response = await axiosInstance(token).get(
      "/email/history/" + employeeId + "/employee"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
