import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="language-selector">
      <button onClick={() => changeLanguage('en')} style={{ marginRight: '4px' }} title='English'><img class="w20 mr-2" src="../assets/images/flags/us.svg" alt="EN" /></button>
      <button onClick={() => changeLanguage('de')} title='Deutsch'><img class="w20 mr-2" src="../assets/images/flags/de.svg" alt="DE"/></button>
     </div>
  );
}

export default LanguageSelector;
