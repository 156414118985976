import React, { useState } from "react";
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = ({closeSignaturePad,setSignUrlFromPad}) => {
    const [sign,setSign] = useState();

    const handleClear= () =>{
        sign.clear()
        setSignUrlFromPad('')
        closeSignaturePad();
    }
    const handleClose= () =>{
        closeSignaturePad();
    }
    const handleGenerate= () =>{
        setSignUrlFromPad(sign.getTrimmedCanvas().toDataURL('image/png'))
        closeSignaturePad();
    }

    return(
        <div className="card text-center">
            <div className="card-body">
            <h3 className="card-title">Sign Here</h3>
            <div style={{border:"2px solid black",width: 500, height: 200, marginTop:'10px'}}>
                <SignatureCanvas 
                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                    ref={data=>setSign(data)}
                />
            </div>
            </div>
            <div className="card-footer text-center">
            <button className="btn btn-info mr-2" onClick={handleClear}>CLEAR</button>
            <button  className="btn btn-info ml-2 mr-2" onClick={handleClose}>CLOSE</button>
            <button  className="btn btn-primary ml-2" onClick={handleGenerate}>SAVE</button>
            </div>
        </div>
    )
}
export default SignaturePad;