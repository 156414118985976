import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import { useStateContext } from "../../context/Context";
import { toast } from "react-toastify";
import { loginUser } from "../../services/authservice";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const { isLoggedIn, setLoggedIn, token, setJwtToken, setUserDetails } =
    useStateContext();
  const history = useHistory();

  const { t } = useTranslation("login");

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userCompany");
    localStorage.removeItem("authorities");
    localStorage.removeItem("token");
    localStorage.removeItem("companyId");
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    if (name === "email") {
      setEmail(newValue);
    } else if (name === "password") {
      setPassword(newValue);
    } else if (name === "rememberMe") {
      setRememberMe(newValue);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    }
    if (!password) {
      newErrors.password = "Password is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});

    const user = {
      username: email,
      password: password,
    };
    loginUser(user)
      .then((result) => {
        setEmail("");
        setPassword("");
        setRememberMe("");
        setLoggedIn(true);
        setJwtToken(result.token);
        setUserDetails(result);
        toast.success(t("success"), {
          autoClose: 500,
        });
        if (localStorage.role === "ROLE_ADMIN") {
          history.push("/manager/dashboard");
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(t("error"), {
          autoClose: 500,
        });
      });
  };

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              <img
                src="https://boardi.co/assets/images/logo_icon_1.png"
                className="img-fluid mx-auto mb-2"
                alt="/"
                style={{ width: "35px", height: "35px" }}
              ></img>
            </Link>
          </div>
          <div className="text-center mb-2">
            <LanguageSelector />
          </div>
          <div className="card-body">
            <div className="card-title" style={{ fontWeight: 500 }}>
              {t("title")}
            </div>
            {/* <div className="form-group">
								<select className="custom-select">
									<option>HR Dashboard</option>
									<option>Project Dashboard</option>
									<option>Job Portal</option>
								</select>
							</div> */}
            <div className="form-group">
              <label className="form-label">{t("email")}</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder={t("enterEmail")}
                name="email"
                value={email}
                onChange={handleInputChange}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="form-group">
              <label className="form-label">
                {t("password")}
                <Link className="float-right small" to="/forgotpassword">
                  {t("forgotPassword")}
                </Link>
              </label>
              {/* <input
									type="password"
									className="form-control"
									id="exampleInputPassword1"
									placeholder="Password"
								/> */}
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder={t("password")}
                name="password"
                value={password}
                onChange={handleInputChange}
              />
              {errors.password && (
                <div className="error">{errors.password}</div>
              )}
            </div>
            <div className="form-footer">
              <button
                className="btn btn-primary btn-block"
                onClick={handleLogin}
              >
                {t("btn")}
              </button>
            </div>
          </div>
          <div className="text-center text-muted" style={{ fontSize: "14px" }}>
            {t("dontHaveAnAccountMsg")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
            }}
          >
            <Link
              to="/data/protection/policy"
              target="_blank"
              style={{ fontSize: "12px", fontWeight: 500 }}
            >
              Datenschutzrichtlinie
            </Link>
            <Link
              to="/terms/conditions"
              target="_blank"
              style={{ fontSize: "12px", fontWeight: 500 }}
            >
              Geschäftsbedingungen
            </Link>
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active items-center">
              <img
                src="assets/images/slider10.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemOnetitle")}</h4>
                <p>{t("itemOnedescription")}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider11.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemTwotitle")}</h4>
                <p>{t("itemTwodescription")}</p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="assets/images/slider9.png"
                className="img-fluid"
                alt="login page"
                style={{ margin: "auto" }}
              />
              <div className="px-4 mt-4">
                <h4>{t("itemThreetitle")}</h4>
                <p>{t("itemThreedescription")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
