import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import Barchart from "../../common/barchart";
import { getAgentDashboard } from "../../../services/companyService";
import NoData from "../../Utility/NoData";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const Dashboard = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const [profileComplete, setProfileComplete] = useState(true);
  const [companyStatistics, setCompanyStatistics] = useState({});
  const [companySummary, setCompanySummary] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [onboardingStatistics, setOnboardingStatistics] = useState([]);

  useEffect(() => {
    if (localStorage.role === "ROLE_ADMIN") {
      history.push("/manager/dashboard");
    }
    const profileCompleteString = localStorage.profileComplete;
    if (profileCompleteString) {
      const profileComplete = JSON.parse(profileCompleteString);
      setProfileComplete(profileComplete);
    }
    fetchAgentDashboard();
  }, []);

  const fetchAgentDashboard = async () => {
    try {
      const response = await getAgentDashboard(localStorage.token);
      if (response) {
        setCompanyStatistics(response.companyStatistics);
        setCompanySummary(response.companySummary);
        setPaymentHistory(response.paymentHistory);
        setOnboardingStatistics(response.onboardingStatisticsList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOnboardingStatus = (status) => {
    if (status === "COMPLETED") return t("completed");
    if (status === "STARTED") return t("started");
    return t("initiated");
  };
  const locale = {
    en: "en-US",
    de: "de-DE",
  };

  const formatDateGerman = (date) => {
    if (date === undefined) return "";
    const dateObject = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat(locale[i18n.language], options).format(
      dateObject
    );
  };

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-8">
                <div
                  className={`section-body ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}
                >
                  <h4>
                    {t("welcome")} {localStorage.userName}!
                  </h4>
                  <small className="font-14">{t("dashboardMsg")}</small>
                </div>
              </div>
            </div>
            {!profileComplete && (
              <div className="card-alert alert alert-warning mb-2">
                {t("dashboardWarningMsg")}
              </div>
            )}
            <div className="row clearfix">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link
                      to="/agent/details"
                      className="my_sort_cut text-muted"
                    >
                      <i
                        className="icon-user"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>Profil</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link
                      to="/account/settings"
                      className="my_sort_cut text-muted"
                    >
                      <i
                        className="icon-list"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>Krankenkasse</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link to="/companies" className="my_sort_cut text-muted">
                      <i
                        className="icon-home"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>{t("companies")}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link to="/employee" className="my_sort_cut text-muted">
                      <i
                        className="icon-users"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>{t("employees")}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="card">
                  <div className="card-body">
                    <Link to="/accounts" className="my_sort_cut text-muted">
                      <i
                        className="icon-calculator"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>{t("accounting")}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="card">
                  <div className="card-body">
                    <Link to="/email/center" className="my_sort_cut text-muted">
                      <i
                        className="icon-envelope"
                        style={{ color: "#5A5279", fontWeight: 600 }}
                      />
                      <span>Emails</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix row-deck">
              <div className="col-xl-4 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {t("companyStatistics")}
                    </h3>
                  </div>
                  <div className="card-body">
                    {companyStatistics &&
                      Object.keys(companyStatistics).length !== 0 && (
                        <div className="row text-center">
                          <div className="col-sm-4 border-right pb-4 pt-4">
                            <h4 className="font-30 font-weight-bold text-col-blue counter">
                              <CountUp end={companyStatistics.totalEmployees} />
                            </h4>
                            <label
                              className="mt-2"
                              style={{ fontSize: "14px" }}
                            >
                              {t("totalEmployees")}
                            </label>
                          </div>
                          <div className="col-sm-4 border-right pb-4 pt-4">
                            <h4 className="font-30 font-weight-bold text-col-blue counter">
                              <CountUp end={companyStatistics.emailPending} />
                            </h4>
                            <label
                              className="mt-2"
                              style={{ fontSize: "14px" }}
                            >
                              {t("emailPending")}
                            </label>
                          </div>
                          <div className="col-sm-4 pb-4 pt-4">
                            <h4 className="font-30 font-weight-bold text-col-blue counter">
                              <CountUp end={companyStatistics.emailSent} />
                            </h4>
                            <label
                              className="mt-2"
                              style={{ fontSize: "14px" }}
                            >
                              {t("emailSent")}
                            </label>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="table-responsive">
                    <table className="table table-vcenter mb-0">
                      {/* <tbody>
                        {companyStatistics &&
                          companyStatistics.paymentPercentages &&
                          Object.entries(
                            companyStatistics.paymentPercentages
                          ).map(([companyName, percentage]) => (
                            <tr key={companyName}>
                              <td>
                                <div className="clearfix">
                                  <div className="float-left">
                                    <strong>{percentage}%</strong>
                                  </div>
                                  <div className="float-right">
                                    <small className="text-muted">
                                      {companyName}
                                    </small>
                                  </div>
                                </div>
                                <div className="progress progress-xs">
                                  <div
                                    className="progress-bar bg-#32658"
                                    role="progressbar"
                                    style={{ width: `${percentage}%` }}
                                    aria-valuenow={percentage}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody> */}
                      <tbody>
                        <tr>
                          <td>
                            <div className="clearfix">
                              <div className="float-left">
                                <strong>
                                  {companyStatistics?.emailSentPercentage}%
                                </strong>
                              </div>
                              <div className="float-right">
                                <small className="text-muted">
                                  {t("yesterday")}
                                </small>
                              </div>
                            </div>
                            <div className="progress progress-xs">
                              <div
                                className="progress-bar bg-gray"
                                role="progressbar"
                                style={{
                                  width: `${companyStatistics?.emailSentPercentage}%`,
                                }}
                                aria-valuenow={42}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <span className="text-uppercase font-10">
                              {t("emailSent")}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="clearfix">
                              <div className="float-left">
                                <strong>
                                  {companyStatistics?.emailPendingPercentage}%
                                </strong>
                              </div>
                              <div className="float-right">
                                <small className="text-muted">
                                  {t("yesterday")}
                                </small>
                              </div>
                            </div>
                            <div className="progress progress-xs">
                              <div
                                className="progress-bar bg-gray"
                                role="progressbar"
                                style={{
                                  width: `${companyStatistics?.emailPendingPercentage}%`,
                                }}
                                aria-valuenow={42}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <span className="text-uppercase font-10">
                              {t("emailPending")}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="clearfix">
                              <div className="float-left">
                                <strong>
                                  {
                                    companyStatistics?.documentsUploadedPercentage
                                  }
                                  %
                                </strong>
                              </div>
                              <div className="float-right">
                                <small className="text-muted">
                                  {t("yesterday")}
                                </small>
                              </div>
                            </div>
                            <div className="progress progress-xs">
                              <div
                                className="progress-bar bg-gray"
                                role="progressbar"
                                style={{
                                  width: `${companyStatistics?.documentsUploadedPercentage}%`,
                                }}
                                aria-valuenow={42}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <span className="text-uppercase font-10">
                              {t("documentsUploaded")}
                            </span>
                          </td>
                        </tr>
                        {/* <tr>
                          <td>
                            <div className="clearfix">
                              <div className="float-left">
                                <strong>
                                  {companyStatistics?.formSubmittedPercentage}%
                                </strong>
                              </div>
                              <div className="float-right">
                                <small className="text-muted">
                                  {t("yesterday")}
                                </small>
                              </div>
                            </div>
                            <div className="progress progress-xs">
                              <div
                                className="progress-bar bg-gray"
                                role="progressbar"
                                style={{
                                  width: `${companyStatistics?.formSubmittedPercentage}%`,
                                }}
                                aria-valuenow={42}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <span className="text-uppercase font-10">
                              {t("formEinreichented")}
                            </span>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {/* {t("currentOnboardingStatus")} */}Hinzugefügte
                      Mitarbeiter
                    </h3>
                  </div>
                  <div className="card-body">
                    {onboardingStatistics.length > 0 && (
                      <Barchart data={onboardingStatistics}></Barchart>
                    )}
                  </div>
                  {/* <div className="card-footer">
                    <div className="row">
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.emailSentPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.emailSentPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          Email Sent
                        </span>
                      </div>
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.emailPendingPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.emailPendingPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("emailPending")}
                        </span>
                      </div>
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.documentsUploadedPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.documentsUploadedPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("documentsUploaded")}
                        </span>
                      </div>
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>
                              {companyStatistics?.formSubmittedPercentage}%
                            </strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{
                              width: `${companyStatistics?.formSubmittedPercentage}%`,
                            }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("formEinreichented")}
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {t("summary")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{t("companyName")}</th>
                            <th>{t("employees")}</th>
                            <th>{t("insuranceProvider")}</th>
                            <th>Onboarding Status</th>
                            <th>{t("payment")} Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {companySummary &&
                            companySummary.map((summary, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <NavLink
                                    to={"/company/info?id=" + summary.companyId}
                                    target="_blank"
                                    style={{ fontWeight: 550 }}
                                  >
                                    {/* <h6
                                      className="mb-0"
                                      style={{ whiteSpace: "nowrap" }}
                                    > */}
                                    {summary.companyName}
                                    {/* </h6> */}
                                  </NavLink>
                                </td>
                                <td>
                                  <ul className="list-unstyled team-info sm margin-0 w150">
                                    {summary.employees > 4 ? (
                                      <>
                                        {[...Array(4)].map((_, index) => (
                                          <li key={index}>
                                            <img
                                              src={`/assets/images/xs/avatar${
                                                index + 1
                                              }.jpg`}
                                              alt={`Avatar ${index + 1}`}
                                            />
                                          </li>
                                        ))}
                                        <li className="ml-2">
                                          <span>{summary.employees - 4}+</span>
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        {[...Array(summary.employees)].map(
                                          (_, index) => (
                                            <li key={index}>
                                              <img
                                                src={`/assets/images/xs/avatar${
                                                  index + 1
                                                }.jpg`}
                                                alt={`Avatar ${index + 1}`}
                                              />
                                            </li>
                                          )
                                        )}
                                      </>
                                    )}
                                  </ul>
                                </td>
                                <td>{summary.providerName}</td>
                                <td>
                                  {summary.status === "INITIATED" && (
                                    <span className="tag tag-danger">
                                      {getOnboardingStatus(summary.status)}
                                    </span>
                                  )}
                                  {summary.status !== "INITIATED" && (
                                    <span className="tag tag-success">
                                      {getOnboardingStatus(summary.status)}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {summary.paymentDone && (
                                    <span className="tag tag-success">
                                      {t("recieved")}
                                    </span>
                                  )}
                                  {!summary.paymentDone && (
                                    <span className="tag tag-danger">
                                      {t("pending")}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {t("txnHistory")}
                    </h3>
                    <div className="card-options">
                      <div className="item-action dropdown ml-2">
                        <a
                          href="#"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-vertical" />
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          x-placement="bottom-end"
                          style={{
                            position: "absolute",
                            transform: "translate3d(-174px, 25px, 0px)",
                            top: 0,
                            left: 0,
                            willChange: "transform",
                          }}
                        >
                          <a
                            href="/accounts"
                            target="_blank"
                            className="dropdown-item"
                          >
                            <i className="dropdown-icon fa fa-eye" />
                            {t("viewAll")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table card-table mt-2">
                    <tbody>
                      {paymentHistory &&
                        paymentHistory.map((row, index) => (
                          <tr>
                            <td
                              className="width45 mt-2"
                              style={{ padding: "4px" }}
                            >
                              {row.paymentDone && (
                                <span
                                  className="avatar avatar-green  ml-1"
                                  style={{ backgroundColor: "#e6edbd" }}
                                >
                                  <i
                                    className="fa fa-check"
                                    style={{
                                      color: "#8e9e37",
                                      fontSize: "150%",
                                    }}
                                  />
                                </span>
                              )}
                              {!row.paymentDone && (
                                <span
                                  className="avatar avatar-green  ml-1"
                                  style={{ backgroundColor: "#f9d2a7" }}
                                >
                                  <i
                                    className="fa fa-clock-o"
                                    style={{
                                      color: "#e58927",
                                      fontSize: "150%",
                                    }}
                                  />
                                </span>
                              )}
                            </td>
                            <td className="mt-2" style={{ padding: "4px" }}>
                              <p className="font-16 mb-1">{row.employeeName}</p>
                              <span className="text-muted font-13 mb-1">
                                {row.paymentDone && (
                                  <>
                                    {t("matchedOn")} -{" "}
                                    {formatDateGerman(row.matchedOn)}
                                  </>
                                )}
                                {!row.paymentDone &&
                                  row.billingRefNum &&
                                  row.billingRefEmailSentOn && (
                                    <>{t("matchPending")}</>
                                  )}
                                {!row.paymentDone && !row.billingRefNum && (
                                  <>
                                    {t("formSent")} -{" "}
                                    {formatDateGerman(row.mailSentOn)}
                                  </>
                                )}
                              </span>
                            </td>
                            <td
                              className="text-right"
                              style={{ padding: "4px" }}
                            >
                              <p className="font-16 mb-1">
                                {row.billingRefNum}
                                {!row.billingRefNum && <>{t("refPending")}</>}
                              </p>
                              <span className="text-success font-13 mb-1">
                                {row.paymentDone && (
                                  <>
                                    {t("received")}-
                                    {formatDateGerman(row.billingRefNumDate)}
                                  </>
                                )}
                                {!row.paymentDone && row.billingRefNum && (
                                  <>
                                    {t("received")}-
                                    {formatDateGerman(row.billingRefNumDate)}
                                  </>
                                )}
                                {!row.paymentDone &&
                                  !row.billingRefNum &&
                                  !row.billingRefEmailSentOn && (
                                    <>{t("refMailNotSent")}</>
                                  )}
                                {!row.paymentDone &&
                                  !row.billingRefNum &&
                                  row.billingRefEmailSentOn && (
                                    <>
                                      {t("mailedOn")}-
                                      {formatDateGerman(
                                        row.billingRefEmailSentOn
                                      )}
                                    </>
                                  )}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {paymentHistory.length == 0 && (
                    <NoData
                      messageHeading={t("noAccountingData")}
                      messageSubHeading={""}
                      messageLink={"/"}
                      noClick={true}
                    />
                  )}
                  <hr className="mb-0 mt-0" />
                  <div className="text-right">
                    <a
                      href="/accounts"
                      target="_blank"
                      className="dropdown-item font-13"
                      style={{ fontWeight: 500 }}
                    >
                      {t("viewAll")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
