import React from 'react';
import Select from 'react-select';

const MultiSelect = ({selectOptions,setValues,index,selectedValues,disabled}) => {

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#4A90E2' : provided.borderColor,
      boxShadow: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0,
      marginTop: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#4A90E2' : provided.backgroundColor,
      color: state.isSelected ? 'white' : provided.color,
      ':hover': {
        backgroundColor: state.isSelected ? '#4A90E2' : '#E6F7FF',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4A90E2',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: '#1E90FF',
      },
    }),
  };

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setValues(selectedValues,index);
  };

  const mapToOptions = (values) => {
    if (!values) {
      return [];
    }  
    return values.map((value) => {
      const correspondingOption = selectOptions.find((option) => option.value === value);
      return correspondingOption || { value, label: value };
    });
  };

  return (
    <Select
      isMulti
      options={selectOptions}
      styles={customStyles}
      placeholder="Select..."
      onChange={handleChange}
      value={mapToOptions(selectedValues)} 
      isDisabled={disabled}
    />
  );
};

export default MultiSelect;
