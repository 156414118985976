import { FileUploadOutlined } from '@mui/icons-material';
import { Check } from 'lucide-react';
import React from 'react';

const FileIcon = ({ status, onClick }) => (
  <span onClick={onClick} style={{ cursor: 'pointer', color: status === 'UPLOADED' ? 'green' : 'black' }}>
    {status === 'PENDING' ? <FileUploadOutlined/> : <Check/>}
  </span>
);

export default FileIcon;
