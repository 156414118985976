import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import MultiSelect from "../../../Utility/MultiSelect";
import Select from "react-select";
import SignaturePad from "../../../Utility/SignaturePad";
import {
  generateFamilyInsuranceForm,
  getFamilyInsuranceForm,
  submitFamilyInsuranceFormApi,
} from "../../../../services/insuranceFormService";
import { toast } from "react-toastify";
import Loading from "../../../Utility/Loading";
import { useTranslation } from "react-i18next";
import { Trash2 } from "lucide-react";

const FamilyInsuranceForm = ({
  employeeId,
  employeeLastName,
  employeeFirstName,
  employeeBirthDate,
}) => {
  const initialRow = {
    firstName: "",
    lastName: "",
    gender: null,
    birthDate: "",
    childrenType: null,
    familyMemberType: null,
    prevInsuranceEndDate: "",
    prevInsuranceName: "",
    prevInsuranceType: [],
    familyInsuranceFirstName: "",
    familyInsuranceLastName: "",
    prevInsuranceRemainsEffectName: "",
    selfEmployedActivity: false,
    selfEmploymentMonthlyIncome: "",
    monthlyGrossIncome: "",
    otherMonthlyIncome: "",
    typeOfIncome: "",
    monthlyPensionPaymentAmount: "",
    schoolAttendanceFromDate: "",
    schoolAttendanceToDate: "",
    militaryServiceFromDate: "",
    militaryServiceToDate: "",
    memberPensionInsuranceNumber: "",
    memberBirthName: "",
    memberBirthPlace: "",
    memberNationality: "",
    biologicalParent: true,
  };
  const [rows, setRows] = useState([initialRow]);
  const [marritalStatus, setMarritalStatus] = useState("");
  const [signUrl, setSignUrl] = useState("");
  const [familyMemberSignUrl, setFamilyMemberSignUrl] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const [signDate, setSignDate] = useState(currentDate);
  const [signaturePadOpen, setSignaturePadOpen] = useState(false);
  const [familySignOpen, setFamilySignOpen] = useState(false);
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submittedDateTime, setSubmittedDateTime] = useState("");
  const { t, i18n } = useTranslation("employee");
  const [hasErrors, setHasErrors] = useState(false);
  const [noMember, setNoMember] = useState(false);

  const [activeTab, setActiveTab] = useState("tab1_0");
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const initialErrorState = {
    marritalStatus: "",
    signUrl: "",
    signDate: "",
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    childrenType: "",
    familyMemberType: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  const invalidFeedbackCardStyle = {
    display: hasErrors ? "block" : "none",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "100%",
    color: "#dc3545",
  };

  const invalidMemberCardStyle = {
    display: noMember ? "block" : "none",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "100%",
    color: "#dc3545",
  };

  useEffect(() => {
    getViactivFamilyInsuranceForm();
  }, []);

  useEffect(() => {}, [applicationSubmitted]);

  const getViactivFamilyInsuranceForm = async () => {
    try {
      const response = await getFamilyInsuranceForm(
        localStorage.token,
        employeeId
      );
      if (response) {
        setFamilyInsuranceForm(response);
        setApplicationSubmitted(true);
        setDisabled(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setFamilyInsuranceForm = (data) => {
    setSubmittedDateTime(data.submittedTime);
    setRows(data.members);
    setSignUrl(data.signUrl);
    setFamilyMemberSignUrl(data.familyMemberSignUrl);
    setSignDate(data.signDate);
    setMarritalStatus(data.maritalStatus);
  };

  const handleAddRow = () => {
    setRows([...rows, { ...initialRow }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    if (field === "kindOfInsurance" && value) {
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value.value,
      };
    } else if (field === "privateSelect") {
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
    } else {
      updatedRows[index] = { ...updatedRows[index], [field]: value };
    }
    setRows(updatedRows);
  };

  const validateForm = (formData) => {
    const newErrors = {};
    if (!formData.maritalStatus || formData.maritalStatus.trim == "") {
      newErrors.marritalStatus = t("required");
    }
    if (!formData.signUrl || formData.signUrl.trim() === "") {
      newErrors.signUrl = t("required");
    }
    if (!formData.signDate || formData.signDate.trim() === "") {
      newErrors.signDate = t("required");
    }
    if (formData.members && formData.members.length == 0) {
      setNoMember(true);
    } else {
      setNoMember(false);
    }
    if (formData.members.length > 0) {
      rows.forEach((row, rowIndex) => {
        if (!row.firstName || row.firstName.trim() === "") {
          newErrors.firstName = t("required");
        }
        if (!row.lastName || row.lastName.trim() === "") {
          newErrors.lastName = t("required");
        }
        if (!row.gender || row.gender.trim() === "") {
          newErrors.gender = t("required");
        }
        if (!row.birthDate || row.birthDate.trim() === "") {
          newErrors.birthDate = t("required");
        }
        if (!row.familyMemberType || row.familyMemberType.trim() === "") {
          newErrors.familyMemberType = t("required");
        }
        if (row.familyMemberType && row.familyMemberType === "CHILDREN") {
          if (!row.childrenType || row.childrenType.trim() === "") {
            newErrors.childrenType = t("required");
          }
        }
      });
    }
    setErrors(newErrors);
    setHasErrors(Object.keys(newErrors).length === 0 ? false : true);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    const formData = {
      members: rows,
      maritalStatus: marritalStatus,
      employeeLastName: employeeLastName,
      employeeFirstName: employeeFirstName,
      employeeBirthDate: employeeBirthDate,
      signUrl: signUrl,
      familyMemberSignUrl: familyMemberSignUrl,
      signDate: signDate,
      provider: "VIACTIVKRANKENKASSE",
      formType: "FAMILY_INSURANCE_FORM",
    };
    if (validateForm(formData) && !noMember) {
      setDisabled(true);
      setIsLoading(true);
      setEditMode(false);
      submitFamilyInsuranceForm(formData);
    }
  };

  const submitFamilyInsuranceForm = async (formData) => {
    try {
      const response = await submitFamilyInsuranceFormApi(
        localStorage.token,
        employeeId,
        formData
      );
      if (response) {
        toast.success(t("formSubmittedSuccessfully"));
        window.location.reload();
      } else {
        toast.error(t("formSubmissionFailed"));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const MARRITAL_STATUS_OPTIONS = [
    { value: "SINGLE", label: "Ledig" },
    { value: "MARRIED", label: "Verheiratet" },
    { value: "LIVING_SEPARATELY", label: "Getrennt Lebend" },
    { value: "DIVORCED", label: "Geschieden" },
    { value: "WIDOWED", label: "Verwitwet" },
    {
      value: "CIVIL_PARTNERSHIP",
      label: "Eingetragene Lebenspartnerschaft nach dem LPartG",
    },
  ];

  const GENDER_OPTIONS = [
    { value: "MALE", label: "Männlich" },
    { value: "FEMALE", label: "Weiblich" },
    { value: "OTHER", label: "Divers" },
    { value: "INDEFINITE", label: "Unbestimmt" },
  ];

  const CHILDREN_TYPE_OPTIONS = [
    { value: "BIOLOGICAL_CHILD", label: "Leibliches Kind" },
    { value: "STEP_CHILD", label: "Stiefkind" },
    { value: "GRAND_CHILD", label: "Enkelkind" },
    { value: "FOSTER_CHILD", label: "Pflegekind" },
  ];

  const MEMBER_TYPE_OPTIONS = [
    { value: "PARTNER", label: "Ehepartner/-in" },
    { value: "CHILDREN", label: "Kind" },
  ];

  const PREV_INS_TYPE_OPTIONS = [
    { value: "MEMBERSHIP", label: "Mitgliedschaft" },
    { value: "FAMILY_INSURANCE", label: "Familienversichert" },
    { value: "NOT_BY_LAW", label: "Nicht Gesetzlich" },
  ];

  const setPrevInsuranceTypeValues = (values, index) => {
    handleRowChange(index, "prevInsuranceType", values);
  };

  const closeSignaturePad = () => {
    setSignaturePadOpen(false);
    setFamilySignOpen(false);
  };

  const setSignUrlFromPad = (url) => {
    setSignUrl(url);
  };

  const setFamilySignUrlFromPad = (url) => {
    setFamilyMemberSignUrl(url);
  };

  const handleEdit = () => {
    setEditMode(true);
    setDisabled(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setDisabled(true);
  };

  const generateForm = async () => {
    try {
      const response = await generateFamilyInsuranceForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = "Formular_zur_Familienversicherung.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.log(error);
    }
  };

  function formatDateOnlyGerman(dateString) {
    const date = new Date(dateString);
    const germanDate = new Intl.DateTimeFormat("de-DE", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);

    return germanDate;
  }

  return (
    <div className="card">
      <div className="card-body p-2">
        {applicationSubmitted && (
          <div className="card-alert alert alert-success mb-0">
            {t("applicationSubmittedOn")} &nbsp;
            {formatDateOnlyGerman(submittedDateTime)}.&nbsp;&nbsp;
            <button className="btn btn-info" onClick={generateForm}>
              {t("viewFromPDF")}
            </button>
          </div>
        )}
        <div style={invalidFeedbackCardStyle}>
          <div className="card-alert alert alert-danger mb-0 mt-4">
            {t("familyFormError")}
          </div>
        </div>
        <div style={invalidMemberCardStyle}>
          <div className="card-alert alert alert-danger mb-0 mt-4">
            {t("familyMemberError")}
          </div>
        </div>
        <div
          className="row ml-2 mt-4 mb-4 p-2"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="col-md-3 col-sm-12">
            <div className="form-group" style={{ marginTop: "5px" }}>
              <label className="form-label" style={{ color: "#282826" }}>
                {t("maritalStatus")}
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>
              <Select
                options={MARRITAL_STATUS_OPTIONS}
                value={MARRITAL_STATUS_OPTIONS.find(
                  (option) => option.value === marritalStatus
                )}
                onChange={(e) => setMarritalStatus(e.value)}
                placeholder={t("select")}
                isDisabled={disabled}
              />
              {errors.marritalStatus && (
                <div style={invalidFeedbackStyle}>{errors.marritalStatus}</div>
              )}
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="form-group" style={{ marginTop: "5px" }}>
              <label className="form-label" style={{ color: "#282826" }}>
                Name
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                name="employeeLastName"
                value={employeeLastName}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="form-group" style={{ marginTop: "5px" }}>
              <label className="form-label" style={{ color: "#282826" }}>
                Vorname
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                name="employeeFirstName"
                value={employeeFirstName}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {rows.map((row, index) => (
          <>
            <div className="card-header d-flex justify-between m-0 p-0 ml-1 mb-1 mt-4">
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className={`btn ${
                    activeTab === "tab1_" + index
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                  style={{
                    border:
                      activeTab === "tab1_" + index ? "3px solid #00a67d" : "",
                  }}
                  id="general-info-tab"
                  data-toggle="tab"
                  href={"#general-info-" + index}
                  onClick={() => handleTabClick("tab1_" + index)}
                >
                  BASISINFO
                </button>
                <button
                  type="button"
                  className={`btn ${
                    activeTab === "tab2_" + index
                      ? "btn-primary"
                      : "btn-secondary"
                  } ml-2`}
                  style={{
                    border:
                      activeTab === "tab2_" + index ? "3px solid #00a67d" : "",
                  }}
                  id="insurance-status-tab"
                  data-toggle="tab"
                  href={"#insurance-status-" + index}
                  onClick={() => handleTabClick("tab2_" + index)}
                >
                  VERS.-STATUS
                </button>
                <button
                  type="button"
                  className={`btn ${
                    activeTab === "tab3_" + index
                      ? "btn-primary"
                      : "btn-secondary"
                  } ml-2`}
                  style={{
                    border:
                      activeTab === "tab3_" + index ? "3px solid #00a67d" : "",
                  }}
                  id="income-tab"
                  data-toggle="tab"
                  href={"#income-" + index}
                  onClick={() => handleTabClick("tab3_" + index)}
                >
                  EINKOMMEN
                </button>
                <button
                  type="button"
                  className={`btn ${
                    activeTab === "tab4_" + index
                      ? "btn-primary"
                      : "btn-secondary"
                  } ml-2`}
                  style={{
                    border:
                      activeTab === "tab4_" + index ? "3px solid #00a67d" : "",
                  }}
                  id="assign-health-number-tab"
                  data-toggle="tab"
                  href={"#assign-health-number-" + index}
                  onClick={() => handleTabClick("tab4_" + index)}
                >
                  K. VERS. NUMMER
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-icon js-sweetalert"
                  title="Delete"
                  data-type="confirm"
                  onClick={() => handleRemoveRow(index)}
                  style={{ backgroundColor: "#f8d7db" }}
                >
                  <Trash2
                    size={20}
                    style={{
                      color: "red",
                      fontWeight: "600",
                      fontFamily: "20px",
                    }}
                  />
                </button>
              </div>
            </div>
            <hr className="m-0 p-0" />
            <div
              className="tab-content p-2 ml-1"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div
                className="tab-pane fade show active"
                id={"general-info-" + index}
                role="tabpanel"
              >
                <div className="row ml-2 mt-4 mb-2">
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        {t("familyMemberType")}
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1px",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <Select
                        options={MEMBER_TYPE_OPTIONS}
                        value={MEMBER_TYPE_OPTIONS.find(
                          (option) => option.value === row.familyMemberType
                        )}
                        onChange={(e) =>
                          handleRowChange(index, "familyMemberType", e.value)
                        }
                        placeholder={t("select")}
                        isDisabled={disabled}
                      />
                      {(!row.familyMemberType ||
                        row.familyMemberType.trim() == "") && (
                        <div style={invalidFeedbackStyle}>
                          {errors.familyMemberType}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Vorname
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1px",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="fisrtName"
                        value={row.firstName}
                        onChange={(e) =>
                          handleRowChange(index, "firstName", e.target.value)
                        }
                        placeholder="Enter First Name"
                        required
                        disabled={disabled}
                      />
                      {(!row.firstName || row.firstName.trim() === "") && (
                        <div style={invalidFeedbackStyle}>
                          {errors.firstName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Name
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1px",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="fisrtName"
                        value={row.lastName}
                        onChange={(e) =>
                          handleRowChange(index, "lastName", e.target.value)
                        }
                        placeholder="Enter Last Name"
                        required
                        disabled={disabled}
                      />
                      {(!row.lastName || row.lastName.trim() === "") && (
                        <div style={invalidFeedbackStyle}>
                          {errors.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        {t("gender")}
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1px",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <Select
                          options={GENDER_OPTIONS}
                          value={GENDER_OPTIONS.find(
                            (option) => option.value === row.gender
                          )}
                          onChange={(e) =>
                            handleRowChange(index, "gender", e.value)
                          }
                          placeholder={t("select")}
                          isDisabled={disabled}
                        />
                        {(!row.gender || row.gender.trim() === "") && (
                          <div style={invalidFeedbackStyle}>
                            {errors.gender}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        {t("birthDate")}
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1px",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="birthDate"
                        value={row.birthDate}
                        onChange={(e) =>
                          handleRowChange(index, "birthDate", e.target.value)
                        }
                        placeholder="Enter Birth Date"
                        required
                        disabled={disabled}
                      />
                      {(!row.birthDate || row.birthDate.trim() === "") && (
                        <div style={invalidFeedbackStyle}>
                          {errors.birthDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {row.familyMemberType === "CHILDREN" && (
                  <div className="row ml-2 mb-4">
                    <div className="col-md-3 col-sm-12">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#282826" }}
                        >
                          {t("childrenType")}
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          options={CHILDREN_TYPE_OPTIONS}
                          value={CHILDREN_TYPE_OPTIONS.find(
                            (option) => option.value === row.childrenType
                          )}
                          onChange={(e) =>
                            handleRowChange(index, "childrenType", e.value)
                          }
                          placeholder={t("select")}
                          isDisabled={disabled}
                        />
                        {(!row.childrenType ||
                          row.childrenType.trim() === "") && (
                          <div style={invalidFeedbackStyle}>
                            {errors.childrenType}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12 ml-4">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#282826" }}
                        >
                          Mein/e Ehe- oder Lebenspartner/-in ist der leibliche
                          Elternteil des Kindes:
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div className="d-flex mt-2">
                          <div className="form-group">
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={row.biologicalParent}
                                onChange={(e) =>
                                  handleRowChange(
                                    index,
                                    "biologicalParent",
                                    true
                                  )
                                }
                                disabled={disabled}
                              />
                              <span
                                className="custom-control-label"
                                style={{
                                  color: "#282826",
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                Ja
                              </span>
                            </label>
                          </div>
                          <div className="form-group ml-24">
                            <label className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={!row.biologicalParent}
                                disabled={disabled}
                                onChange={(e) =>
                                  handleRowChange(
                                    index,
                                    "biologicalParent",
                                    false
                                  )
                                }
                              />
                              <span
                                className="custom-control-label"
                                style={{
                                  color: "#282826",
                                  fontSize: "15px",
                                  fontWeight: 400,
                                }}
                              >
                                Nein
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id={"insurance-status-" + index}
                role="tabpanel"
              >
                <div className="row ml-2 mt-4">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Die bisherige Versicherung endete am:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="prevInsuranceEndDate"
                        value={row.prevInsuranceEndDate}
                        disabled={disabled}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "prevInsuranceEndDate",
                            e.target.value
                          )
                        }
                        placeholder="Enter Previous insurance End Date"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Bestand Bei (Name der Krankenkasse):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="prevInsuranceName"
                        value={row.prevInsuranceName}
                        disabled={disabled}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "prevInsuranceName",
                            e.target.value
                          )
                        }
                        placeholder="Enter Previous insurance Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Art der bisherigen Versicherung
                      </label>
                      <MultiSelect
                        selectOptions={PREV_INS_TYPE_OPTIONS}
                        setValues={setPrevInsuranceTypeValues}
                        index={index}
                        selectedValues={row.prevInsuranceType}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ml-2">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label
                        className="form-label"
                        style={{ color: "#282826", marginTop: "5px" }}
                      >
                        Die bisherige Versicherung besteht weiter bei:(Name der
                        Krankenkasse)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="prevInsuranceRemainsEffectName"
                        value={row.prevInsuranceRemainsEffectName}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "prevInsuranceRemainsEffectName",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Vorname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="familyInsuranceFirstName"
                        value={row.familyInsuranceFirstName}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "familyInsuranceFirstName",
                            e.target.value
                          )
                        }
                        placeholder="Enter First Name"
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="familyInsuranceLastName"
                        value={row.familyInsuranceLastName}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "familyInsuranceLastName",
                            e.target.value
                          )
                        }
                        placeholder="Enter Last Name"
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id={"income-" + index}
                role="tabpanel"
              >
                <div className="row ml-2 mt-4">
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Selbstständige Tätigkeit liegt vor:
                      </label>
                      <div className="d-flex mt-2">
                        <div className="form-group">
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={row.selfEmployedActivity}
                              disabled={disabled}
                              onChange={(e) =>
                                handleRowChange(
                                  index,
                                  "selfEmployedActivity",
                                  true
                                )
                              }
                            />
                            <span
                              className="custom-control-label"
                              style={{
                                color: "#282826",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              Ja
                            </span>
                          </label>
                        </div>
                        <div className="form-group ml-24">
                          <label className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              disabled={disabled}
                              checked={!row.selfEmployedActivity}
                              onChange={(e) =>
                                handleRowChange(
                                  index,
                                  "selfEmployedActivity",
                                  false
                                )
                              }
                            />
                            <span
                              className="custom-control-label"
                              style={{
                                color: "#282826",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              Nein
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Monatl. Gewinn aus selbstständiger Tätigkeit (bitte
                        Nachweise beilegen)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="selfEmploymentMonthlyIncome"
                        value={row.selfEmploymentMonthlyIncome}
                        disabled={disabled}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "selfEmploymentMonthlyIncome",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Monatl. Bruttoarbeitsentgelt aus geringfügiger
                        Beschäftigung (bitte Nachweise beilegen):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="monthlyGrossIncome"
                        value={row.monthlyGrossIncome}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "monthlyGrossIncome",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="row ml-2">
                  <div className="col-md-7 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Monatl. Einkünfte aus Kapitalvermögen, Abfindungen,
                        Vermietung/Verpachtung, sonstige regelmäßige Einkünfte
                        (bitte Nachweise beilegen):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="otherMonthlyIncome"
                        value={row.otherMonthlyIncome}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "otherMonthlyIncome",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Monatl. Zahlbetrag der gesetzlichen Rente,
                        Versorgungsbezüge, sonstige Rente (bitte Nachweise
                        beilegen):
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="monthlyPensionPaymentAmount"
                        value={row.monthlyPensionPaymentAmount}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "monthlyPensionPaymentAmount",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>

                <div className="row ml-2">
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Art der Einkünfte
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="typeOfIncome"
                        value={row.typeOfIncome}
                        onChange={(e) =>
                          handleRowChange(index, "typeOfIncome", e.target.value)
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                {row.familyMemberType === "CHILDREN" && (
                  <div className="row ml-2">
                    <div className="col-md-4 col-sm-12 mt-4">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#282826" }}
                        >
                          Schulbesuch/Studium (bitte bei Kindern ab 23 Jahren
                          Schulbescheinigung beilegen):
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-12">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#282826" }}
                        >
                          Vom
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="schoolAttendanceFromDate"
                          value={row.schoolAttendanceFromDate}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              "schoolAttendanceFromDate",
                              e.target.value
                            )
                          }
                          placeholder={t("enterHere")}
                          required
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-12">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#282826" }}
                        >
                          Bis
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="schoolAttendanceToDate"
                          value={row.schoolAttendanceToDate}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              "schoolAttendanceToDate",
                              e.target.value
                            )
                          }
                          placeholder={t("enterHere")}
                          required
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row ml-2">
                  <div className="col-md-5 col-sm-12 mt-4">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Wehrdienst oder gesetzlich geregelter Freiwilligendienst
                        (bitte Dienstzeitbescheinigung beilegen, sofern noch
                        nicht erfolgt):
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Vom
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="militaryServiceFromDate"
                        value={row.militaryServiceFromDate}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "militaryServiceFromDate",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Bis
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="militaryServiceToDate"
                        value={row.militaryServiceToDate}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "militaryServiceToDate",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id={"assign-health-number-" + index}
                role="tabpanel"
              >
                <div className="row ml-2 mt-4">
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Sozialversicherungsnummer Angehöriger
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="memberPensionInsuranceNumber"
                        value={row.memberPensionInsuranceNumber}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "memberPensionInsuranceNumber",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Geburtsname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="memberBirthName"
                        value={row.memberBirthName}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "memberBirthName",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Geburtsort/Geburtsland
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="memberBirthPlace"
                        value={row.memberBirthPlace}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "memberBirthPlace",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="form-group" style={{ marginTop: "5px" }}>
                      <label
                        className="form-label"
                        style={{ color: "#282826" }}
                      >
                        Staatsangehörigkeit
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="memberNationality"
                        value={row.memberNationality}
                        onChange={(e) =>
                          handleRowChange(
                            index,
                            "memberNationality",
                            e.target.value
                          )
                        }
                        placeholder={t("enterHere")}
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-muted ml-3">
                  <b>Note: &nbsp;</b>Falls noch keine Sozialversicherungsnummer
                  vergeben ist, bitte ausfüllen Geburtsname,
                  Geburtsort/Geburtsland,Staatsangehörigkeit
                </div>
              </div>
            </div>
          </>
        ))}
        <div className="flex gap-5 ml-1 mb-4 mt-4 justify-start">
          <button className="btn btn-primary" onClick={handleAddRow}>
            {t("addNewMember")}
          </button>
        </div>
        <hr className="m-0 p-0" />
        <div style={{ backgroundColor: "whitesmoke" }}>
          <div className="row ml-2 mt-4">
            <h6
              className="ml-2 mt-4 mb-2"
              style={{ fontSize: "17px", fontWeight: 600, color: "#58585B" }}
            >
              Einverständniserklärung
            </h6>
            <p className="ml-2">
              Ich bestätige die Richtigkeit der Angaben. Ich informiere Sie über
              alle wichtigen Änderungen -- zum Beispiel, wenn sich das Einkommen
              eines Familienangehörigen ändert oder eine eigene Mitgliedschaft
              beginnt.
            </p>
          </div>
          <div className="row ml-2 mt-4">
            <div className="col-md-3 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Datum
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="signDate"
                  value={signDate}
                  onChange={(e) => setSignDate(e.target.value)}
                  disabled={disabled}
                />
                {errors.signDate && (
                  <div style={invalidFeedbackStyle}>{errors.signDate}</div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group">
                <label className="form-label" style={{ color: "#282826" }}>
                  Unterschrift des Mitglieds
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <div>
                  <img src={signUrl}></img>
                  <button
                    className="btn btn-info"
                    style={{
                      height: "35px",
                      width: "100px",
                      padding: "2px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                    disabled={disabled}
                    onClick={() => setSignaturePadOpen(true)}
                  >
                    ZEICHEN
                  </button>
                  {errors.signUrl && (
                    <div style={invalidFeedbackStyle}>{errors.signUrl}</div>
                  )}
                  <br />
                  <small className="text-muted">
                    Mit der Unterschrift erkläre ich, die Zustimmung der
                    Familienangehörigen zur Abgabe der erforderlichen Daten
                    erhalten zu haben.
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group">
                <label className="form-label" style={{ color: "#282826" }}>
                  ggf. Unterschrift der Familienangehörigen
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <div>
                  <img src={familyMemberSignUrl}></img>
                  <button
                    className="btn btn-info"
                    style={{
                      height: "35px",
                      width: "100px",
                      padding: "2px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                    disabled={disabled}
                    onClick={() => setFamilySignOpen(true)}
                  >
                    ZEICHEN
                  </button>
                  <br />
                  <small className="text-muted">
                    Wenn Sie getrennt leben, reicht Ihre Unterschrift aus.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer text-right">
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={handleSubmit}
          style={{ textTransform: "uppercase" }}
        >
          {t("submit")}
        </button>
        {disabled && (
          <>
            <button
              className="btn btn-primary ml-10"
              style={{ textTransform: "uppercase" }}
              onClick={handleEdit}
            >
              {t("edit")}
            </button>
          </>
        )}
        {editMode && (
          <>
            <button
              className="btn btn-info ml-10"
              style={{ textTransform: "uppercase" }}
              onClick={handleCancel}
            >
              {t("cancel")}
            </button>
          </>
        )}
      </div>
      <Dialog
        open={signaturePadOpen}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
          <SignaturePad
            closeSignaturePad={closeSignaturePad}
            setSignUrlFromPad={setSignUrlFromPad}
          />
        </div>
      </Dialog>
      <Dialog
        open={familySignOpen}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
          <SignaturePad
            closeSignaturePad={closeSignaturePad}
            setSignUrlFromPad={setFamilySignUrlFromPad}
          />
        </div>
      </Dialog>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FamilyInsuranceForm;
