import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <>
        <div>
          <div className="section-body">
            <footer className="footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    Copyright © 2024{" "}
                    <a href="https://boardi.co/login">Boardi.co</a>
                  </div>
                  <div className="col-md-6 col-sm-12 text-md-right">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <a href="/support">Kontakt</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="/data/protection/policy">
                          Datenschutzrichtlinie
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="/terms/conditions">Geschäftsbedingungens</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="/support">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </>
    );
  }
}
