import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { useStateContext } from "../../context/Context";
import { handleChangePassword } from "../../services/apiService";
import { toast } from "react-toastify";
import GDPRConsentForm from "../Utility/GDPRConsentForm";
import { Dialog } from "@mui/material";

const Header = ({ fixNavbar, darkHeader, dataFromSubParent }) => {
  const { i18n, t } = useTranslation("menu");
  const { isLoggedIn, setLoggedIn } = useStateContext();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showConsentForm, setShowConsentForm] = useState(false);

  useEffect(() => {
    const consentAccepted = localStorage.getItem("consentAccepted");
    if (consentAccepted != undefined) {
      setShowConsentForm(!JSON.parse(consentAccepted));
    }
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userCompany");
    localStorage.removeItem("profileComplete");
    localStorage.removeItem("authorities");
    localStorage.removeItem("consentAccepted");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("companyId");
    setLoggedIn(false);
    history.push("/login");
  }

  const changePassword = async () => {
    if (!password || password.trim() === "") {
      setPasswordError("Passwort ist erforderlich. Es darf nicht leer sein.");
      return;
    }
    const request = {
      password: password,
    };
    try {
      const response = await handleChangePassword(localStorage.token, request);
      if (response) {
        toast.success("Passwort erfolgreich geändert.");
        logout();
      } else {
        toast.error("Fehler beim Ändern des Passworts.");
      }
    } catch (error) {
      console.error("Error changing password", error);
    }
  };

  return (
    <div>
      <div
        id="page_top"
        className={`section-body ${fixNavbar ? "sticky-top" : ""} ${
          darkHeader ? "top_dark" : ""
        }`}
      >
        <div className="container-fluid">
          <div className="page-header">
            <div className="left">
              <h1 className="page-title">{dataFromSubParent}</h1>
              {/* <select className="custom-select">
                <option>Year</option>
                <option>Month</option>
                <option>Week</option>
              </select>
              <div className="input-group xs-hide">
                <input type="text" className="form-control" placeholder="Search..." />
              </div> */}
            </div>
            <div className="right mr-2">
              <ul className="nav nav-pills">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t("language")}
                  </a>
                  <div className="dropdown-menu">
                    <button
                      className="dropdown-item"
                      onClick={() => changeLanguage("en")}
                      style={{ display: "flex" }}
                    >
                      <img
                        className="w20 mr-2"
                        src="https://boardi.co/assets/images/flags/us.svg"
                        alt="fake_url"
                      />
                      English
                    </button>
                    <div className="dropdown-divider" />
                    <button
                      className="dropdown-item"
                      onClick={() => changeLanguage("de")}
                      style={{ display: "flex" }}
                    >
                      <img
                        className="w20 mr-2"
                        src="https://boardi.co/assets/images/flags/de.svg"
                        alt="fake_url"
                      />
                      Deutsch
                    </button>
                  </div>
                </li>
                {/* {localStorage.role !== 'ROLE_EMPLOYEE' && <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Reports
                  </a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href='/reports' target='_blank'>
                      <i className="dropdown-icon fa fa-file-excel-o" /> Sales
                    </a>
                    <a className="dropdown-item" href='/reports' target='_blank'>
                      <i className="dropdown-icon fa fa-file-excel-o" /> Attendance
                    </a>
                    <a className="dropdown-item" href='/reports' target='_blank'>
                      <i className="dropdown-icon fa fa-file-excel-o" /> Employee Performance
                    </a>
                    <a className="dropdown-item" href='/reports' target='_blank'>
                      <i className="dropdown-icon fa fa-file-excel-o" /> Employee Payroll
                    </a>
                  </div>
                </li>} */}

                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    ariaexpanded="false"
                  >
                    Project
                  </a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item">Graphics Design</a>
                    <a className="dropdown-item">Angular Admin</a>
                    <a className="dropdown-item">PSD to HTML</a>
                    <a className="dropdown-item">iOs App Development</a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item">Home Development</a>
                    <a className="dropdown-item">New Blog post</a>
                  </div>
                </li> */}
              </ul>
              <div className="notification d-flex">
                <div className="dropdown d-flex">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-user" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    {/* <NavLink to="/profile" className="dropdown-item">
                      <i className="dropdown-icon fe fe-user" /> Profile
                    </NavLink>
                    <a className="dropdown-item">
                      <i className="dropdown-icon fe fe-settings" /> Settings
                    </a>
                    <a className="dropdown-item">
                      <span className="float-right">
                        <span className="badge badge-primary">6</span>
                      </span>
                      <i className="dropdown-icon fe fe-mail" /> Inbox
                    </a>
                    <a className="dropdown-item">
                      <i className="dropdown-icon fe fe-send" /> Message
                    </a>
                    <div className="dropdown-divider" /> */}
                    <a
                      className="dropdown-item"
                      href="/support"
                      target="_blank"
                    >
                      <i className="dropdown-icon fe fe-help-circle" />{" "}
                      {t("needHelp")}
                    </a>
                    <button
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#changePasswordModal"
                    >
                      <i className="dropdown-icon icon-refresh" />{" "}
                      {t("changePassword")}
                    </button>
                    <button onClick={logout} className="dropdown-item">
                      <i className="dropdown-icon fe fe-log-out" />{" "}
                      {t("signOut")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="changePasswordModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="changePasswordModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="changePasswordModalLabel">
                {t("changePassword")}
              </h5>
              <button
                type="button"
                id="closeConfirmModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-label">Neues Passwort</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Neues Passwort Eingeben"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {(!password || password.trim() === "") &&
                  passwordError !== "" && (
                    <div style={{ fontSize: "13px", color: "red" }}>
                      {passwordError}
                    </div>
                  )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Stornieren
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={changePassword}
              >
                Einreichen
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={showConsentForm}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <GDPRConsentForm
            setShowConsentForm={setShowConsentForm}
            changeLanguage={changeLanguage}
          />
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
  darkHeader: state.settings.isDarkHeader,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
