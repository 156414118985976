import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieWrapper = ({ animationData, width, height }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const anim = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg', // Use 'svg' or 'canvas' as needed
        loop: true, // Set to true for looped animations
        autoplay: true, // Set to true to autoplay the animation
        animationData, // Pass your Lottie animation data here
      });

      // Cleanup when the component unmounts
      return () => {
        anim.destroy();
      };
    }
  }, [animationData]);

  return (
    <div
      ref={containerRef}
      style={{ width, height }}
    ></div>
  );
};

export default LottieWrapper;
