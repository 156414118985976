import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const MonthAreaChart = () => {
  const [chartData] = useState({
    series: [
      {
        data: generateRandomDataForOneMonth(),
      },
    ],
    options: {
      chart: {
        id: 'area-datetime',
        type: 'area',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: 'hollow',
      },
      xaxis: {
        type: 'datetime',
        min: new Date('2023-01-01').getTime(), // Adjust the start date for one month of data
        tickAmount: 10, // Adjust the number of ticks based on your data granularity
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
  });

  // Function to generate random data for one month
  function generateRandomDataForOneMonth() {
    const startDate = new Date('2023-01-01').getTime();
    const data = [];
    for (let day = 0; day < 30; day++) {
      const randomValue = Math.floor(Math.random() * (40 - 30 + 1)) + 30;
      data.push([startDate + day * 24 * 60 * 60 * 1000, randomValue]);
    }
    return data;
  }

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
      </div>
    </div>
  );
};

export default MonthAreaChart;
